import React from 'react';
import { Format } from '../../../models/Contract';
import { StationDashboardViewModel } from '../../../models/Station';
import StripContract from '../../../models/StripContract';
import { DashBoardAction, DashBoardActionType } from './actions';

export type DashboardState = {
    contracts: StripContract[];
    stations: StationDashboardViewModel[];
    selectedContract?: StripContract;
    selectedStation?: StationDashboardViewModel;
    selectedFormat?: Format;
    feedbackId?: string
};
export const initialState: DashboardState = {
    contracts: [],
    stations: [],
    feedbackId: undefined
};

//reducers
export function dashboardReducer(state: DashboardState, action: DashBoardAction): DashboardState {
    switch (action.type) {
        case DashBoardActionType.SET_CONTRACTS:
            return {
                ...state,
                contracts: action.payload,
            };
        case DashBoardActionType.SET_STATIONS:
            return {
                ...state,
                stations: action.payload
            };
        case DashBoardActionType.SELECT_CONTRACT:
            return {
                ...state,
                selectedContract: action.payload,
                feedbackId: undefined
            };
        case DashBoardActionType.SELECT_STATION:
            return {
                ...state,
                selectedStation: action.payload,
                selectedContract: undefined,
                feedbackId: undefined
            };
        case DashBoardActionType.SELECT_FORMAT:
                return {
                    ...state,
                    selectedFormat: action.payload
                };
        case DashBoardActionType.SELECT_FEEDBACK:
            return {
                ...state,
                feedbackId: action.payload
            };
        default:
            return state;
    }
}

export const DashboardContext = React.createContext<{
    state: DashboardState;
    dispatch: React.Dispatch<DashBoardAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
