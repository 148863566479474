import React from 'react';
import { Pager, PageChangeEvent } from '@progress/kendo-react-data-tools';
import { SearchStationActionType, SearchStationContext } from './state';

const Pagination: React.FC = () => {
    const { state, dispatch } = React.useContext(SearchStationContext);

    const handlePageChange = (event: PageChangeEvent) => {
        const { skip, take } = event;
        dispatch({
            type: SearchStationActionType.SET_PAGE,
            payload: { page: (skip/take) + 1, pageSize: take }
        });
        dispatch({
            type: SearchStationActionType.TOGGLE_SEARCH
        });
    };

    return state.total >= 0 ? 
            <Pager
                skip={(state.page - 1) * state.pageSize}
                take={state.pageSize}
                total={state.total}
                onPageChange={handlePageChange}
                responsive={true}
            />: <></>    
};

export default Pagination;
