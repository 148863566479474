import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Typography from '../../components/Core/Typography';
import Report, { ReportType } from '../../models/Report';
import './contractreport.scss';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { PerformanceService, ReportService } from '../../api';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as CreateIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { formatDate} from '../../utils';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Button from '../../components/Core/Button';
import ReportTable from './ReportTable';
import DateInput from '../../components/DateInput';
import InputSelect from '../../components/Core/InputSelect';

type Props = {
    contractId: string;
};

const ContractReport: React.FC<Props> = ({ contractId }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const[report, setReport] = React.useState<Report | null>(null);
    const[reports, setReports] = React.useState<Report[] | null>(null);
    const [reportModal, setReportModal] = React.useState<ReportType | undefined>(undefined);
    // const [date, setDate] = React.useState<Date>(new Date());
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [ endDate, setEndDate] = React.useState<Date>(new Date());
    const [isLoading, setIsLoading] = React.useState(false);
    const[dateError, setDateError] = React.useState(false);
    const[templateList, setTemplateList] = React.useState([{label: '', value: ''}])
    const[templateId, setTemplateId] = React.useState<string>('');
    const history = useHistory();

    // React.useEffect(() => {
    //     setDateError(false);
    //     if (date.getDay() !== 3) { // Wednesday is represented by 3
    //         date.setDate(date.getDate() - ((date.getDay() - 3 + 7) % 7));
    //       }
          
    //       // set the first day of the week (Wednesday) as the start of the range
    //       const first = formatDate(new Date(date.getTime()));
          
    //       // set the date to the next Tuesday
    //       date.setDate(date.getDate() + 6);
          
    //       // set the last day of the week (Tuesday) as the end of the range
    //       const last = formatDate(new Date(date.getTime()));

    //         if(first=="Invalid Date" || last == "Invalid Date" || first.length==0 || last.length == 0){
    //         setDateError(true);
    //         setWeek("Invalid Date");

    //         }
    //     else{
    //         const weekString = `${first}-${last}`;
    //         setWeek(weekString)
    //     }

    // }, [date]);
    
    React.useEffect(()=>{
        if(reports && reports.length>0){
            const earliestReport = reports[reports.length-1];
            setTemplateList([{label: "Default", value: earliestReport.id? earliestReport.id : ''},{label: "Create New Report", value: ''}])
            setTemplateId(earliestReport.id ?? '');
        }
        else{
            setTemplateId('');
            setTemplateList([{label: "Create New Report", value: ''}])
        }
    },[reports])
    React.useEffect(()=> {
       getReports();
    }, [contractId])


    const getReports =()=>{
        getAllReports();
    }

    const getAllReports = () =>{
        ReportService.getAllReportsForContract(contractId).
        then((response)=> {
            setReports(response);
            const reportFromDb =  response.find(a => a.reportType === ReportType.Cumulative) as Report;
            setReport(reportFromDb);
        });
    }

    const handleTemplateChange = (_name: string, value: string | number | undefined) => {
        setTemplateId(value as string);
    }


    const handleDateChange = (name: string, value: string) => {
       
        const date = new Date(value + " 00:00:00"); // Set the time to 00:00:00
        const options = { timeZone: "America/New_York" };
        const dateString = date.toLocaleString("en-US", options);
        const dateInTimeZone = new Date(dateString);

        if(name == "startDate"){
            setStartDate(dateInTimeZone);
        }
        else{
            setEndDate(dateInTimeZone);
        }
    };
    const handleCreateReport = () => {
        setDateError(false);
        if(reportModal == ReportType.Weekly){
            if(!isDateValid(startDate, endDate)){
                setDateError(true)
            }
            else{
            const weekStart = new Date(startDate);
            // weekStart.setDate(weekStart.getDate() - ((weekStart.getDay() - 3 + 7) % 7));
            const weekEnd = new Date(endDate);
            // weekEnd.setDate(weekEnd.getDate() + 6 - ((weekEnd.getDay() + 6) % 7));
            const overallWeek = `${formatDate(weekStart)}-${formatDate(weekEnd)}`;
            setIsLoading(true);
            const request = (reports?.filter(obj => obj.week === overallWeek as string));
            if(request?.length == 1){
                history.push(`/projects/${contractId}/report/${request[0].id}`)
            }
            else{
                PerformanceService.getDataForReport(contractId, templateId, startDate, endDate).then((res)=>{
                        history.push({
                            pathname: `/projects/${contractId}/report/generate-weekly-new`,
                            state: {data: res, weekData: overallWeek}
                    })

                })
            }
            setIsLoading(false);
            }
        }
        else{
            PerformanceService.getDataForReport(contractId, templateId).then((res)=>{
                history.push({
                    pathname: `/projects/${contractId}/report/generate-new`,
                    state: {data: res}
            })

        })  
        }
    }

    const isDateValid = (startDate: Date, endDate : Date) =>{
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        if(end< start){
            return false;
        }
        if(start.toString()=="Invalid Date" || end.toString() == "Invalid Date"){
            return false;  
        }
        return true;
    }
    return (
        <div className="contract-report paper">
            <div className="report-title">
                <Typography variant="subtitle" weight={600} color="secondary">
                    REPORT
                </Typography>
                <Modal isOpen={reportModal != undefined} close={()=> {setStartDate(new Date()); setEndDate(new Date()); setDateError(false); setReportModal(undefined)}} title="Create New Report" size="small">
                    <div>
                        <Typography variant='body'>
                            Select a template:
                        </Typography>
                        <InputSelect onChange={handleTemplateChange} value={templateId} name="template" options={templateList} placeholder="Template" />
                    </div>
                    {(reportModal == ReportType.Weekly) ?
                        <>
                        <Typography variant='body'>
                            Select a start date:
                        </Typography>
                        <DateInput
                            onChange= {handleDateChange}
                            name = "startDate"
                            value={startDate.toLocaleDateString('en-US')}
                            error={dateError && !isDateValid(startDate, endDate)}
                            />
                        <Typography variant='body'>
                            Select an end date:
                        </Typography>
                        <DateInput
                            onChange={handleDateChange}
                            name = "endDate"
                            value={endDate.toLocaleDateString('en-US')}
                            error={dateError && !isDateValid(startDate, endDate)}
                            />                    
                        <div className="week-spin">
                            { (dateError&& !isDateValid(startDate, endDate)) && 
                                <Typography variant="caption" color="error" textAlign="right">
                                    Invalid Date Range
                                </Typography>
                            }
                        </div>
                        </> : <></> 
                    }
                    <ModalActionBar>
                        <Button onClick={handleCreateReport} color="secondary" loading = {isLoading}>
                            Confirm
                        </Button>
                    </ModalActionBar>
                </Modal>
                <div className='report-icons'>
                    <IconButton Icon={CalendarIcon} onClick = {()=>setReportModal(ReportType.Weekly)} color="tertiary" tooltip='Create New Weekly Report'/>
                    {!report ? (
                        <div >
                            <IconButton Icon={CreateIcon} onClick = {()=>setReportModal(ReportType.Cumulative)}color="tertiary" tooltip='Create New Cumulative Report'/>
                        </div>
                    ) : (
                        <div className="icons-container">
                            <Link to={{ pathname: `/projects/${contractId}/report/${report.id}` }}>
                                <IconButton Icon={EditIcon} color="tertiary" tooltip='Edit Existing Cumulative Report'/>
                            </Link>
                        </div>
                    )}
                        {reports &&  reports.length > 0 &&  <IconButton
                            onClick={() => setIsExpanded(!isExpanded)}
                            Icon={isExpanded ? UpArrowIcon : DownArrowIcon}
                        />}
                </div>
            </div>
            {isExpanded && reports && <ReportTable data = {reports} getData = {getReports} contractId = {contractId}/>}
        </div>
    );
};

export default ContractReport;
