import { process, State } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React from 'react';
import LoadingOverlay from '../../components/LoadingOverlay';
import Report, { ReportType } from '../../models/Report';
import pagerSettings from '../../utils/pagerSettings';
import ReportActions from './ReportActions';


type Props= {
    data: Report[];
    getData: ()=> void;
    contractId: string;
}

const ReportTable: React.FC<Props> = ({data, getData, contractId}) =>{
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const [isGeneratingReport, setIsGeneratingReport] = React.useState(false);


    const toggleReportGeneration = (val: boolean) => {
        setIsGeneratingReport(val);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const TypeCell = (props: any) =>{
        return (
            <td>
                <p>{(props.dataItem.reportType== ReportType.Weekly)? "Weekly": "Cumulative"}</p>
            </td>
        )
    }


    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <ReportActions report={props.dataItem as Report} fetchData={getData} toggle = {toggleReportGeneration} contractId = {contractId}/>
            </td>
        );
    };

    return(
        <>
         <LoadingOverlay loading={isGeneratingReport}>
        <Grid
            style={{
                height: '100%',
            }}
            sortable={true}
            pageable={pagerSettings}
            filterable={true}
            data={process(data, dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
            }}
        >
            <GridColumn field = "reportType" title = "Report Type" cell = {TypeCell}/>
            <GridColumn field = "week" title="Week"/>
            <GridColumn field = "dateUpdated" title = "Last Updated" />
            <GridColumn field = "dateCreated" title = "Date Created" />
            <GridColumn cell={GridActions} filterable={false} />
        </Grid>
        </LoadingOverlay>
        </>
    )
}

export default ReportTable;