import React from 'react';
import Typography from '../../components/Core/Typography';
import { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import Select from 'react-select';
import { DashBoardActionType, DashboardContext } from './state';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Link } from 'react-router-dom';
import { StationDashboardViewModel } from '../../models/Station';
import { Format } from '../../models/Contract';
import { StationService } from '../../api';
import useDebounce from '../../utils/useDebounce';
import { DataLoadingContext } from '../../components/DataLoading';


const StationSelector: React.FC = () => {
    const { stations, selectedStation } = React.useContext(DashboardContext).state;
    const dispatch = React.useContext(DashboardContext).dispatch;
    const [inputValue, setInputValue] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const debounced = useDebounce(inputValue, 500);
    const[isUpdating, setIsUpdating] = React.useState(false)

    React.useEffect(() => {
        getStations();
    }, [debounced]);

    React.useEffect(()=>{
        if(selectedStation == undefined)
        handleUpdate();
    },[selectedStation])

    const handleUpdate = () =>{
        setIsUpdating(true);
        setTimeout(()=>setIsUpdating(false),1);
    }

    const getStations = () => {
        toggleLoading(true);
        setIsLoading(true);
        StationService.getStationsForDashboard(debounced as string)
        .then((res) => {
            dispatch({type: DashBoardActionType.SET_STATIONS, payload: res});
        }).finally(() => {
            toggleLoading(false);
            setIsLoading(false);
        })
    };

    const handleChange = (newStation: StationDashboardViewModel) => {
        dispatch({
            type: DashBoardActionType.SELECT_STATION,
            payload: newStation,
        });
    };
    const handleClear = () => {
        dispatch({
            type: DashBoardActionType.SELECT_STATION,
            payload: undefined,
        });
    };
    if (isUpdating) return null;
    return (
        <div className="selector station-container">
            <div className="selector-title">
                <RadioIcon className="color-tertiary" />
                <Typography variant="subtitle" color="tertiary">
                    Station
                </Typography>
            </div>
            <Select
                options={stations}
                components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                onChange={(el) => handleChange(el as StationDashboardViewModel)}
                value={selectedStation}
                onInputChange={(newValue) => setInputValue(newValue)}
                isLoading={isLoading}
                getOptionValue={(el) => el.id as string}
                getOptionLabel={(el) => el.name as string}
                placeholder="search stations..."
                isSearchable={true}
                noOptionsMessage={()=>{return <>Enter station name</>}}
            />
            {selectedStation && <div className='clear-container'><p className='clear' onClick = {handleClear}>Clear</p></div>}
        </div>
    );
};

const Option: React.FC<OptionProps<StationDashboardViewModel>> = (props: OptionProps<StationDashboardViewModel>) => {

    return (
        <div className="option">
            <components.Option {...props} className="tag-option-item">
                <div className="details">
                    <div>
                        <Typography variant="body" weight={600}>
                            {props.data.name}
                        </Typography>
                        {props.data.formats&&<Typography variant="caption">
                            <strong>Formats: </strong>
                            {
                               String(props.data.formats.map((el:number)=>{return ` ${Format[el]}`}))
                            }
                        </Typography>}
                        {(props.data.city || props.data.state) && 
                            <Typography variant='caption'>
                                <strong>Market: </strong>
                                {props.data.city && <>{props.data.city}</>}
                                {props.data.state && <>{props.data.city && ', '}{props.data.state}</>}
                                {props.data.country && <>{props.data.state && ', '}{props.data.country}</>}
                            </Typography>
                        }
                    </div>
                </div>
            </components.Option>
            <Link to={`stations/${props.data.id}`}>
                <ExternalLinkIcon />
            </Link>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<StationDashboardViewModel>> = (props: DropdownIndicatorProps<StationDashboardViewModel>) => {
    return(
        <div className='dropdown-indicator'>
            <components.DropdownIndicator {...props}>
                <SearchIcon className='color-grey' />
            </components.DropdownIndicator>
        </div>
    )
}


export default StationSelector;
