import React from 'react';
import Button from '../../../components/Core/Button';
import InputField from '../../../components/Core/InputField';
import InputRange from '../../../components/Core/InputRange';
import InputSelect from '../../../components/Core/InputSelect';
import Typography from '../../../components/Core/Typography';
import { getListFromEnum, getStates, useDebounce } from '../../../utils';
import { SearchStationActionType, SearchStationContext } from './state';
import { getProvinces } from '../../../utils/getStates';
import InputMultiSelect from '../../../components/Core/InputMultiSelect';
import { Format } from '../../../models/Contract';

type Props = {
    downloadFile: () => void;
}

const SearchFilters: React.FC<Props> = ({downloadFile}) => {
    const {state, dispatch} = React.useContext(SearchStationContext);
    const debouncedDistance = useDebounce(state.distance, 1500);
    const formatList = getListFromEnum(Object.values(Format));
    const[showError, setShowError] = React.useState(false);
    const[isUpdating, setIsUpdating] = React.useState(false);
    // const debouncedCity = useDebounce(state.city, 1500);
    // const debouncedZip = useDebounce(state.zip, 1500);
    // const debouncedState = useDebounce(state.searchState, 1500);
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isValid = (value: any)=>{
        return value && value.length>0;
    }
    
    const {country, distance, city, searchState, zip, formats} = state;

    const states = getStates();
    const provinces = getProvinces();

    const countries: Array<{ value: string; label: string }> = [
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' }
    ]

    React.useEffect(() => {
        if(state.distance){
            setShowError(false);
        dispatch({type: SearchStationActionType.TOGGLE_SEARCH});
        }
    }, [debouncedDistance]);

    const handleSubmit = () =>{
        setShowError(false);
        if(state.distance && state.distance>0 && (isValid(state.country))){
        dispatch({type: SearchStationActionType.TOGGLE_SEARCH});
        }
        else{
            setShowError(true);
        }
    };

    const handleCountryChange = (_name: string, value: string | number) => {
        dispatch({
            type: SearchStationActionType.SET_COUNTRY,
            payload:  value as string
        });
    };

    const handleFormatChange = (_name: string, value: number[]) => {
        dispatch({
            type: SearchStationActionType.SET_FORMAT,
            payload : value 
        });
    }

    const handleDistance = (_name: string, value: string) => {
        dispatch({
            type: SearchStationActionType.SET_DISTANCE,
            payload: parseInt(value)
        });
    };

    const handleZipChange = (_name: string, value: string) => {
        dispatch({
            type: SearchStationActionType.SET_ZIP_CODE,
            payload: value
        });
    };

    const handleCityChange = (_name: string, value: string) => {
        dispatch({
            type: SearchStationActionType.SET_CITY,
            payload: value as string
        });
    };

    const handleStateChange = (_name: string, value: string | number) => {
        dispatch({
            type: SearchStationActionType.SET_STATE,
            payload: value as string
        });
    };

    const handleClearFilter=()=> {
        dispatch({
            type: SearchStationActionType.CLEAR_FILTER
        });
        dispatch({
            type: SearchStationActionType.SET_SHOW_CLUSTER,
            payload: false
        });
        dispatch({type: SearchStationActionType.TOGGLE_SEARCH});
        setIsUpdating(true);
        setTimeout(()=>setIsUpdating(false),1);
    }
    if(isUpdating) return null;
    return(
        <div className='search-filters'>
            <div>
                <Typography variant="subtitle">Distance</Typography>
                <Typography variant="caption">Adjust distance to find stations within the radius adjusted below</Typography>
                <br/>
                <InputRange
                    onChange={handleDistance}
                    max={500}
                    min={0}
                    name="distance"
                    value={distance ? distance : 0}
                />
                {(showError && !(state.distance && state.distance>0)) && 
                <Typography variant='caption' color='error'>required</Typography>}
            </div>
            <div className='form-grid-third'>
            <div>
                    <Typography variant="subtitle">Country</Typography>
                    <Typography variant="caption">Search stations closer to a country below</Typography>
                    <InputSelect onChange={handleCountryChange} value={country} name="country" options={countries} placeholder="Country" error = {showError && !isValid(state.country)} errorText='required'/>
                </div>
                <div>
                    <Typography variant="subtitle">City</Typography>
                    <Typography variant="caption">Search stations closer to the city below</Typography>
                    <InputField
                        onChange={handleCityChange}
                        value={city? city :""}
                        name="city"
                    />
                </div>
                {!country || (country && country === 'US') ?
                    <div>
                        <Typography variant="subtitle">State</Typography>
                        <Typography variant="caption">Search stations closer to a state below</Typography>
                        <InputSelect onChange={handleStateChange} value={searchState} name="searchState" options={states} placeholder="State" />
                    </div> :
                    <div>
                    <Typography variant="body">Province</Typography>
                    <Typography variant="caption">Search stations closer to a province below</Typography>
                    <InputSelect onChange={handleStateChange} value={searchState} name="state" options={provinces} placeholder='Province' />
                </div>
                }
            </div>
            <div className='form-grid-third'>
                <div>
                    <Typography variant="subtitle">{!country || (country && country === 'US') ? 'Zip Code' : 'Postal Code' }</Typography>
                    <Typography variant="caption">Search stations closer to {!country || (country && country === 'US') ? 'zip code' : 'postal code'} below</Typography>
                    <InputField
                        onChange={handleZipChange}
                        value={zip? zip: ""}
                        name="zip"
                        type={(!country || country && country === 'US') ? "number" : "text"}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Format(s) <span className="color-error">*</span>
                    </Typography>
                    <Typography variant="caption">Search stations based on formats</Typography>
                    <InputMultiSelect 
                        options={formatList}
                        value={formats}
                        name="formats"
                        placeholder='Select station format(s)'
                        onChange={(name, value)=>handleFormatChange(name, value as number[])}
                    />
                </div>
            </div>
            <div className='search-actions'>                        
                {state.total>0 && <Button onClick={downloadFile} color="success">Export</Button>}
                <Button onClick={handleClearFilter} color='error'>Clear Filters</Button>
                <Button onClick={handleSubmit}> Submit</Button>
            </div>

        </div>
    )
};

export default SearchFilters;