import FileExportResponse from "../models/FileExportResponse";
import Report from "../models/Report";
import instance from "./instance";

function createReport(report: Report): Promise<Report>{
    return instance.post(`contract/report`, report).then((response)=>{
       return response.data;
    })
}

function updateReport(report: Report): Promise<Report>{
    return instance.put(`contract/report`, report).then((response)=>{
       return response.data;
    })
}
function getReportById(id:string): Promise<Report> {
    return instance.get(`contract/report/${id}`).then((response)=>{
        return response.data;
    })
}

function getAllReportsForContract(contractId: string):Promise<Report[]> {
    return instance.get(`contract/${contractId}/reports`).then((response)=> {
        return response.data;
    })
}

function deleteReport(id:string): Promise<boolean> {
    return instance.delete(`contract/report/${id}`).then((response)=> {
        return response.data
    })
}
function uploadMedia(blob: Blob, name: string): Promise<string> {
    const formData = new FormData();
    formData.append('file', blob, name);
    return instance.post(`contract/report/upload`, formData).then((response) => {
        return response.data;
    });
}

function previewReport(id:string): Promise<string>{
    return instance.get(`contract/report/${id}/preview`).then((response)=>{
        return response.data
    })
}

function downloadReport(id:string): Promise<FileExportResponse>{
    return instance.get(`contract/report/${id}/download`).then((response)=>{
        return response.data
    })
}

export{createReport,updateReport,getReportById,getAllReportsForContract, deleteReport, uploadMedia, previewReport, downloadReport};