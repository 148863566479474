import React from 'react';
import { StationService } from '../../api';
import { useHistory } from 'react-router-dom';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import Button from '../../components/Core/Button';
import Card, { CardActionBar } from '../../components/Core/Card';
import Typography from '../../components/Core/Typography';
import Station from '../../models/Station';
import { formatLocation } from '../../utils';
import { Format } from '../../models/Contract';

type Props = {
    station: Station;
    toggleView: () => void;
};

const StationDetail: React.FC<Props> = ({ station, toggleView }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const history = useHistory();

    const handleOpenDeleteStationDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Station',
                description: `Are you sure you want to delete ${station.name}?`,
                handleConfirm: () => handleDelete(station.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        StationService.deleteStation(id).then(() => {
            history.push('/stations');
        });
    };

    return (
        <Card>
            <div className="station-detail">
                <Typography variant="subtitle" weight={600} color="secondary">
                    STATION DETAIL
                </Typography>
                <br/>
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body" weight={500}>
                            Name
                        </Typography>
                        <Typography variant="body">{station.name}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Alias(es) 
                        </Typography>
                        {
                            station.aliases && <Typography variant="body">{String(station.aliases.map((el:string)=>{return ` ${el}`}))}</Typography>
                        }
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Formats
                        </Typography>
                        {station.formats && <Typography variant="body">
                            {
                                String(station.formats.map((el:number)=>{return ` ${Format[el]}`}))
                            }
                        </Typography>}
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Address
                        </Typography>
                        <Typography variant="body">
                            {formatLocation(
                                station.addressLine1,
                                station.addressLine2,
                                station.city,
                                station.state,
                                station.country,
                                station.zip,
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Priority
                        </Typography>
                        <Typography variant="body">
                            {!station.priority ? 'Not Set' : station.priority}
                        </Typography>
                    </div>
                    {(station.emailAddress && station.emailAddress.length>0) &&
                    <div>
                        <Typography variant="body" weight={500}>
                            Email Address
                        </Typography>
                        <Typography variant="body">
                            {station.emailAddress}
                        </Typography>
                    </div>
                    }
                </div>
                <div className="form-grid-third">
                    <div>
                        <Typography variant="body" weight={500}>
                            Notes
                        </Typography>
                        <Typography variant="body">{station.notes}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            CD Requires Mailing
                        </Typography>
                        <Typography variant="body">{station.needsMailing ? 'Yes' : 'No'}</Typography>
                    </div>
                    <div>
                        <Typography variant="body" weight={500}>
                            Is Live
                        </Typography>
                        <Typography variant="body">{station.isLive ? 'Yes' : 'No'}</Typography>
                    </div>
                </div>
                <CardActionBar>
                    <Button onClick={toggleView}>Edit</Button>
                    <Button onClick={handleOpenDeleteStationDialog} color="error">
                        Delete
                    </Button>
                </CardActionBar>
            </div>
        </Card>
    );
};
export default StationDetail;
