import React from 'react';
import { Link } from 'react-router-dom';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import Button from '../Core/Button';
import { UserRole } from '../../models/User';

type Props = {
    activeItem: string;
};

const AccountActions: React.FC<Props> = ({ activeItem }) => {
    const { isLoggedIn, user } = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;

    return (
        <>
            {!isLoggedIn && (
                <div className="account-actions">
                    <Link to="/">
                        <p className={`nav-item ${activeItem === 'Login' ? 'active' : ''}`} role="navigation">
                            <span>Login</span>
                        </p>
                    </Link>
                    <Button color="secondary">CONTACT US</Button>
                </div>
            )}
            {isLoggedIn && (
                <div className="account-actions">
                    {user && (
                        <div
                            className="profile-section"
                            tabIndex={0}
                        >
                            <div>
                                <p className="profile-name">{user.name} {user.role === UserRole.Admin && <><span> | ADMIN</span></>}</p>
                                <p
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch({ type: AuthActionType.LOGOUT });
                                    }}
                                    tabIndex={0}
                                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                        if (e.key === 'Enter') {
                                            e.stopPropagation();
                                            dispatch({ type: AuthActionType.LOGOUT });
                                        }
                                    }}
                                    className="logout-link"
                                >
                                    Logout
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AccountActions;
