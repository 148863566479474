import React from 'react';
import './contractDetail.scss';
import { ContractService } from '../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import Card from '../../components/Core/Card';
import IconButton from '../../components/Core/IconButton';
import Typography from '../../components/Core/Typography';
import Contract, { ContractStatus, Format, ReleaseType } from '../../models/Contract';
import { downloadFile, formatDate } from '../../utils';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from '../../assets/icons/unarchive.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import { useHistory } from 'react-router-dom';
import { DataLoadingContext } from '../../components/DataLoading';

type Props = {
    contract: Contract;
    toggleView: () => void;
};

const ContractDetail: React.FC<Props> = ({ contract, toggleView }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const history = useHistory();

    const handleOpenDeleteClientDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to delete ${contract.artistName}?`,
                handleConfirm: () => handleDelete(contract.id as string),
            },
        });
    };
    const handleOpenArchiveContractDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to archive ${contract.artistName}? Once archived the strip will no longer feature this release.`,
                handleConfirm: () => handleArchive(contract.id as string),
            },
        });
    };

    const handleOpenUnArchiveContractDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to un-archive ${contract.artistName}? Once archived the strip will feature this release.`,
                handleConfirm: () => handleUnArchive(contract.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        ContractService.deleteContract(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'project was successfully deleted.',
                    status: 'success',
                    autoClose: false,
                },
            });
            history.push(`/projects`);
        });
    };
    const handleArchive = (id: string) => {
        ContractService.archiveContract(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'Project was successfully archived.',
                    status: 'success',
                    autoClose: false,
                },
            });
            history.push(`/projects`);
        });
    };

    const handleUnArchive = (id: string) => {
        ContractService.unarchiveContract(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'Project was successfully un-archived.',
                    status: 'success',
                    autoClose: false,
                },
            });
            history.push(`/projects`);
        });
    }

    const handleExport = () => {
        toggleLoading(true);
        ContractService.exportContract(contract.id as string)
        .then((response) => {
            downloadFile(response.content, response.fileName);
        })
        .catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(() => {
            toggleLoading(false);
        });
    }

    return (
        <Card>
            <div className="contract-detail">   
                {contract.status === ContractStatus.Archived && <div style={{borderLeft: "4px solid grey", paddingLeft: "12px", backgroundColor: "rgba(0,0,0,0.2)"}}>
                    Archived
                </div>}
                <div className='detail-title'>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        PROJECT DETAIL
                    </Typography>
                    <div className="icons-container">
                        {contract.status !== ContractStatus.Archived && <IconButton
                            onClick={toggleView}
                            Icon={EditIcon}
                            color='info'
                            tooltip="Edit"
                        />
                        }
                        <IconButton
                            onClick={handleExport}
                            Icon={ShareIcon}
                            color="success"
                            tooltip="Export Contract"
                        />
                        {contract.status === ContractStatus.Active && <IconButton
                            onClick={handleOpenArchiveContractDialog}
                            Icon={ArchiveIcon}
                            color='grey'
                            tooltip="Archive"
                        />}
                         {contract.status === ContractStatus.Archived && <IconButton
                                onClick={handleOpenUnArchiveContractDialog}
                                Icon={UnArchiveIcon}
                                color='grey'
                                tooltip="Un Archive"
                            />}
                        <IconButton 
                            Icon={DeleteIcon} 
                            color="error" 
                            tooltip='Delete'
                            onClick={handleOpenDeleteClientDialog} />
                    </div>
                </div> 
                <br/>
                <div className='form-grid-third'>
                    <div>
                        <Typography variant="body" weight={500}>Client</Typography>
                        <Typography variant="body">{contract.clientName}</Typography>
                    </div>
                    {contract.startDate && <div>
                        <Typography variant="body" weight={500}>Start Date</Typography>
                        <Typography variant="body">{formatDate(contract.startDate)}</Typography>
                    </div>}
                    {contract.endDate && <div>
                        <Typography variant="body" weight={500}>End Date</Typography>
                        <Typography variant="body">{formatDate(contract.endDate)}</Typography>
                    </div>}
                    {contract.formats && contract.formats.length>0 && <div>
                        <Typography variant="body" weight={500}>Release Format(s)</Typography>
                        <Typography variant="body">{String(contract.formats.map((el:number)=>{return ` ${Format[el]}`}))}</Typography>
                    </div>}
                </div>   
                <br/>             
                <div>
                    <Typography variant="body" weight={500}> Campaign Goals</Typography>
                    <Typography variant="body">{contract.campaignGoals?contract.campaignGoals:'N/A'}</Typography>
                </div>
                <br/>
                <Typography variant="body" weight={600} color="secondary">
                    <u>RELEASE</u>
                </Typography>
                <div className='form-grid-third'>
                    <Typography variant="body">
                        <strong> Name: </strong>
                        {contract.releaseName}
                    </Typography>
                    <Typography variant="body">
                        <strong>Release Alias(es):</strong>
                        {
                            contract.releaseAliases && <>{String(contract.releaseAliases.map((el:string)=>{return ` ${el}`}))}</>
                        }
                    </Typography>
                    <Typography variant="body">
                        <strong> Artist: </strong>
                        {contract.artistName}
                    </Typography>
                    <Typography variant="body">
                        <strong>Artist Alias(es):</strong>
                        {
                            contract.artistAliases && <>{String(contract.artistAliases.map((el:string)=>{return ` ${el}`}))}</>
                        }
                    </Typography>
                    <Typography variant="body">
                        <strong> Type: </strong>
                        {ReleaseType[contract.releaseType]}
                    </Typography>
                    <Typography variant="body">
                        <strong> Label: </strong>
                        {contract.labelName}
                    </Typography>
                </div>
            </div>
        </Card>
    );
};

export default ContractDetail;
