import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import config from '../../config';
import { UserRole } from '../../models/User';

const AppRouter: React.FC = () => {
    return (
        <Switch>
            {config.routes
                .filter((element) => element.roles.includes(UserRole.Visitor))
                .map((element, index) => (
                    <Route path={element.route} exact={element.exact} component={element.Component} key={index} />
                ))}
            {config.routes
                .filter((element) => !element.roles.includes(UserRole.Visitor))
                .map((element, index) => (
                    <PrivateRoute
                        path={element.route}
                        exact={element.exact}
                        component={element.Component}
                        roles={element.roles}
                        key={index}
                    />
                ))}
            <Redirect to="/404" />
        </Switch>
    );
};

export default AppRouter;
