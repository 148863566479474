import React from 'react';
import Typography from '../../components/Core/Typography';
import Feedback, { FeedbackCategory } from '../../models/Feedback';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import IconButton from '../../components/Core/IconButton';
import {  formatDate, getFormatName } from '../../utils';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { FeedbackService } from '../../api';
import './contractfeedback.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Modal from '../../components/Core/Modal';
import NoteEditor from '../Dashboard/NoteEditor';
import { DashBoardActionType, DashboardContext } from '../Dashboard/state';
import { downloadPdf } from '../../utils/downloadFile';

const ContractFeedbacks: React.FC<{ feedbacks: Feedback[]; getData: () => void; activeFeedbackId?: string }> = ({
    feedbacks,
    getData,
    activeFeedbackId,
}) => {
    const { id } = useParams<{ id: string }>();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [editingFeedbackId, setEditingFeedbackId] = React.useState<string>();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const location = useLocation();
    const history = useHistory();
    const isWorkStation = location.pathname == '/work-station';
    const dispatch = React.useContext(DashboardContext).dispatch;

    const openDeleteDialog = (feedback: Feedback) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Feedback',
                description: `Are you sure you want to delete this feedback for project ${feedback.contractName}?`,
                handleConfirm: () => handleDelete(feedback.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        FeedbackService.deleteFeedback(id).then(() => {
            getData();
        });
    };

    const handleExport = () => {
        FeedbackService.exportFeedbackForContract(id as string)
            .then((response) => {
                downloadPdf(response.content, response.fileName);
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: false,
                    },
                });
            });
    };

    const handleToggleExpansion = () => {
        if (isExpanded) {
            setEditingFeedbackId(undefined);
        }
        setIsExpanded(!isExpanded);
    };

    const handleFeedbackEditorToggler = (feedbackId: string) => {
       
        if(location.pathname.includes('work-station')) {
            dispatch({
                type: DashBoardActionType.SELECT_FEEDBACK, // eslint-disable-next-line react/prop-types
                payload: feedbackId,
            });
            history.push('/work-station');
            const a = document.getElementById("scroll-anchor");
            if(a) a.scrollIntoView();
        } else {
            if (!editingFeedbackId) {
                setEditingFeedbackId(feedbackId);
            } else {
                setEditingFeedbackId(undefined);
            }
        }
        
    };

    return (
        <div className="contracts-feedbacks paper">
            <div className="feedback-title">
                <div>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        FEEDBACK HISTORY
                    </Typography>
                    {feedbacks.length > 0 && (
                        <Typography variant="caption" color="info">
                            {feedbacks.length} feedback{feedbacks.length > 1 && 's'} recorded.
                        </Typography>
                    )}
                </div>
                <div className="d-flex">
                    {!location.pathname.includes("work-station") && (
                        <IconButton onClick={handleExport} Icon={ShareIcon} color="success" tooltip="Export feedback" />
                    )}
                    <IconButton onClick={handleToggleExpansion} Icon={isExpanded ? UpArrowIcon : DownArrowIcon} />
                </div>
            </div>
            {isExpanded &&
                feedbacks.map((feedback) => (
                    <div className={`feedback${feedback.id === activeFeedbackId ? ' active' : ''}`} key={feedback.id}>
                        <div className="feedback-action">
                            <IconButton
                                Icon={DeleteIcon}
                                color="error"
                                onClick={() => openDeleteDialog(feedback)}
                                tooltip="Delete feedback"
                            />
                            <IconButton
                                Icon={EditIcon}
                                color="tertiary"
                                tooltip="Edit feedback"
                                onClick={() => handleFeedbackEditorToggler(feedback.id as string)}
                            />
                        </div>
                        {feedback.feedbackDate &&
                        <Typography variant="caption">
                            Feedback Date: {formatDate(feedback.feedbackDate)}
                        </Typography>
                        }
                        <Typography variant="caption">
                            Updated Date: {formatDate(feedback.updatedAt as string)}
                        </Typography>
                        {!isWorkStation && <Typography variant="body">
                            <strong>Station: </strong>
                            {feedback.stationName}
                        </Typography>}
                        {feedback.genre && <Typography variant="body">
                            <strong>Genre: </strong>
                            {getFormatName(feedback.genre)}
                        </Typography>}
                        <Typography variant="body">
                            <strong>Category: </strong>
                            {FeedbackCategory[feedback.category].replace(/([A-Z]+)/g, ' $1').replace(/^ /, '')}
                        </Typography>
                        {/* {!isWorkStation && <Typography variant="body">
                            <strong>Project: </strong>
                            {feedback.contractName}
                        </Typography>} */}
                        <br />

                        <Modal
                            isOpen={editingFeedbackId === feedback.id}
                            close={() => {
                                setEditingFeedbackId(undefined);
                                getData();
                            }}
                            title={'Edit feedback'}
                            size="large"
                        >
                            <NoteEditor
                                feedback={feedback.comment}
                                stationId={feedback.stationId}
                                contractId={feedback.contractId}
                                feedbackId={feedback.id}
                                feedbackSentiment={feedback.category}
                                feedbackGenre={feedback.genre}
                                feedBackDate={feedback.feedbackDate}
                            />
                        </Modal>
                        <div>{feedback.comment}</div>
                    </div>
                ))}
        </div>
    );
};

export default ContractFeedbacks;
