import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContractService } from '../../api';
import Button from '../../components/Core/Button';
import Checkbox from '../../components/Core/Checkbox';
import InputField from '../../components/Core/InputField';
import InputMultiSelect from '../../components/Core/InputMultiSelect';
import InputSelect from '../../components/Core/InputSelect';
import MultiInputField from '../../components/Core/MultiInputField';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import TextArea from '../../components/Core/TextArea';
import Typography from '../../components/Core/Typography';
import Client, { ClientSearchResponse } from '../../models/Client';
import Contract, { Format, ReleaseType } from '../../models/Contract';
import { emailIsValid, endDateIsValid, FormatDateInputValue, getListFromEnum } from '../../utils';
import ClientSelector from './ClientSelector';

type Props = {
    contract: Contract;
    toggleView: () => void;
};

const ContractForm: React.FC<Props> = ({ contract, toggleView }) => {
    const { id } = useParams<{ id: string }>();
    const [_contract, setContract] = React.useState({
        ...contract,
        startDate: contract.startDate? FormatDateInputValue(contract.startDate) as string: contract.startDate,
        endDate: contract.endDate? FormatDateInputValue(contract.endDate): contract.endDate
    });
    const [selectedClient, setSelectedClient] = React.useState<ClientSearchResponse>();
    const [showError, setShowError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [_isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isNewClient, setIsNewClient] = React.useState<boolean>(false);
    const [_client, setClient] = React.useState<Client>({name: '', contactName: '', contactEmail: ''});
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const history = useHistory();

    React.useEffect(() => {
        if(id !== 'add-new'){
            setIsEditing(true);
        }
    }, [id])

    React.useEffect(() => {
        if(contract && contract.clientId.length > 0){
            setSelectedClient({ id: contract.clientId, name: contract.clientName as string });
        }
    }, [contract]);

    const handleChange = (name: string, value: string | number | number[]) => {
        setContract({ ..._contract, [name]: value });
    };

    const handleAliasChange = (name: string, value: Array<string>) => {
        setContract({..._contract, [name]: value});
    }

    const handleClientChange = (item: ClientSearchResponse) => {
        setSelectedClient(item);
        handleChange('clientId', item.id);
    }

    const handleNewClientChange = (name: string, value: string) =>{
        setClient({..._client, [name]: value})
    }

    const releaseTypes = getListFromEnum(Object.values(ReleaseType));
    const formats = getListFromEnum(Object.values(Format));

    const handleCancel = () => {
        if (contract.id) {
            toggleView();
        } else {
            history.push('/projects');
        }
    };

    const handleSubmit = () => {
        const isValid =
            (isNewClient ||_contract.clientId.length > 0) &&
            _contract.artistName.length > 0 &&
            (_contract.endDate ? endDateIsValid(_contract.startDate, _contract.endDate): true) &&
            _contract.releaseName.length > 0 &&
            _contract.labelName.length > 0 &&
            _contract.formats && _contract.formats.length>0 ;
            
        const isClientValid = isNewClient?             
        _client.name.length > 0 &&
        _client.contactName.length > 0 &&
        (_client.contactEmail ? emailIsValid(_client.contactEmail): true) : true;

        setShowError(!(isValid && isClientValid));

        if (isValid && isClientValid) {
            setLoading(true);
            let request; 
            if(isNewClient){
                // _contract.clientId = "00000000-0000-0000-0000-000000000000"
                /* eslint-disable @typescript-eslint/no-explicit-any */
                delete (_contract as any).clientId; //removing client id from the payload since client is created in backend.
                request = ContractService.createContractWithClient(_contract, _client);
            }
            else{
                request  = _contract.id
                ? ContractService.updateContract(_contract)
                : ContractService.createContract(_contract);
            }
            request
                .then(() => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: 'Project was successfully updated.',
                            status: 'success',
                            autoClose: false,
                        },
                    });
                    history.push('/projects');
                }).catch(()=>{
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload:{
                            text: 'Something went Wrong. Please Try Again',
                            status : "error",
                            autoClose: false
                        }
                    })
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="contract-form paper">
            <Typography variant="subheading" weight={500} textAlign="center">
                <span className="page-title">{contract.id ? contract.artistName : 'CREATE NEW CONTRACT'}</span>
            </Typography>
            <br />
            <br />
            <Typography variant="caption" textAlign="left" weight={500}>
                <span className="color-error">*</span> indicates required
            </Typography>
            <div className='client-container'>
                <Typography variant="title" weight={500} color="primary">
                    Client 
                </Typography>
                <div className="action-container">
                    <Checkbox
                        onChange={()=>setIsNewClient(!isNewClient)}
                        checked = {isNewClient}
                        label = "New Client"
                    />
                </div>
            </div>
            {isNewClient ? <>
                <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Client Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleNewClientChange}
                        value={_client?.name}
                        name="name"
                        error={showError && _client?.name.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <br />
            <Typography variant="subtitle" color="primary" weight={500}>
                CONTACT DETAIL
            </Typography>
            <br />
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleNewClientChange}
                        value={_client?.contactName}
                        name="contactName"
                        error={showError && _client?.contactName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Email Address
                    </Typography>
                    <InputField
                        onChange={handleNewClientChange}
                        value={_client?.contactEmail}
                        name="contactEmail"
                        error={showError && (_client?.contactEmail ? !emailIsValid(_client?.contactEmail): false)}
                        errorText="enter a valid email"
                    />
                </div>
            </div>
            </>
            :<>
            {
                <div className="client-selector-container">
                    <ClientSelector 
                        selectedClient={selectedClient} handleChange={handleClientChange}
                        error={showError && _contract.clientId.length === 0}
                        errorText={'Please select a valid client'}
                    />
                </div>
            }
            </>}

            <Typography variant="title" weight={500} color="primary">
                Project Details
            </Typography>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Start Date
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_contract.startDate as string}
                        type="date"
                        name="startDate"
                        error={showError && (_contract.endDate ? !endDateIsValid(_contract.startDate, _contract.endDate): false)}
                        errorText="Project start date and end date are not valid"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        End Date
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_contract.endDate as string}
                        type="date"
                        name="endDate"
                        error={showError && (_contract.endDate ? !endDateIsValid(_contract.startDate, _contract.endDate): false)}
                        errorText="Project start date and end date are not valid"
                    />
                </div>
            </div>
            <div>
                <Typography variant="body">Goals</Typography>
                <TextArea onChange={handleChange} value={_contract.campaignGoals} name="campaignGoals" />
            </div>
            <Typography variant="title" weight={500} color="primary">
                Release Details
            </Typography>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Artist Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_contract.artistName}
                        name="artistName"
                        error={showError && _contract.artistName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Artist Aliases
                    </Typography>
                    <MultiInputField
                        onAddRemove={handleAliasChange}
                        values={_contract.artistAliases}
                        name="artistAliases"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Release Type <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        onChange={(name, value) => handleChange(name, value)}
                        value={_contract.releaseType}
                        name="releaseType"
                        options={releaseTypes}
                        placeholder="type"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Release Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_contract.releaseName}
                        name="releaseName"
                        error={showError && _contract.releaseName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Release Aliases
                    </Typography>
                    <MultiInputField
                        onAddRemove={handleAliasChange}
                        values={_contract.releaseAliases}
                        name="releaseAliases"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Label <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_contract.labelName}
                        name="labelName"
                        error={showError && _contract.labelName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Release Format(s) <span className="color-error">*</span>
                    </Typography>
                    <InputMultiSelect 
                        options={formats}
                        value={_contract.formats}
                        name="formats"
                        placeholder='Select station format(s)'
                        onChange={(name, value)=>handleChange(name, value as number[])}
                        error={showError && _contract.formats.length === 0}
                        errorText='required'
                    />
                </div>
            </div>
            <div>
                <Typography variant="body">Notes</Typography>
                <TextArea onChange={handleChange} value={_contract.notes ? _contract.notes : ''} name="notes" />
            </div>
            <div className=" action-container">
                <Button color="primary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit} loading={loading}>
                    {contract.id ? 'Save' : 'Submit'}
                </Button>
            </div>
        </div>
    );
};

export default ContractForm;
