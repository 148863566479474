import { Format } from "./Contract";

export default interface Station {
    id?:string;
    name: string;
    aliases: Array<string>;
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    city?: string;
    state?: string;
    zip?: string;
    isLive: boolean;
    needsMailing: boolean;
    priority?: number;
    notes?: string;
    formats: Format[];
    hotLine?:string;
    createdAt?: string;
    updatedAt?:string;
    emailAddress?: string;
}

export interface StationDashboardViewModel{
    id: string;
    name: string;
    formats: Format[];
    aliases: Array<string>;
    country?: string;
    city?: string;
    state?: string;
    priority: number;
    needsMailing: boolean;
    emailAddress?: string;
}

export interface DJDashboardViewModel{
    id: string;
    name: string;
    stationName:string;
    formats: Format[];
}

export interface StationGridViewModel {
    id: string;
    name: string;
    formats: Format[];
    aliases: Array<string>;
    country?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zip?: string;
    isLive: boolean;
    needsMailing: boolean;
    priority: number;
    primaryContactName?: string;
    primaryContactPhoneNumber?: string;
    primaryContactEmailAddress?: string;
    createdAt: string;
}

export interface Contact{
    id?: string;
    stationId?: string;
    firstName: string;
    lastName: string;
    emailAddress?: string;
    phoneNumber?: string;
    isPrimary: boolean;
}

export interface DJ {
    id?: string;
    stationId?: string;
    firstName: string;
    lastName: string;
    djAvailabilities: DJTime[];
    phoneNumber: string;
    formats: Format[];
    isInMailingList: boolean;
    isPriority: boolean;
    notes?:string;
}

export type DJTime = {
    id?: string;
    dayOfWeek: string;
    djId?: string;
    endTime: string;
    startTime: string;
    timeZone: TimeZone;
}

export type DJCaller = {
    userId: string;
    name: string;
    email: string;
}

export type StationClusterSearchReq = {
    distance: number;
    country?: string;
    city?: string;
    state?: string;
    zip?: string;
    stationId?: string;
    page: number;
    pageSize: number;
    formats?: Format[];
}

export enum TimeZone {
    "Alaska Standard Time (UTC-9)" = 0,
    "Central Standard Time (UTC-6)" = 1,
    "Eastern Standard Time (UTC-5)" = 2,
    "Mountain Standard Time (UTC-7)" = 3,
    "Hawaii-Aleutian Standard Time (UTC-10)" = 4,
    "Pacific Standard Time (UTC-8)" = 5,
}