import Callsheet from '../models/CallSheet';
import { CallerDJ, SystemUser } from '../models/User';
import instance from './instance';
import FileExportResponse from '../models/FileExportResponse';

function getUsers(showDisabled?: boolean): Promise<SystemUser[]> {
    const request = instance.get(!showDisabled ? 'userprofiles' : 'userprofiles?showDisabled=true');
    return request.then((response) => {
        response.data.forEach((el: SystemUser) => (el.createdAt = new Date(el.createdAt as Date)));
        return response.data;
    });
}

function createUser(user: SystemUser): Promise<SystemUser> {
    return instance.post(`auth/create-user`, user).then((response) => {
        return response.data;
    });
}

function getUser(id: string): Promise<SystemUser> {
    return instance.get(`userprofile/${id}`).then((response) => {
        return response.data;
    });
}

function deleteUser(id: string): Promise<boolean> {
    return instance.delete(`auth/${id}`).then((response) => {
        return response.data;
    });
}

function enableUser(id: string): Promise<boolean> {
    return instance.put(`auth/enable?Id=${id}`).then((response) => {
        return response.data;
    });
}
function disableUser(id: string): Promise<boolean> {
    return instance.put(`auth/disable?Id=${id}`).then((response) => {
        return response.data;
    });
}

function updateUser(user: SystemUser): Promise<SystemUser> {
    return instance.put(`userprofile`, user).then((response) => {
        return response.data;
    });
}

function changeRole(id: string, role: string): Promise<boolean> {
    return instance
        .put(`auth/role`, {
            userId: id,
            role: role,
        })
        .then((response) => {
            return response.data;
        });
}

function getCallSheet(id?: string, stationId?: string, contractId?: string): Promise<Callsheet[]> {
    let query = "/userprofile/callsheet?";

    if (id) {
      query += `id=${id}`;
    }
  
    if (stationId) {
      query += `&stationId=${stationId}`;
    }
  
    if (contractId) {
      query += `&contractId=${contractId}`;
    }

    return instance.get(query).then((response) => {
        return response.data;
    });
}

function exportCallSheet(id?: string, stationId?: string, contractId?: string): Promise<FileExportResponse> {
    let query = "/userprofile/export-callsheet?";

    if (id) {
      query += `id=${id}`;
    }
  
    if (stationId) {
      query += `&stationId=${stationId}`;
    }
  
    if (contractId) {
      query += `&contractId=${contractId}`;
    }

    return instance.get(query).then((response) => {
        return response.data;
    });
}

function getUserDjs(id: string): Promise<CallerDJ[]> {
    return instance.get(`userprofile/${id}/djs`).then((response) => {
        return response.data;
    });
}

function updateUserDjs(userId: string, djList:string[]): Promise<boolean>{
    return instance.put(`userprofile/${userId}/djs`, djList).then((response)=> {
        return response.data;
    })
}

export {
    getUsers,
    createUser,
    updateUser,
    getUser,
    deleteUser,
    enableUser,
    disableUser,
    changeRole,
    getCallSheet,
    exportCallSheet,
    getUserDjs,
    updateUserDjs
};
