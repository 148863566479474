import { UserRole } from "../models/User";
import { NavPosition, Route } from "./models";
import LandingPage from '../containers/Landing';
import DashboardPage from '../containers/Dashboard';
import ForgotPasswordPage from '../containers/ForgotPassword';
import ResetPasswordPage from '../containers/ResetPassword';
import {ReactComponent as DashboardIcon } from '../assets/icons/table.svg';
import {ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import {ReactComponent as UploadIcon } from '../assets/icons/upload-cloud.svg';
import {ReactComponent as ClipboardIcon } from '../assets/icons/clipboard.svg';
import {ReactComponent as RadioIcon } from '../assets/icons/radio.svg';
import {ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import {ReactComponent as ClientIcon } from '../assets/icons/server.svg';
import NotFound from "../containers/NotFound";
import Clients from "../containers/Clients";
import Users from "../containers/Users";
import Calendar from "../containers/Calendar";
import Stations from "../containers/Stations";
import Contracts from "../containers/Contracts";
import ClientPage from "../containers/Clients/ClientPage";
import StationPage from "../containers/Stations/StationPage";
import ContractPage from "../containers/Contracts/ContractPage";
import ReportGenerator from "../containers/Contracts/ReportGenerator";
import StationSearch from "../containers/Stations/StationSearch";
import SpinUploads from "../containers/SpinUploads";

const routes: Array<Route> = [   
    {
        label: 'Forgot Password',
        route:  '/forgot-password',
        roles:  [UserRole.Visitor],
        Component: ForgotPasswordPage,
        exact: true
    },
    {
        label: 'Reset Password',
        route:  '/reset-password/:token/:email',
        roles:  [UserRole.Visitor],
        Component: ResetPasswordPage,
        exact: false   
    },    
    {
        label: 'Create Password',
        route:  '/create-password/:token/:email',
        roles:  [UserRole.Visitor],
        Component: ResetPasswordPage,
        exact: false   
    },
    {
        label: 'Work Station',
        route:  '/work-station',
        roles:  [UserRole.Employee, UserRole.Admin],
        Component: DashboardPage,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav: true,
        Icon: DashboardIcon,
        exact: true 
    },
    {
        label: 'Work Station',
        route:  '/work-station/:stationId',
        roles:  [UserRole.Employee, UserRole.Admin],
        Component: DashboardPage,
        exact: true 
    },    
    {
        label: 'Work Station',
        route:  '/work-station/:stationId/:feedbackId',
        roles:  [UserRole.Employee, UserRole.Admin],
        Component: DashboardPage,
        exact: true 
    },  
    {
        label: 'Not Found',
        route:  '/404',
        roles:  [UserRole.Employee, UserRole.Admin, UserRole.Visitor],
        Component: NotFound,
        Icon: DashboardIcon,
        exact: true 
    },        
    {
        label: 'Projects',
        route:  '/projects',
        roles:  [UserRole.Admin],
        Component: Contracts,
        showVerticalNav: true,        
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: ClipboardIcon,
        exact: true 
    },   
    {
        label: 'Projects',
        route:  '/projects/:id',
        roles:  [UserRole.Admin],
        Component: ContractPage,
        showVerticalNav: true,        
        showHorizontalNav: true,
        Icon: ClipboardIcon,
        exact: true 
    }, 
    {
        label: 'NACC Data',
        route: '/spin-uploads',
        roles: [UserRole.Admin],
        Component: SpinUploads,
        showHorizontalNav: true,
        showVerticalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: UploadIcon,
        exact: true
    },             
    {
        label: 'Stations',
        route:  '/stations',
        roles:  [UserRole.Admin, UserRole.Employee],
        Component: Stations,
        showVerticalNav: true,        
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: RadioIcon,
        exact: true 
    },    
    {
        label: 'Stations',
        route:  '/stations/cluster',
        roles:  [UserRole.Admin],
        Component: StationSearch,
        showVerticalNav: true,        
        showHorizontalNav: true,
        Icon: RadioIcon,
        exact: true 
    },     
    {
        label: 'Stations',
        route:  '/stations/:id',
        roles:  [UserRole.Admin],
        Component: StationPage,
        showVerticalNav: true,        
        showHorizontalNav: true,
        Icon: RadioIcon,
        exact: true 
    },    
    {
        label: 'Clients',
        route:  '/clients',
        roles:  [UserRole.Admin],
        Component: Clients,
        showVerticalNav: true,        
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: ClientIcon,
        exact: true 
    },                         
    {
        label: 'Clients',
        route:  '/clients/:id',
        roles:  [UserRole.Admin],
        Component: ClientPage,
        showVerticalNav: true,        
        showHorizontalNav: true,
        Icon: ClientIcon,
        exact: true 
    },                                               
    {
        label: 'Report',
        route: '/projects/:contractId/report/:id',
        roles: [UserRole.Admin, UserRole.Employee],
        Component: ReportGenerator,
        Icon: ClipboardIcon,
        exact: true
    },                    
    {
        label: 'Users',
        route:  '/users',
        roles:  [UserRole.Admin],
        Component: Users,
        showVerticalNav: true,        
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: UsersIcon,
        exact: true 
    },                                       
    {
        label: 'Calendar',
        route:  '/calendar',
        roles:  [UserRole.Admin, UserRole.Employee],
        Component: Calendar,
        showVerticalNav: true,        
        showHorizontalNav: true,
        navPosition: NavPosition.VERTICAL,
        Icon: CalendarIcon,
        exact: true 
    },
    //keep landing route at the end for active route filtering
    {
        label: 'Landing',
        route:  '/',
        roles:  [UserRole.Visitor],
        Component: LandingPage,
        class: "landing-container",
        exact: true
    },
]

export default routes;