import React from 'react';
import Select, { SingleValue } from 'react-select';
import './select.scss';

type Option = {
    label: string;
    value: string | number;
};

type Props = {
    onChange: (name: string, value: string | number) => void;
    name: string;
    value?: string | null | number;
    options: Option[];
    placeholder: string;
    error?: boolean;
    errorText?:string;
    disabled?: boolean
};

const InputSelect: React.FC<Props> = ({ name, value, options, placeholder, onChange, error, errorText, disabled=false }) => {
    const [selected, setSelected] = React.useState<Option>();

    React.useEffect(() => {
        const newValue = options.find((element: Option) => element.value === value);
        setSelected(newValue);
    }, [value]);

    const handleChange = (option: SingleValue<Option>): void => {
        if (option) {
            onChange(name, option.value);
        }
    };
    return (
        <div className="select-container">
            <Select
                onChange={handleChange}
                name={name}
                options={options}
                value={selected}          
                placeholder={placeholder}
                classNamePrefix={error?  'error-select' : 'input-select'}
                isDisabled = {disabled}
            />
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default InputSelect;
