import { NavItem, NavPosition } from '../config/models';
import { UserRole } from '../models/User';
import config from '../config';

export const getNavItemsByRole = (role: UserRole, position?: NavPosition): Array<NavItem> => {
    const response: Array<NavItem> = [];
    config.routes.filter((element)=> element.roles.includes(role) && (position? element.navPosition === position : true)).forEach((element) => {
            response.push({
                label: element.label,
                route: element.route,
                Icon: element.Icon,
            });
    });
    return response;
};
