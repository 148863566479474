import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import Contract, { Format } from '../../models/Contract';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridRowProps,
    GridToolbar,
    GridDataStateChangeEvent,
    GridFilterCellProps,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { ContractService } from '../../api';
import { useHistory, useParams } from 'react-router-dom';
import ContractActions from './ContractActions';
import Checkbox from '../../components/Core/Checkbox';
import pagerSettings from '../../utils/pagerSettings';
import Button from '../../components/Core/Button';
import Modal from '../../components/Core/Modal';
import StripDetail from './StripDetail';
import { formatDate, getDiffernceInDays, getListFromEnum } from '../../utils';
import GridDropDownFilter from '../../components/GridDropDownFilter';

type Props = {
    clientId?: string;
};

const Contracts: React.FC<Props> = ({ clientId }) => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [contracts, setContracts] = React.useState<Contract[]>([]);
    const [showArchived, setShowArchived] = React.useState(false);
    const [showStripManagement, setShowStripManagement] = React.useState(false);
    const {id:isClientPage}= useParams<{id: string}>();
    const history = useHistory();

    React.useEffect(() => {
        getData();
    }, [showArchived, clientId]);

    React.useEffect(() => {
        //clean filter when show archived unchecked
        if (!showArchived) {
            setDataState({ ...dataState, filter: undefined });
        }
    }, [showArchived]);

    const getData = () => {
        toggleLoading(true);
        ContractService.getContracts({ clientId: clientId, showArchived: showArchived ? showArchived : undefined })
            .then((response) => {
                setContracts(response);
            })
            .finally(() => toggleLoading(false));
    };

    const durationCell = (props: GridCellProps) => {
        /* eslint-disable-next-line react/prop-types */
        const temp = props.dataItem as Contract;
        if(!temp.startDate && !temp.endDate) return <td></td>
        return <td className='clickable-grid-custom-item'>{formatDate(temp.startDate as string)}</td>;
    };

    const formats = getListFromEnum(Object.values(Format));
    const FormatFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter
            {...props}
            data={formats}
            defaultValue={{ label: 'select format', value: '' }}
            filterOperator="contains"
        />
    );

    const FormatCell = (props: GridCellProps) => {
        return (
            <td className='clickable-grid-custom-item'>
                {String(
                    props.dataItem.formats.map((el: number) => {
                        return ` ${Format[el]}`;
                    }),
                )}
            </td>
        );
    };

    const getColorCodeByPercentage = (value: number) => {
        if(value >=  80) {
            return "success";
        }
        else if(value >= 60){
            return "info";
        }
        else if(value >= 40){
            return "warning";
        }
        else if(value >= 20){
            return "primary";
        }
        else{
            return "error";
        }
    }

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        /* eslint-disable-next-line react/prop-types */
        const startDate = props.dataItem.startDate;
       
        /* eslint-disable-next-line react/prop-types */
        const endDate = props.dataItem.endDate; 
        
        const totalDifference = getDiffernceInDays(startDate, endDate);
        const currentDifference = getDiffernceInDays(new Date().toString(), endDate);

        const percentage = ((totalDifference  - currentDifference) / totalDifference) * 100;
        
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trProps: any = { className: `contract--${getColorCodeByPercentage(percentage)}` };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children as React.ReactNode);
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <ContractActions contract={props.dataItem as Contract} fetchData={getData} isClientPage={isClientPage?true: false}/>
            </td>
        );
    };

    const handleStripManagement = () => {
        setShowStripManagement(true);
    };

    const handleStripModalClose = () => {
        setShowStripManagement(false);
    };

    return (
        <div className="contracts">
            <div className="contracts-list">
                <Grid
                    style={{
                        height: '100%',
                    }}
                    sortable={true}
                    pageable={pagerSettings}
                    filterable={true}
                    data={process(contracts, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                    }}
                    onRowClick={(props) =>
                        // eslint-disable-next-line react/prop-types
                        history.push(`/projects/${props.dataItem.id}`)
                    }
                    rowRender={rowRender}
                >
                    <GridToolbar>
                        <Checkbox
                            checked={showArchived}
                            onChange={() => setShowArchived(!showArchived)}
                            label="Show archived"
                        />
                        <Button onClick={() => history.push(`/projects/add-new`)}>Create</Button>
                        <Button onClick={handleStripManagement}>Priorities</Button>
                    </GridToolbar>                  
                    <GridColumn field="artistName" title="Artist" />
                    <GridColumn field="releaseName" title="Release" />
                    <GridColumn field="labelName" title="Label" />
                    {!isClientPage && <GridColumn field="clientName" title="Client" />}
                    <GridColumn
                        field="formats"
                        title="Formats"
                        cell={FormatCell}
                        filterCell={FormatFilterCell}
                        filter="numeric"
                    />
                    <GridColumn
                        field="startDate"
                        title="Start Date"
                        cell={durationCell}
                        filterable={false}
                        sortable={false}
                    />
                    <GridColumn cell={GridActions} filterable={false} width="180px" sortable={false} />
                </Grid>
            </div>
            <Modal
                isOpen={showStripManagement}
                close={handleStripModalClose}
                title="Manage Project Priorities"
                size="large"
            >
                <StripDetail />
            </Modal>
        </div>
    );
};

export default Contracts;
