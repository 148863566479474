import React from 'react';
import { NotificationActionType, NotificationContext } from './state';
import './notification.scss';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/alert-circle.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/alert-triangle.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

const Notification: React.FC = () => {
    const { notificationState, notificationDispatch } = React.useContext(NotificationContext);

    React.useEffect(() => {
        if (notificationState.isOpen && notificationState.autoClose) {
            setTimeout(() => notificationDispatch({ type: NotificationActionType.CLOSE }), 5000);
        }
    }, [notificationState.isOpen]);

    const getIcon = (): React.ReactNode => {
        switch (notificationState.status) {
            case 'success':
                return (
                    <span className={`icon color-${notificationState.status}`}>
                        <SuccessIcon />
                    </span>
                );
            case 'error':
                return (
                    <span className={`icon color-${notificationState.status}`}>
                        <ErrorIcon />
                    </span>
                );
            case 'info':
                return (
                    <span className={`icon color-${notificationState.status}`}>
                        <InfoIcon />
                    </span>
                );
            case 'warning':
                return (
                    <span className={`icon color-${notificationState.status}`}>
                        <WarningIcon />
                    </span>
                );
            default:
                return (
                    <span className={`icon color-${notificationState.status}`}>
                        <InfoIcon />
                    </span>
                );
        }
    };

    return (
        <>
            {notificationState.isOpen && (
                <div className="notification-container">
                    {getIcon()} <p className="text">{notificationState.text}</p>
                    <span className="close-icon" onClick={() => notificationDispatch({ type: NotificationActionType.CLOSE })}>
                        &#10006;
                    </span>
                </div>
            )}
        </>
    );
};

export default Notification
