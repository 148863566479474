import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as MailingListIcon } from '../../assets/icons/mail.svg';
import Contract from '../../models/Contract';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { ContractService } from '../../api';
import { ReactComponent as ClientIcon } from '../../assets/icons/server.svg';
import { downloadFile } from '../../utils';
import { DataLoadingContext } from '../../components/DataLoading';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';

type Props = {
    contract: Contract;
    fetchData: () => void;
    isClientPage?: boolean;
};
const ContractActions: React.FC<Props> = ({ contract, fetchData, isClientPage }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const { toggleLoading } = React.useContext(DataLoadingContext);

    const handleOpenDeleteContractDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Project',
                description: `Are you sure you want to delete project for ${contract.clientName}?`,
                handleConfirm: () => handleDelete(contract.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        ContractService.deleteContract(id).then(() => {
            fetchData();
        });
    };

    const handleExportMailingList = () => {
        toggleLoading(true);
        ContractService.exportMailingList(contract.id as string).then((response) => {
            downloadFile(response.content, response.fileName);
        })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            }).finally(() => {
                toggleLoading(false);
            });
    }

    return (
        <>
            <Link to={`/projects/${contract.id}`}><IconButton
                Icon={ExternalLinkIcon}
                color="tertiary"
                tooltip='Project details'
            /></Link>
            {!isClientPage && <Link to={`/clients/${contract.clientId}`}><IconButton
                Icon={ClientIcon}
                color="tertiary"
                tooltip='Client details'
            /></Link>}
            {contract.hasMailingList &&
                <IconButton
                    Icon={MailingListIcon}
                    color="tertiary"
                    tooltip='Export mailing list'
                    onClick={handleExportMailingList}
                />
            }
            <IconButton Icon={DeleteIcon} onClick={handleOpenDeleteContractDialog} color="error" tooltip='Delete Project' />
        </>
    );
};

export default ContractActions;
