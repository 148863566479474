import React, { ChangeEvent } from "react";
import IconButton from "../IconButton";
import './multiinput.scss';
import { ReactComponent as CreateIcon } from '../../../assets/icons/plus-circle.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/minus-circle.svg';

type Props = {
    onAddRemove: (name: string, value: Array<string> ) => void;
    name: string;
    values: Array<string>;
    placeholder?: string;
    error?: boolean;
    errorText?:string;
    variant?: string;
};

const MultiInputField: React.FC<Props> = ({ onAddRemove, name, values, placeholder, error, errorText, variant }) => {
    const [value, setValue] = React.useState<string>('');
    const [_values, setValues] = React.useState<Array<string>>([]);
    const ref = React.useRef<HTMLInputElement | null>(null);

    React.useEffect(() => {
        setValues(values);
    }, [values])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    const handleAddClick = () => {
        if(!value || value.length === 0 || values.includes(value)){
            setValue('');
        }
        else{
            const newValues = _values.concat(value);
            onAddRemove(name, newValues);
            setValue('');
        }

        if(ref && ref.current){
            ref.current.focus();
        }
    };

    const handleRemoveClick = (val: string) => {
        const currentValues = _values;
        const newValues = currentValues.filter(a => a !== val);
        onAddRemove(name, newValues);
        setValues(newValues);

        if(ref && ref.current){
            ref.current.focus();
        }
    }

    return(
        <div className="multi-input-field-parent-container">
            <div className="multi-input-field-controller">
                <input className='input-field' placeholder={variant!=="outline" ? placeholder : " "} ref={ref} type={'text'} name={name}  onChange={handleChange} value={value}/>
                <IconButton Icon={CreateIcon} tooltip='Add Alias' onClick={handleAddClick} />
            </div>
            <div className="multi-input-value-container">
                {values.length > 0 && values.map((el, idx) => {
                    return (<MultiInputItem key={idx} handleRemove={handleRemoveClick} value={el} />)
                })}
            </div>
            {error && <p className="error-text">{errorText}</p>}
        </div>
    )
};

type ItemProps = {
    handleRemove: (value: string) => void;
    value: string
}

const MultiInputItem: React.FC<ItemProps> = ({handleRemove, value}) => {
    return(
        <span className="multiple-value-text-input-item">
			{value}{' '}
			<span
				className="multiple-value-text-input-item-delete-button"
				data-value={value}
				role="button"
				onClick={() => handleRemove(value)}
			>
				<IconButton Icon={RemoveIcon} tooltip='Remove Alias' />
			</span>
		</span>
    )
};

export default MultiInputField;