import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridToolbar,
    GridFilterCellProps,
    GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { isCompositeFilterDescriptor, process, State } from '@progress/kendo-data-query';
import { StationService} from '../../api';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Core/Button';
import StationActions from './StationActions';
import Typography from '../../components/Core/Typography';
import { downloadFile, formatLocation, formatPhoneNumber, getListFromEnum } from '../../utils';
import { StationGridViewModel } from '../../models/Station';
import './station.scss';
import { Format } from '../../models/Contract';
import GridDropDownFilter from '../../components/GridDropDownFilter';
import pagerSettings from '../../utils/pagerSettings';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Checkbox from '../../components/Core/Checkbox';
import ExportDJModal from './ExportDJModal';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as DJIcon } from '../../assets/icons/users.svg';
import { ReactComponent as StationIcon } from '../../assets/icons/radio.svg';

const Stations: React.FC = () => {
    const [showArchived, setShowArchived] = React.useState(false);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [stations, setStations] = React.useState<StationGridViewModel[]>([]);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const history = useHistory();
    const [formatFilterValue, setFormatFilterValue] = React.useState<Format>();
    const [filteredStationName, setFilteredStationName] = React.useState<string>();
    const [showDJExportModal, setShowDJExportModal] = React.useState<boolean>(false);
    const [isExportingDJs, setIsExportingDJs] = React.useState<boolean>(false);

    React.useEffect(() => {
        getData();

        if(!showArchived){
            setDataState({...dataState, filter: undefined});
        }
    }, [showArchived]);

    const getData = () => {
        toggleLoading(true);
        StationService.getStationsForGrid(showArchived)
            .then((response) => {
                setStations(response);
            })
            .finally(() => toggleLoading(false));
    };

    const AddressCell = (props: GridCellProps) => {
        return (
            <td className='clickable-grid-custom-item'>
                <Typography variant="caption">
                        {formatLocation(
                                props.dataItem.addressLine1,
                                props.dataItem.addressLine2,
                                props.dataItem.city,
                                props.dataItem.state,
                                props.dataItem.country,
                                props.dataItem.zip,
                            )}
                </Typography>
            </td>
        );
    };

    const PrimaryContactCell = (props: GridCellProps) => {
        return(
            <td className='clickable-grid-custom-item'>
                
                    {props.dataItem.primaryContactName &&
                       <Typography variant='caption'><strong>{props.dataItem.primaryContactName}</strong>
                        {props.dataItem.primaryContactEmailAddress &&
                            <>
                                {` | ${props.dataItem.primaryContactEmailAddress}`}
                            </>
                        }
                       </Typography>
                    }
                    {props.dataItem.primaryContactPhoneNumber &&
                        <Typography variant='caption'>{formatPhoneNumber(props.dataItem.primaryContactPhoneNumber)}</Typography>
                    }
            </td>
        )
    }

    const formats = getListFromEnum(Object.values(Format));
    const FormatFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter {...props} data={formats} defaultValue={{ label: 'select format', value: '' }} filterOperator='contains' />
    );

    const FormatCell =  (props: GridCellProps) => {
        return <td className='clickable-grid-custom-item'>{String(props.dataItem.formats.map((el:number)=>{return ` ${Format[el]}`}))}</td>;
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <StationActions station={props.dataItem as StationGridViewModel} fetchData={getData}/>
            </td>
        );
    };

    const handleDataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
        
        const filters = e.dataState.filter?.filters;

        if(filters){
            filters.forEach((el) => {
                if(!isCompositeFilterDescriptor(el)){
                    if(el.field === 'name'){
                        setFilteredStationName(el.value);
                    }
                    else if(el.field === 'formats'){
                        setFormatFilterValue(el.value);
                    }
                }
            })
        }
    }

    const handleStationExport = () => {
        StationService.exportStations(filteredStationName, formatFilterValue).then((response) => {
            downloadFile(response.content, response.fileName);
        }).catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: false,
                },
            });
        })
    }

    const handleDJExportClicked = () => {
       setShowDJExportModal(true);
    }

    const handleDJExport = (format?: Format) => {
        setIsExportingDJs(true);
        StationService.exportDJs(format).then((response) => {
            downloadFile(response.content, response.fileName);
            setShowDJExportModal(false);
        }).catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: false,
                },
            });
        }).finally(() => {
            setIsExportingDJs(false);
        })
    }

    const ExportBtn = () => {
        return(
            <div className="drop-down-button btn bg-primary color-white">
                <IconButton
                    tooltip='Export Stations'
                    Icon={StationIcon}
                    onClick={handleStationExport}
                />
                <div className='vertical-line'></div>
                <IconButton
                    tooltip='Export DJs'
                    Icon={DJIcon}
                    onClick={handleDJExportClicked}
                />
            </div >
        )
    }

    return (
        <div className="stations">
            <div className="station-list">
                <Grid
                    style={{
                        height: '100%',
                    }}
                    sortable={true}
                    pageable={pagerSettings}
                    filterable={true}
                    data={process(stations, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        handleDataStateChange(e);
                    }}
                    // eslint-disable-next-line react/prop-types
                    onRowClick={(props) => history.push(`/stations/${props.dataItem.id}`)}
                >
                    <GridToolbar>
                        <Checkbox
                            checked={showArchived}
                            onChange={() => setShowArchived(!showArchived)}
                            label="Show Inactive"
                        />
                        <Button onClick={() => history.push(`/stations/add-new`)}>Create</Button>
                        <Button color="primary" onClick={() => history.push(`/stations/cluster`)}>
                            Station Cluster
                        </Button>{' '}
                        <ExportBtn />
                    </GridToolbar>
                    <GridColumn field="priority" title="Priority" width={100} />
                    <GridColumn field="name" title="Name" />                    
                    <GridColumn field="formats" title="Formats" cell={FormatCell} filterCell={FormatFilterCell} filter="numeric"/>
                    <GridColumn field="addressLine1" title="Address" cell={AddressCell} filterable={false}/>
                    <GridColumn field="primaryContactName" title="Primary Contact" cell={PrimaryContactCell} filterable={false} />
                    <GridColumn cell={GridActions} filterable={false} />
                </Grid>
            </div>
            <ExportDJModal 
                isOpen={showDJExportModal} 
                handleClose={() => setShowDJExportModal(false)} 
                onSubmit={handleDJExport}
                isExporting={isExportingDJs} 
            />
        </div>
    );
};

export default Stations;
