import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridToolbar,
    GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { UserService } from '../../api';
import Button from '../../components/Core/Button';
import UserActions from './UserActions';
import { SystemUser } from '../../models/User';
import Checkbox from '../../components/Core/Checkbox';
import UserModal from './UserModal';
import './users.scss';
import pagerSettings from '../../utils/pagerSettings';
import UserDJsModal from './UserDJsModal';
import CallSheetPanel from '../Dashboard/CallSheetPanel';

const Users: React.FC = () => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const [users, setUsers] = React.useState<SystemUser[]>([]);
    const [showDisabled, setShowDisabled] = React.useState(false);
    const [ isUserModal, setIsUserModal] = React.useState(false);
    const [isUserDJModal, setIsUserDJModal]= React.useState(false);
    const [ selectedId, setSelectedId] = React.useState(null);
    const [isCallsheetOpen, setIsCallsheetOpen] = React.useState(false);
    const[userId, setUserId] = React.useState();
    const[selectedUserName, setSelectedUserName] = React.useState<string|undefined>();

    React.useEffect(() => {
        getData();
        
    }, [showDisabled]);


    React.useEffect(()=>{
        setDataState({...dataState, skip:0, take:dataState.take});
    }, [showDisabled]);

    const getData = () => {
        toggleLoading(true);
        UserService.getUsers(showDisabled)
            .then((response) => {
                setUsers(response);
            })
            .finally(() => toggleLoading(false));
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <UserActions user={props.dataItem as SystemUser} handleEdit={() => {setSelectedId(props.dataItem.id); setIsUserModal(true);}} fetchData={getData} handleCallSheetClick={()=>{setUserId(props.dataItem.id); setIsCallsheetOpen(true); setSelectedUserName(`${props.dataItem.firstName} ${props.dataItem.lastName}`)}} openAssociationModal={()=>{setIsUserDJModal(true); setSelectedId(props.dataItem.id)}}/>
            </td>
        );
    };

    return (
        <>
        <div className="users">
            <div className="users-list">
                <Grid
                    style={{ height: '100%' }}  
                    sortable={true}
                    pageable={pagerSettings}
                    filterable={true}
                    data={process(users, dataState)}
                    {...dataState}
                    onDataStateChange={(e:GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                      }}
                    // eslint-disable-next-line react/prop-types
                    onRowClick={(props) => {setSelectedId(props.dataItem.id); setIsUserModal(true)}}
                >
                    <GridToolbar>
                    <Checkbox
                                checked={showDisabled}
                                onChange={() => setShowDisabled(!showDisabled)}
                                label="Show disabled"
                            />
                        <Button onClick={() => setIsUserModal(true)}>Create</Button>
                    </GridToolbar>
                    <GridColumn field="name" title="Name" />
                    <GridColumn field="emailAddress" title="Email" />
                    <GridColumn field="role" title="Role" />
                    <GridColumn cell={GridActions} filterable={false} sortable={false}/>
                </Grid>
            </div>
                <UserModal
                    isOpen = {isUserModal}
                    close = {() =>{
                        setIsUserModal(false);
                        setSelectedId(null);
                    }}
                    userId = {selectedId}
                    getData = {getData}
                /> 
                <UserDJsModal open={isUserDJModal} close={()=>{setIsUserDJModal(false); setSelectedId(null);}} selectedId={selectedId}/>
                {(isCallsheetOpen) && <CallSheetPanel userId={userId} close = {()=> setIsCallsheetOpen(false)} userName={selectedUserName}/>}
        </div>
        
        </>
        
    );
};

export default Users;
