import React from 'react';
import IconButton from '../../components/Core/IconButton';
import Report from '../../models/Report';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-cloud.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';

import { ReportService } from '../../api';
import { Link } from 'react-router-dom';
import { downloadPdf } from '../../utils/downloadFile';


type Props = {
    report:Report; 
    fetchData:()=>void; 
    toggle:(val:boolean)=>void;
    contractId: string;
}
const ReportActions: React.FC<Props> = ({report,fetchData,toggle, contractId}) =>{
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

        const openDeleteDialog = (report: Report) => {
            alertDialogDispatch({
                type: AlertDialogActionType.OPEN,
                payload: {
                    title: 'Delete Feedback',
                    description: `Are you sure you want to delete this report?`,
                    handleConfirm: () => handleDelete(report.id as string),
                },
            });
        };

    //     const handlePreview = (report: Report) => {
    //     toggle(true);
    //     ReportService.previewReport(report.id as string).then((response) => {
    //         toggle(false);
    //         const pdfWindow = window.open('');
    //         pdfWindow?.document.write(
    //             "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    //                 encodeURI(response) +
    //                 "'></iframe>",
    //         );
    //     }).catch(() => {
    //         toggle(false);
    //     });
    // };

    const handleDownload = (report: Report) => {
        toggle(true);
        ReportService.downloadReport(report.id as string).then((response) => {
            toggle(false);
            downloadPdf(response.content, response.fileName);
        }).catch(() => {
            toggle(false);
        });
    };

    const handleDelete = (id: string) => {
        ReportService.deleteReport(id)
            .then(() => {
                fetchData();
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: `Report Deleted Successfully!`,
                        status: 'success',
                        autoClose: true,
                    },
                });
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            });
    };


    return (
        <>
            <Link to={{ pathname: `/projects/${contractId}/report/${report.id}` }}>
                <IconButton Icon={EditIcon} color="tertiary" tooltip="Edit  Report" />
            </Link>
            <IconButton
                onClick={() => handleDownload(report)}
                Icon={DownloadIcon}
                color="success"
                tooltip="Download report"
            />
            <IconButton
                Icon={DeleteIcon}
                color="error"
                onClick={() => openDeleteDialog(report)}
                tooltip="Delete report"
            />
        </>
    );
}

export default ReportActions;