import React from 'react';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import InputSelect from '../../components/Core/InputSelect';
import Typography from '../../components/Core/Typography';
import { DJTime, TimeZone } from '../../models/Station';
import { getCurrentTimeZone, getListFromEnum, getTimeIn12HrFormat } from '../../utils';
type Props = {
    handleChange: (name: string, value: string | boolean | number | DJTime[]) => void;
    availability: DJTime[];
    djId?: string;
};

const initAvail: DJTime = {
    dayOfWeek: '',
    startTime: '',
    endTime: '',
    timeZone: TimeZone['Eastern Standard Time (UTC-5)']
};
const weekdays = [{
    label: "Sunday",
    value: "Sunday"
}, {
    label: "Monday",
    value: "Monday"
}, {
    label: "Tuesday",
    value: "Tuesday"
}, {
    label: "Wednesday",
    value: "Wednesday"
}, {
    label: "Thursday",
    value: "Thursday"
}, {
    label: "Friday",
    value: "Friday"
}, {
    label: "Saturday",
    value: "Saturday"
}]
const DJTimeSlots: React.FC<Props> = ({ djId, availability, handleChange }) => {
    const [newAvail, setNewAvail] = React.useState<DJTime>({ ...initAvail, djId: djId, timeZone: getCurrentTimeZone() });
    const [showError, setShowError] = React.useState(false);
    const timeZones =  getListFromEnum(Object.values(TimeZone));

    const handleAvailabilityChange = (name: string, value: string | number) => {
        setNewAvail({ ...newAvail, [name]: value });
    };
    const addAvailability = () => {
        const isValid = newAvail.dayOfWeek.length > 0 && newAvail.startTime.length > 0 && newAvail.endTime.length > 0;
        if (isValid) {
            handleChange('djAvailabilities', [...availability, newAvail]);
            setNewAvail({ ...initAvail, djId: djId });
        } else {
            setShowError(true);
        }
    };

    const removeAvailability = (id: string) => {
        let temp = [...availability];
        temp = temp.filter((el) => el.id !== id);
        handleChange('djAvailabilities', temp);
    };
    return (
        <>
            <Typography variant="body" weight={500}>
                New Availability:
            </Typography>
            <div className="dj-availability">
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">Day of week</Typography>
                        <InputSelect
                            options={weekdays}
                            placeholder="Select day"
                            name="dayOfWeek"
                            value={newAvail.dayOfWeek}
                            onChange={handleAvailabilityChange}
                            error={showError && newAvail.dayOfWeek.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">Time Zone</Typography>
                        <InputSelect
                            options={timeZones}
                            placeholder={"Select TimeZone"}
                            name="timeZone"
                            value={newAvail.timeZone}
                            onChange={handleAvailabilityChange}
                        />
                    </div>
                </div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">Start Time</Typography>
                        <InputField
                            name="startTime"
                            value={newAvail.startTime}
                            onChange={handleAvailabilityChange}
                            type="time"
                            error={showError && newAvail.startTime.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">End Time</Typography>
                        <InputField
                            name="endTime"
                            value={newAvail.endTime}
                            onChange={handleAvailabilityChange}
                            type="time"
                            error={showError && newAvail.endTime.length === 0}
                            errorText="required"
                        />
                    </div>
                </div>
                <Button color="tertiary" onClick={addAvailability}>
                        Add
                </Button>
            </div>
            <Typography variant="body" weight={500}>
                Current Availabilities:
            </Typography>
            {availability.map((el, index) => (
                <div className="dj-availability" key={index}>
                    <div className="form-grid-half">
                        <div>
                            <Typography variant="body">Day of week</Typography>
                            <Typography variant="caption">{el.dayOfWeek}</Typography>
                        </div>
                        <div>
                            <Typography variant="body">Time Zone</Typography>
                            <Typography variant="caption">{TimeZone[el.timeZone]}</Typography>
                        </div>
                    </div>
                    <div className="form-grid-half">
                        <div>
                            <Typography variant="body">Start Time</Typography>
                            <Typography variant="caption">{getTimeIn12HrFormat(el.startTime.substring(0, 5))}</Typography>
                        </div>
                        <div>
                            <Typography variant="body">End Time</Typography>
                            <Typography variant="caption">{getTimeIn12HrFormat(el.endTime.substring(0, 5))}</Typography>
                        </div>
                    </div>
                    <Button color="error" onClick={() => removeAvailability(el.id as string)}>
                        Remove
                    </Button>
                </div>
            ))}
        </>
    );
};

export default DJTimeSlots;
