import React from 'react';
import LoaderGif from '../../assets/images/loader.gif';
import './loadingoverlay.scss';

type Prop = {
    children: React.ReactNode;
    loading: boolean;
    minHeight?: number;
};
const LoadingOverlay: React.FC<Prop> = ({ children, loading, minHeight }) => {
    const preventClickOnLoading = (e: React.MouseEvent): void => {
        if (loading) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    return (
        <>
            <div
                className={`loading-overlay-container${loading ? ' loader-active' : ''}`}
                onClick={preventClickOnLoading}
                style={{ minHeight: minHeight ? `${minHeight}px` : 'initial' }}
            >
                {loading && (
                    <div className="loading-icon-container">
                        <img src={LoaderGif} alt="loader" />
                    </div>
                )}
                <div className="loading-content">{children}</div>
            </div>
        </>
    );
};

export default LoadingOverlay;