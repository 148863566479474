import React from 'react';
import { StationService } from '../../api';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Toggle from '../../components/Core/Toggle/inedx';
import Typography from '../../components/Core/Typography';
import { Contact } from '../../models/Station';
import { emailIsValid, phoneNumberIsValid } from '../../utils';

type Props = {
    open: boolean;
    close: () => void;
    contact: Contact;
    getData: ()=>void;
};

const StationContactModal: React.FC<Props> = ({ contact, open, close, getData }) => {
    const [_contact, setContact] = React.useState<Contact>(contact);
    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setContact(contact);
    }, [contact]);

    const handleChange = (name: string, value: string | boolean) => {
        setContact({ ..._contact, [name]: value });
    };

    const onSubmit = () => {
        const isValid =
            _contact.firstName.length > 0 &&
            _contact.lastName.length > 0 &&
            (_contact.emailAddress ? emailIsValid(_contact.emailAddress) : true) &&
            (_contact.phoneNumber ? phoneNumberIsValid(_contact.phoneNumber) : true);
        if (isValid) {
            setLoading(true);
            const request = contact.id
                ? StationService.updateStationContact(_contact)
                : StationService.createStationContact(_contact);
            request.then(() => {getData();close();}).finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    return (
        <Modal isOpen={open} close={close} title="Station Contact" size="large">
            <div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">
                            First Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={_contact.firstName}
                            name="firstName"
                            error={showError && _contact.firstName.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Last Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={_contact.lastName}
                            name="lastName"
                            error={showError && _contact.lastName.length === 0}
                            errorText="required"
                        />
                    </div>
                </div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">Email Address</Typography>
                        <InputField
                            onChange={handleChange}
                            value={_contact.emailAddress}
                            name="emailAddress"
                            error={showError && (_contact.emailAddress ? !emailIsValid(_contact.emailAddress) : false)}
                            errorText="Invalid Email Address"
                        />
                    </div>
                    <div>
                        <Typography variant="body">Phone Number</Typography>
                        <InputField
                            onChange={handleChange}
                            value={_contact.phoneNumber}
                            name="phoneNumber"
                            error={
                                showError && (_contact.phoneNumber ? !phoneNumberIsValid(_contact.phoneNumber) : false)
                            }
                            errorText="Invalid Phone Number"
                        />
                    </div>
                    <div>
                        <Typography variant="body">Is Primary Contact?</Typography>
                        <Toggle onChange={handleChange} name="isPrimary" value={_contact.isPrimary} />
                    </div>
                </div>
                <ModalActionBar>
                    <Button onClick={close} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} color="secondary" loading={loading}>
                        {contact.id ? 'Save' : 'Submit'}
                    </Button>
                </ModalActionBar>
            </div>
        </Modal>
    );
};
export default StationContactModal;
