import React from "react";
import { ContractService } from "../../../api";
import { AddContractMailingListReq, ContractMailingListResp } from "../../../models/Contract";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { State, process } from "@progress/kendo-data-query";
import Typography from "../../../components/Core/Typography";
import { downloadFile, formatLocation } from "../../../utils";
import ContractMailingListActions from "./ContractMailingListActions";
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as MailingListIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as TrueIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as FalseIcon } from '../../../assets/icons/x-circle.svg';
import IconButton from "../../../components/Core/IconButton";
import { ReactComponent as CreateIcon } from '../../../assets/icons/plus-circle.svg';
import AddMailingListModal from "./AddMailingListModal";
import { StationDashboardViewModel } from "../../../models/Station";
import { DataLoadingContext } from "../../../components/DataLoading";
import { NotificationActionType, NotificationContext } from "../../../components/Core/Notification/state";

type Props = {
    contractId: string;
}

const ContractMailingList: React.FC<Props> = ({ contractId }) => {
    const [mailingList, setMailingList] = React.useState<ContractMailingListResp[]>([]);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isAddMailingListOpen, setIsAddMailingListOpen] = React.useState(false);
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    React.useEffect(() => {
        if (contractId) {
            getContractMailingList();
        }
    }, [contractId]);

    const getContractMailingList = () => {
        ContractService.getContractMailingList(contractId)
            .then((response) => {
                setMailingList(response);
            })
    };

    const AddressCell = (props: GridCellProps) => {
        return (
            <td className='clickable-grid-custom-item'>
                <Typography variant="caption">
                    {formatLocation(
                        props.dataItem.addressLine1,
                        props.dataItem.addressLine2,
                        props.dataItem.city,
                        props.dataItem.state,
                        props.dataItem.country,
                        props.dataItem.zip,
                    )}
                </Typography>
            </td>
        );
    };

    const MailInRequiredCell = (props: GridCellProps) => {
        return (
            <td className='clickable-grid-custom-item'>
                {
                    props.dataItem.needsMailing ?
                        <IconButton
                            Icon={TrueIcon}
                            color='success'
                        />
                        :
                        <IconButton
                            Icon={FalseIcon}
                            color='error'
                        />
                }
            </td>
        );
    };

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <ContractMailingListActions mailingList={props.dataItem as ContractMailingListResp} fetchData={getContractMailingList} />
            </td>
        );
    };

    const handleSubmit = (req: StationDashboardViewModel[]) => {
        const payload: AddContractMailingListReq[] = [];

        req.forEach(element => {
            payload.push({
                stationId: element.id,
                contractId: contractId
            })
        });

        ContractService.insertStationsToContractMailingList(payload)
            .then(() => {
                getContractMailingList();
            })
            .finally(() => {
                setIsAddMailingListOpen(false);
            })
    };

    const handleExportMailingList = () => {
        toggleLoading(true);
        ContractService.exportMailingList(contractId as string).then((response) => {
            downloadFile(response.content, response.fileName);
        })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            }).finally(() => {
                toggleLoading(false);
            });
    };

    return (
        <div className="contract-adds paper">
            <div className="adds-title">
                <div>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        Station Mailing List
                    </Typography>
                    {mailingList.length > 0 && (
                        <Typography variant="caption" color="info">
                            {mailingList.length} stations in mailing list.
                        </Typography>
                    )}
                </div>
                <div className='icons-container'>
                    <IconButton
                        Icon={CreateIcon}
                        color="success"
                        tooltip="Add stations to mailing list"
                        onClick={() => setIsAddMailingListOpen(true)}
                    />
                    {mailingList.length > 0 &&
                        <IconButton
                            onClick={handleExportMailingList}
                            Icon={MailingListIcon}
                            color='info'
                            tooltip="Export stations mailing list"
                        />
                    }
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        Icon={isExpanded ? UpArrowIcon : DownArrowIcon}
                    />
                </div>
            </div>
            {isExpanded &&
                <Grid
                    style={{
                        height: '100%',
                    }}
                    sortable={true}
                    filterable={true}
                    data={process(mailingList, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                    }}
                    pageable={true}
                >
                    <GridColumn field="name" title="Name" />
                    <GridColumn field="addressLine1" title="Address" cell={AddressCell} filterable={false} />
                    <GridColumn field="priority" title="Priority" />
                    <GridColumn field="needsMailing" title="CDs Requires Mailing" cell={MailInRequiredCell} filter="boolean"/>
                    <GridColumn cell={GridActions} filterable={false} width="180px" sortable={false} />
                </Grid>
            }

            <AddMailingListModal contractId={contractId} isOpen={isAddMailingListOpen} close={() => setIsAddMailingListOpen(false)} onSubmit={handleSubmit} />
        </div>
    )
};

export default ContractMailingList;