import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import Select, { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import StripContract from '../../models/StripContract';
import { ContractService } from '../../api';
import Typography from '../../components/Core/Typography';
import Checkbox from '../../components/Core/Checkbox';
import Performance, { NACCAdds, SpinStatus } from '../../models/ContractPerformance';
import { useDebounce } from '../../utils';

type Props = {
    spin?: Performance;
    spinIdProp?: string;
    adds?: NACCAdds;
    addsIdProp?: string;
    onSelect: (selectedContract: StripContract | undefined) => void;
}

const ContractSelector: React.FC<Props> = ({ spin, spinIdProp, adds, addsIdProp, onSelect }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedContract, setSelectedContract] = React.useState<StripContract>();
    const [contracts, setContracts] = React.useState<StripContract[]>([]);
    const [checked, setChecked] = React.useState<boolean>(false);
    const spinId = (spinIdProp) ? spinIdProp : spin?.id as string;
    const addsId = (addsIdProp) ? addsIdProp : adds?.id as string;
    const [searchValue, setSearchValue] = React.useState('');
    const debounced = useDebounce(searchValue, 500);

    React.useEffect(()=>{
        if(debounced){
        handleInputChange(debounced as string);
    }
        else{
            if(selectedContract == undefined){
                handleInputChange('');
            }
        }
    },[debounced])
    const filteredContracts = contracts.filter(
        (contract) =>
            contract.artistName.toLowerCase().startsWith(searchValue.toLowerCase())
    );

    React.useEffect(() => {
        getContracts();
    }, []);

    React.useEffect(() => {
        if (!checked) {
            getContracts();
        }
    }, [checked]);

    const handleChange = (contract: StripContract) => {
        setSelectedContract(contract);
        onSelect(contract);
    };

    const getContracts = () => {
        setIsLoading(true);
        if (spin || spinIdProp) {
            ContractService.searchSimiarContractForAStrip(spinId, 'spin')
                .then((res) => {
                    setContracts(res);
                }).finally(() => {
                    setIsLoading(false);
                })
        }
        else {
            ContractService.searchSimiarContractForAStrip(addsId, 'adds')
                .then((res) => {
                    setContracts(res);
                }).finally(() => {
                    setIsLoading(false);
                })
        }

    };


    const handleInputChange = (val: string) => {
        if(selectedContract){
        setSelectedContract(undefined);
        onSelect(undefined)
        }
            ContractService.getContractInformation(val as string)
                .then((res) => {
                    setContracts(res);
                })
    }

    return (
        <>            
        <div className='contract-checkbox-container'>
            {((spin?.status == SpinStatus.SoftMatch) || (adds?.status == SpinStatus.SoftMatch)) &&
                <Checkbox onChange={() => setChecked(!checked)} checked={checked} label="Select different contract." />}                
        </div>
        <div className="searchbar">
            {((spin?.status == SpinStatus.NoMatch || checked) || (adds?.status == SpinStatus.NoMatch || checked) || spinIdProp || addsIdProp) ?
                <div className="search-input-wrapper">
                    <Select
                        options={filteredContracts}
                        components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                        onChange={(el) => handleChange(el as StripContract)}
                        value={selectedContract}
                        isLoading={isLoading}
                        getOptionValue={(el) => el.id as string}
                        getOptionLabel={(el) => el.artistName as string}
                        placeholder={((spin?.status == SpinStatus.NoMatch) || (adds?.status == SpinStatus.NoMatch)) ? "search contracts..." : "search different contracts..."}
                        isSearchable={true}
                        menuIsOpen={true}
                        maxMenuHeight={200}
                        onInputChange={(e) => setSearchValue(e)}
                    />
                </div> :
                <div className="search-input-wrapper">
                    <Select
                        options={filteredContracts}
                        components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                        onChange={(el) => handleChange(el as StripContract)}
                        value={selectedContract}
                        isLoading={isLoading}
                        getOptionValue={(el) => el.id as string}
                        getOptionLabel={(contract) => contract.artistName}
                        placeholder="search soft matched contracts..."
                        isSearchable={true}
                        menuIsOpen={true}
                        maxMenuHeight={200}
                        onInputChange={(value) => setSearchValue(value)}
                    />
                </div>
            }
        </div>
        </>
    );
};

const Option: React.FC<OptionProps<StripContract>> = (props: OptionProps<StripContract>) => {

    return (
        <div className="option">
            <components.Option {...props} className="tag-option-item">
                <div>
                    <Typography variant="body" weight={600}>{props.data.artistName.toUpperCase()}</Typography>
                    <div className='form-grid-third'>
                        <Typography variant='caption'><strong>Client:</strong> {props.data.clientName}</Typography>
                        <Typography variant='caption'><strong>Release:</strong> {props.data.releaseName}</Typography>
                        <Typography variant='caption'><strong>Label:</strong> {props.data.labelName}</Typography>
                    </div>
                </div>
            </components.Option>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<StripContract>> = (props: DropdownIndicatorProps<StripContract>) => {
    return (
        <div className='dropdown-indicator'>
            <components.DropdownIndicator {...props}>
                <SearchIcon className='color-grey' />
            </components.DropdownIndicator>
        </div>
    )
}

export default ContractSelector;

