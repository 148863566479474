import { getFormatName } from ".";
import { Format } from "../models/Contract";
import { NACCReport, SpinReport } from "../models/ContractPerformance";

function getHtmlForNACC(naccReports: NACCReport[]) {
    const Heavy = naccReports.filter((el)=> el.genre === Format.Metal);
    const Electronic = naccReports.filter((el)=> el.genre === Format.Electronic);
    const HipHop = naccReports.filter((el)=> el.genre === Format["Hip-Hop"]);
    const Top = naccReports.filter((el)=> el.genre === Format.College);

    let html = `<strong style="font-family: 'Helvetica Neue';"><u>NACC RANK</u></strong> <br><p style="margin-top:0; font-size: 9px; margin-bottom: 0 ;font-family: 'Helvetica Neue';">`;

    if(Heavy.length>0){
        html += `<strong style="font-family: 'Helvetica Neue';"><u>Metal Rank</u></strong> <p style="margin-top:0; font-size: 9px;margin-bottom: 0 ; font-family: 'Helvetica Neue';">`
        Heavy.forEach((el)=>{
            html+=`${el.stationName}${el.stationLocation.length > 0 ? "- " + el.stationLocation : ""} (${el.naccRank})<br>`
        })
        html+="</p>"
    }
    if(Electronic.length>0){
        html += `<strong style="font-family: 'Helvetica Neue';"><u>Electronic Rank</u></strong><p style="margin-top:0; font-size: 9px;margin-bottom: 0 ; font-family: 'Helvetica Neue';">`
        Electronic.forEach((el)=>{
            html+=`${el.stationName}${el.stationLocation.length > 0 ? "- " + el.stationLocation : ""}(${el.naccRank})<br>`
        })
        html+="</p>"
    }
    if(HipHop.length>0){
        html += `<strong style="font-family: 'Helvetica Neue';"><u>HipHop Rank</u></strong><p style="margin-top:0; font-size: 9px;margin-bottom: 0 ; font-family: 'Helvetica Neue';">`
        HipHop.forEach((el)=>{
            html+=`${el.stationName}${el.stationLocation.length > 0 ? "- " + el.stationLocation : ""}(${el.naccRank})<br>`
        })
        html+="</p>"
    }
    if(Top.length>0){
        html += `<strong style="font-family: 'Helvetica Neue';"><u>College Rank</u></strong><p style="margin-top:0; font-size: 9px; margin-bottom: 0 ;font-family: 'Helvetica Neue';">`
        Top.forEach((el)=>{
            html+=`${el.stationName}${el.stationLocation.length > 0 ? "- " + el.stationLocation : ""} (${el.naccRank})<br>`
        })
        html+="</p>"
    }

    html += "</p>"
    return html;
  }

  function getHtmlForSpins(spinReports: SpinReport[]) {
    let html = `<strong style="font-family: 'Helvetica Neue';"><u>IN ROTATION</u></strong> <br><p style="margin-top:0; margin-bottom: 0 ; font-family: 'Helvetica Neue';">`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const groupedByGenre: { [key: string]: SpinReport[] } = spinReports.reduce((acc: any, obj) => {
        const key = getFormatName(obj['genre']) as string;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});

    Object.keys(groupedByGenre).forEach((key) => {
        html += `<strong style="font-family: 'Helvetica Neue';"><u>${key}</u></strong><p style="margin-top:0; font-size: 9px; font-family: 'Helvetica Neue';">`
        const genreReportSortedBySpinsNumber = groupedByGenre[key].sort((a, b) => {
            const spinCountComparison = parseInt(b.spinCount) - parseInt(a.spinCount);          
            if (spinCountComparison === 0) {
              return a.stationName.localeCompare(b.stationName);
            }          
            return spinCountComparison;
        });
        
        genreReportSortedBySpinsNumber.forEach((el: SpinReport) => {
            html += `${el.stationName}${el.stationLocation.length > 0 ? "- " + el.stationLocation : ""} (${el.spinCount}x)<br>`
        })
        html+="</p>"
    })
    
    html += "</p>"
    return html;
  }

  export {
    getHtmlForNACC,
    getHtmlForSpins
  }