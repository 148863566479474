import React from 'react';
import InputField from '../../components/Core/InputField';
import Typography from '../../components/Core/Typography';
import { PerformanceType, SpinAddReq } from '../../models/ContractPerformance';
import { formatDate, getListFromEnum} from '../../utils';
import Button from '../../components/Core/Button';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import DateInput from '../../components/DateInput';
import InputSelect from '../../components/Core/InputSelect';
import { Format } from '../../models/Contract';

type Props = {
    isOpen: boolean;
    close: () => void;
    handleSubmit: (data: SpinAddReq) => Promise<boolean>;
    contractId: string;
};

const PerformanceAdd: React.FC<Props> = ({ isOpen, close, handleSubmit, contractId }) => {
    const [week, setWeek] = React.useState<string>();
    const [date, setDate] = React.useState(new Date());
    const [station, setStation] = React.useState<string>();
    const [market, setMarket] = React.useState<string>();
    const [spins, setSpins] = React.useState<string>();
    const [rank, setRank] = React.useState<string>();
    const [otherSource, setOtherSource] = React.useState<string>();
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const[dateError, setDateError] = React.useState(false);
    const [format, setFormat] = React.useState<Format | undefined>(undefined);
    const formats = getListFromEnum(Object.values(Format));

    React.useEffect(() => {
        setDateError(false);
        if (date.getDay() !== 3) { // Wednesday is represented by 3
            date.setDate(date.getDate() - ((date.getDay() - 3 + 7) % 7));
          }
          
          // set the first day of the week (Wednesday) as the start of the range
          const first = formatDate(new Date(date.getTime()));
          
          // set the date to the next Tuesday
          date.setDate(date.getDate() + 6);
          
          // set the last day of the week (Tuesday) as the end of the range
          const last = formatDate(new Date(date.getTime()));

            if(first=="Invalid Date" || last == "Invalid Date" || first.length==0 || last.length == 0){
            setDateError(true);
            setWeek("Invalid Date");

            }
        else{
            const weekString = `${first}-${last}`;
            setWeek(weekString)
        }

    }, [date]);
    
    const onSubmit = () => {
        const isValid = week && week.length > 0 &&
        station && station.length > 0 &&
        contractId && spins && spins.length > 0 && otherSource && otherSource.length > 0 && format != undefined;
        if (isValid) {
            setShowError(false);
            setLoading(true);
            handleSubmit({
                contractId: contractId,
                week: week as string,
                station: station as string,
                spins: spins.toString(),
                rank: rank ? rank.toString() : '',
                market: market && market.length > 0 ? market as string : '',
                otherSource: otherSource as string,
                type: PerformanceType.OTHER,
                genre: format
            })
                .then(() => {setError(''); setMarket(undefined); setRank(undefined); setStation(undefined); setSpins(undefined);})
                .catch((e) => setError(e))
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    const handleDateChange = (_name: string, value: string) => {
        const date = new Date(value + " 00:00:00"); // Set the time to 00:00:00
        const options = { timeZone: "America/New_York" };
        const dateString = date.toLocaleString("en-US", options);
        const dateInTimeZone = new Date(dateString);
        setDate(new Date(dateInTimeZone));
    };

    const handleStationChange = (_name: string, value: string) => {
        setStation(value);
    }

    const handleMarketChange = (_name: string, value: string) => {
        setMarket(value);
    }

    const handleSpinChange = (_name: string, value: string) => {
        setSpins(value);
    }

    const handleRankChange = (_name: string, value: string) => {
        setRank(value);
    }

    const handleOtherSourceChange = (_name: string, value: string) => {
        setOtherSource(value);
    }

    const handleFormatChange = (_name: string, value: number) => {
        setFormat(value);
    }

    return (
        <Modal isOpen={isOpen} close={close} size='large' title="Add performance data from outside source">
            <>
                {error.length>0 && <Typography variant='body' color="error" textAlign='center'>{error}</Typography>}
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">
                            Source <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleOtherSourceChange}
                            name="otherSource"
                            type="text"
                            value={otherSource}
                            required={true}
                            error={showError && (!otherSource || otherSource.length === 0)}
                            errorText={'required'}
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Genre <span className="color-error">*</span>
                        </Typography>
                        <InputSelect
                            options={formats}
                            value={format}
                            name="format"
                            placeholder='Select Genre'
                            onChange={(name, value) => handleFormatChange(name, value as number)}
                            error={showError && (format === undefined)}
                            errorText='required'
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Date <span className="color-error">*</span>
                        </Typography>
                        <DateInput
                        onChange={handleDateChange}
                        name = "date"
                        value={date.toLocaleDateString('en-US')}
                        error = {dateError}
                        />
                        <div className="week-seletion">
                        <Typography variant="caption" color={dateError? "error": "info"} textAlign="right">
                                {week}
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <Typography variant="body">
                            Station <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleStationChange}
                            name="station"
                            type="text"
                            value={station}
                            required={true}
                            error={showError && (!station || station.length === 0)}
                            errorText={'required'}
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Market
                        </Typography>
                        <InputField
                            onChange={handleMarketChange}
                            name="market"
                            type="text"
                            value={market}
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Spins <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleSpinChange}
                            name="spins"
                            type="number"
                            value={spins}
                            required={true}
                            error={showError && (!spins || spins.length === 0)}
                            errorText={'required'}
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Rank
                        </Typography>
                        <InputField
                            onChange={handleRankChange}
                            name="rank"
                            type="number"
                            value={rank}
                        />
                    </div>

                </div>
                <ModalActionBar>
                    <Button onClick={onSubmit} loading={loading}>
                        Submit
                    </Button>
                </ModalActionBar>
            </>
        </Modal>
    );
};

export default PerformanceAdd;
