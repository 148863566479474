import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { useDebounce } from '../../../utils';
import { SearchStationActionType, SearchStationContext } from './state';
import { StationService } from '../../../api';
import { DataLoadingContext } from '../../../components/DataLoading';
import { StationDashboardViewModel } from '../../../models/Station';
import Select, { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import Typography from '../../../components/Core/Typography';
import { Format } from '../../../models/Contract';

const SearchBar: React.FC = () => {
    const { dispatch, state } = React.useContext(SearchStationContext);
    const [inputValue, setInputValue] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const debounced = useDebounce(inputValue, 500);

    React.useEffect(() => {
        if(state.selectedStation){
            dispatch({
                type: SearchStationActionType.TOGGLE_SEARCH
            });
        }
    }, [state.selectedStation])

    const handleChange = (newStation: StationDashboardViewModel) => {
        dispatch({
            type: SearchStationActionType.SET_SELECTED_STATION,
            payload: newStation,
        });
    };

    React.useEffect(() => {
        getStations();
    }, [debounced]);

    const getStations = () => {
        toggleLoading(true);
        setIsLoading(true);
        StationService.getStationsForDashboard(debounced as string)
        .then((res) => {
            dispatch({type: SearchStationActionType.SET_DROP_DOWN_STATIONS, payload: res});
        }).finally(() => {
            toggleLoading(false);
            setIsLoading(false);
        })
    };

    return (
        <div className="searchbar">
            <div className="search-input-wrapper">
                <Select
                    className='search-input'
                    options={state.dropDownSearchStations}
                    components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                    onChange={(el) => handleChange(el as StationDashboardViewModel)}
                    value={state.selectedStation}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    isLoading={isLoading}
                    getOptionValue={(el) => el.id as string}
                    getOptionLabel={(el) => el.name as string}
                    placeholder="search stations..."
                    isSearchable={true}
                    noOptionsMessage={()=>{return <>Enter station name</>}}
                />
            </div>
        </div>
    );
};

const Option: React.FC<OptionProps<StationDashboardViewModel>> = (props: OptionProps<StationDashboardViewModel>) => {

    return (
        <div className="option">
            <components.Option {...props} className="tag-option-item">
                <div className="details">
                    <div>
                        <Typography variant="body" weight={600}>
                            {props.data.name}
                        </Typography>
                        {props.data.formats&&<Typography variant="caption">
                            <strong>Formats: </strong>
                            {
                               String(props.data.formats.map((el:number)=>{return ` ${Format[el]}`}))
                            }
                        </Typography>}
                        {(props.data.city || props.data.state) && 
                            <Typography variant='caption'>
                                <strong>Market: </strong>
                                {props.data.city && <>{props.data.city}</>}
                                {props.data.state && <>{props.data.city && ', '}{props.data.state}</>}
                                {props.data.country && <>{props.data.state && ', '}{props.data.country}</>}
                            </Typography>
                        }
                    </div>
                </div>
            </components.Option>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<StationDashboardViewModel>> = (props: DropdownIndicatorProps<StationDashboardViewModel>) => {
    return(
        <div className='dropdown-indicator'>
            <components.DropdownIndicator {...props}>
                <SearchIcon className='color-grey' />
            </components.DropdownIndicator>
        </div>
    )
}

export default SearchBar;

