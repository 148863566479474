import { Format } from "../../../models/Contract";
import { StationDashboardViewModel } from "../../../models/Station";
import StripContract from "../../../models/StripContract";

export enum DashBoardActionType {
    SET_CONTRACTS,
    SET_STATIONS,
    SELECT_CONTRACT,
    SELECT_STATION,
    SELECT_FORMAT,
    SELECT_FEEDBACK,
}

//actions
export type DashBoardAction = 
{ type: DashBoardActionType.SET_CONTRACTS; payload: StripContract[] } 
| { type: DashBoardActionType.SET_STATIONS, payload: StationDashboardViewModel[] }
| {type: DashBoardActionType.SELECT_CONTRACT, payload: StripContract}
| {type: DashBoardActionType.SELECT_STATION, payload?: StationDashboardViewModel}
| {type: DashBoardActionType.SELECT_FORMAT, payload: Format}
| {type: DashBoardActionType.SELECT_FEEDBACK, payload: string}
