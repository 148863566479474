import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClientService } from '../../api';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import Typography from '../../components/Core/Typography';
import Client from '../../models/Client';
import { emailIsValid } from '../../utils';

type Props = {
    client: Client;
    toggleView: () => void;
};

const ClientForm: React.FC<Props> = ({ client, toggleView }) => {
    const [_client, setClient] = React.useState(client);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const handleChange = (name: string, value: string|number) => {
        setClient({ ..._client, [name]: value });
    };

    const handleSubmit = () => {
        const isValid =
            _client.name.length > 0 &&
            _client.contactName.length > 0 &&
            (_client.contactEmail ? emailIsValid(_client.contactEmail): true);
        setShowError(!isValid);
        if (isValid) {
            setLoading(true);
            const request = _client.id ? ClientService.updateClient(_client) : ClientService.createClient(_client);
            request
                .then(() => {
                    history.goBack();
                })
                .finally(() => setLoading(false));
        }
    };

    const handleCancel = () => {
        if (client.id) {
            toggleView();
        } else {
            history.goBack();
        }
    };
    return (
        <div className="client-form paper">
            <Typography variant="subheading" weight={500} color="secondary" textAlign="center">
                <span className="page-title">{client.id ? client.name : 'CREATE NEW CLIENT'}</span>
            </Typography>
            <br />
            <br />
            <Typography variant="caption" textAlign="right" weight={500}>
                <span className="color-error">*</span> indicates required
            </Typography>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Client Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_client.name}
                        name="name"
                        error={showError && _client.name.length === 0}
                        errorText="required"
                    />
                </div>
            </div>
            <br />
            <Typography variant="subtitle" color="primary" weight={500}>
                CONTACT DETAIL
            </Typography>
            <br />
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_client.contactName}
                        name="contactName"
                        error={showError && _client.contactName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Email Address
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_client.contactEmail}
                        name="contactEmail"
                        error={showError && (_client.contactEmail ? !emailIsValid(_client.contactEmail): false)}
                        errorText="enter a valid email"
                    />
                </div>
            </div>
            <div className="action-container">
                <Button color="primary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit} loading={loading}>
                    {client.id ? 'Save' : 'Submit'}
                </Button>
            </div>
        </div>
    );
};

export default ClientForm;
