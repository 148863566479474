import { Format } from '../models/Contract';
import FileExportResponse from '../models/FileExportResponse';
import Station, { Contact, DJ, DJCaller, DJDashboardViewModel, StationClusterSearchReq, StationDashboardViewModel, StationGridViewModel } from '../models/Station';
import instance from './instance';

function getStationsForDashboard(name?: string): Promise<StationDashboardViewModel[]> {

    let apiRoute = 'radiostations/dashboard';

    if(name) apiRoute += `?name=${name}`;

    return instance.get(apiRoute).then((response) => {
        return response.data;
    });
}

function getDJsForDashboard(name?: string): Promise<DJDashboardViewModel[]> {
    return instance.get(`djs/dashboard${name ? `?name=${name}` : ''}`).then((response) => {
        return response.data;
    });
}

function getStationsForGrid(showArchived: boolean): Promise<StationGridViewModel[]> {
    return instance.get(`radiostations?showArchived=${showArchived}`).then((response) => {
        return response.data as StationGridViewModel[];
    });
}

function createStation(Station: Station): Promise<Station> {
    return instance.post(`radiostation`, Station).then((response) => {
        return response.data;
    });
}

function getStation(id: string): Promise<Station> {
    return instance.get(`radiostation/${id}`).then((response) => {
        return response.data;
    });
}

function deleteStation(id: string): Promise<boolean> {
    return instance.delete(`radiostation/${id}`).then((response) => {
        return response.data;
    });
}

function updateStation(Station: Station): Promise<Station> {
    return instance.put(`radiostation`, Station).then((response) => {
        return response.data;
    });
}

function createStationContact(contact: Contact): Promise<boolean> {
    return instance.post(`radiostation/contact`, contact).then((response) => {
        return response.data;
    });
}

function getAllStationContact(stationId: string): Promise<Contact[]> {
    return instance.get(`radiostation/${stationId}/contacts`).then((response) => {
        return response.data;
    });
}

function updateStationContact(contact: Contact): Promise<boolean> {
    return instance.put(`radiostation/contact`, contact).then((response) => {
        return response.data;
    });
}

function deleteStationContact(contactId: string): Promise<boolean> {
    return instance.delete(`radiostation/contact/${contactId}`).then((response) => {
        return response.data;
    });
}

function createStationDJ(dj: DJ): Promise<boolean> {
    return instance.post(`radiostation/dj`, dj).then((response) => {
        return response.data;
    });
}

function getAllStationDJ(stationId: string): Promise<DJ[]> {
    return instance.get(`radiostation/${stationId}/djs`).then((response) => {
        return response.data;
    });
}

function updateStationDJ(dj: DJ): Promise<boolean> {
    return instance.put(`radiostation/dj`, dj).then((response) => {
        return response.data;
    });
}

function deleteStationDJ(djId: string): Promise<boolean> {
    return instance.delete(`radiostation/dj/${djId}`).then((response) => {
        return response.data;
    });
}

function getStationDJ(id: string): Promise<DJ> {
    return instance.get(`djs/${id}`).then((response) => {
        return response.data;
    });
}

function exportStations(stationName: string | undefined, format: Format | undefined): Promise<FileExportResponse> {

    let apiRoute = 'stations/export';

    if(stationName) apiRoute += `?stationName=${stationName}`;
    if(format) apiRoute += `${stationName ? '&' : '?'}format=${format}`;

    return instance.get(apiRoute).then((response) => {
        return response.data;
    });
}

function exportDJs(format: Format | undefined): Promise<FileExportResponse> {

    let apiRoute = 'djs/export';

    if(format) apiRoute += `?format=${format}`;

    return instance.get(apiRoute).then((response) => {
        return response.data;
    });
}

function getDJCallers(id:string): Promise<DJCaller[]> {
    return instance.get(`dj/${id}/callers`).then((response)=> {
        return response.data;
    })
}

function updateDJCallers(id:string, users:string[]): Promise<boolean> {
    return instance.put(`dj/${id}/callers`, users).then((response)=> {
        return response.data;
    })
}

function searchStationCluster(req: StationClusterSearchReq): Promise<{items: Array<StationGridViewModel>, totalItems: number, currentPage: number}> {
    return instance.post('radiostation/cluster-search', req).then((response) => {
        return response.data;
    })
}

function exportSearchStationCluster(req: StationClusterSearchReq) : Promise<FileExportResponse> {
    return instance.post('radiostation/cluster-search/export', req).then((response) => {
        return response.data;
    })
}

function archiveStation(id: string): Promise<boolean> {
    return instance.put(`radiostation/archive/${id}`).then((response) => {
        return response.data;
    });
}

function unarchiveStation(id: string): Promise<boolean> {
    return instance.put(`radiostation/unarchive/${id}`).then((response) => {
        return response.data;
    });
}

export {
    getStationsForDashboard,
    getDJsForDashboard,
    getStationsForGrid,
    createStation,
    updateStation,
    getStation,
    deleteStation,
    createStationContact,
    getAllStationContact,
    updateStationContact,
    deleteStationContact,
    getAllStationDJ,
    createStationDJ,
    updateStationDJ,
    deleteStationDJ,
    getStationDJ,
    exportStations,
    exportDJs,
    updateDJCallers,
    getDJCallers,
    searchStationCluster,
    exportSearchStationCluster,
    archiveStation,
    unarchiveStation
};
