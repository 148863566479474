import React from 'react';
import { useHistory } from 'react-router-dom';
import { StationService } from '../../api';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import InputMultiSelect from '../../components/Core/InputMultiSelect';
import InputSelect from '../../components/Core/InputSelect';
import MultiInputField from '../../components/Core/MultiInputField';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import TextArea from '../../components/Core/TextArea';
import Toggle from '../../components/Core/Toggle/inedx';
import Typography from '../../components/Core/Typography';
import { Format } from '../../models/Contract';
import Station from '../../models/Station';
import { emailIsValid, getListFromEnum, getStates, phoneNumberIsValid, zipCodeIsValid } from '../../utils';
import { getProvinces } from '../../utils/getStates';

type Props = {
    station: Station;
    toggleView: () => void;
};

const StationForm: React.FC<Props> = ({ station, toggleView }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [_station, setStation] = React.useState(station);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const states = getStates();
    const provinces = getProvinces();
    const formats = getListFromEnum(Object.values(Format));

    const countries: Array<{ value: string; label: string }> = [
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' }
    ]

    const handleChange = (name: string, value: string | boolean | Array<string | number> | number) => {
        if(name === 'country'){
                setStation({ ..._station, country: value as string, state: value=='US'?'AL' : 'AB'});      
        }
        else{
            setStation({ ..._station, [name]: value });
        }
    };

    const handleAliasChange = (name: string, value: Array<string>) => {
        setStation({ ..._station, [name]: value });
    }

    const handleCancel = () => {
        if (station.id) {
            toggleView();
        } else {
            history.push('/stations');
        }
    };

    const handleSubmit = () => {
        const isValid =
            _station.name.length > 0 &&
            _station.formats.length !== 0 &&
            (((_station.zip === undefined || _station.zip === null || (_station.country === 'CA' && _station.zip.length>0)) ? true : zipCodeIsValid(_station.zip))) &&
            (((_station.hotLine === undefined || _station.hotLine === null || _station.hotLine.length===0) ? true : phoneNumberIsValid(_station.hotLine))) && 
            (((_station.emailAddress === undefined || _station.emailAddress === null|| _station.emailAddress.length===0) ? true : emailIsValid(_station.emailAddress)));

        setShowError(!isValid);
        if (isValid) {
            setLoading(true);
            const request = _station.id
                ? StationService.updateStation(_station)
                : StationService.createStation(_station);

            request
                .then(() => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: 'station was successfully updated.',
                            status: 'success',
                            autoClose: false,
                        },
                    });
                    history.push('/stations');
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="station-form paper">
            <Typography variant="subheading" weight={500} color="secondary" textAlign="center">
                <span className="page-title">{station.id ? station.name : 'CREATE NEW STATION'}</span>
            </Typography>
            <br />
            <br />
            <Typography variant="caption" weight={500} textAlign="right">
                <span className="color-error">*</span> indicates required
            </Typography>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Station Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={_station.name}
                        name="name"
                        error={showError && _station.name.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Station Aliases
                    </Typography>
                    <MultiInputField
                        onAddRemove={handleAliasChange}
                        values={_station.aliases}
                        name="aliases"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Format(s) <span className="color-error">*</span>
                    </Typography>
                    <InputMultiSelect
                        options={formats}
                        value={_station.formats}
                        name="formats"
                        placeholder='Select station format(s)'
                        onChange={handleChange}
                        error={showError && _station.formats.length === 0}
                        errorText='required'
                    />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">
                        Country
                    </Typography>
                    <InputSelect
                        onChange={handleChange}
                        name="country"
                        value={_station.country}
                        options={countries}
                        placeholder="Select a country"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Street Address
                    </Typography>
                    <InputField onChange={handleChange} value={_station.addressLine1} name="addressLine1" />
                </div>
                <div>
                    <Typography variant="body">Apt/Unit/Suite</Typography>
                    <InputField onChange={handleChange} value={_station.addressLine2} name="addressLine2" />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">City</Typography>
                    <InputField onChange={handleChange} value={_station.city} name="city" />
                </div>
                {(!_station.country || _station.country && _station.country === 'US') ?
                    <div>
                        <Typography variant="body">State</Typography>
                        <InputSelect onChange={handleChange} value={_station.state} name="state" options={states} placeholder='State'  />
                    </div>
                    :
                    <div>
                        <Typography variant="body">Province</Typography>
                        <InputSelect onChange={handleChange} value={_station.state} name="state" options={provinces} placeholder='Province' />
                    </div>
                }
                <div>
                    <Typography variant="body">{(!_station.country || _station.country && _station.country === 'US') ? 'Zip Code' : 'Postal Code'}</Typography>
                    <InputField
                        onChange={handleChange}
                        value={_station.zip}
                        name="zip"
                        type={(!_station.country || _station.country && _station.country === 'US') ? "number" : "text"}
                        error={showError && ((_station.zip !== undefined && _station.zip !== null && _station.country==='US') && !zipCodeIsValid(_station.zip))}
                        errorText={(!_station.country || _station.country && _station.country === 'US') ? 'Invalid zip code' : 'Invalid postal code'}
                    />
                </div>
            </div>
            <div className="form-grid-third">
                <div>
                    <Typography variant="body">Phone Number</Typography>
                    <InputField
                        onChange={handleChange}
                        value={_station.hotLine}
                        error={showError && ((_station.hotLine !== undefined && _station.hotLine !== null && _station.hotLine.length!=0) && !phoneNumberIsValid(_station.hotLine))}
                        errorText="Invalid Phone Number"
                        name="hotLine"
                    />
                </div>
                <div>
                    <Typography variant="body">Email Address</Typography>
                    <InputField
                        onChange={handleChange}
                        value={_station.emailAddress}
                        error={showError && ((_station.emailAddress !== undefined && _station.emailAddress !== null && _station.emailAddress.length!=0) && !emailIsValid(_station.emailAddress))}
                        type="text"
                        errorText="Invalid Email Address"
                        name="emailAddress"
                    />
                </div>
                <div>
                    <Typography variant="body">Priority</Typography>
                    <InputField
                        onChange={handleChange}
                        value={_station.priority}
                        type="number"
                        name="priority"
                    />
                </div>
            </div>
            <div>
                <Typography variant="body">Notes</Typography>
                <TextArea onChange={handleChange} value={_station.notes} name="notes" />
            </div>
            <Typography variant="body">CD Requires Mailing?</Typography>
            <Toggle onChange={handleChange} value={_station.needsMailing} name="needsMailing" />

            <Typography variant="body">Is Live?</Typography>
            <Toggle onChange={handleChange} value={_station.isLive} name="isLive" />

            <div className=" action-container">
                <Button color="primary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit} loading={loading}>
                    {station.id ? 'Save' : 'Submit'}
                </Button>
            </div>
        </div>
    );
};

export default StationForm;
