import React from 'react';
import { UserService } from '../../api';
import InputField from '../../components/Core/InputField';
import InputSelect from '../../components/Core/InputSelect';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Typography from '../../components/Core/Typography';
import { SystemUser, UserRole } from '../../models/User';
import Button from '../../components/Core/Button';
import { emailIsValid } from '../../utils';

type Props = {
    isOpen: boolean;
    close: () => void;
    getData: () => void;
    userId: string | null;
};
const initUser: SystemUser = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    role: UserRole.Employee,
};

const availRoles = [
    {
        label: 'Admin',
        value: 'Admin',
    },
    {
        label: 'Employee',
        value: 'Employee',
    },
];
const UserModal: React.FC<Props> = ({ userId, isOpen, close, getData }) => {
    const [showError, setShowError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [user, setUser] = React.useState<SystemUser>(initUser);

    React.useEffect(() => {
        if (userId) {
            UserService.getUser(userId).then((response) => setUser(response));
        } else {
            setUser(initUser);
        }
    }, [userId]);

    const closeUserModal = () => {
        close();
        setIsLoading(false);
        setUser(initUser);
        setShowError(false);
    };

    const handleChange = (name: string, value: string | number) => {
        user && setUser({ ...user, [name]: value });
    };

    const handleRoleChange = () => {
        UserService.changeRole(user?.id as string, user.role).then(() =>
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `User role updated successfully`,
                    status: 'info',
                    autoClose: true,
                },
            }),
        );
    };

    const handleSubmit = (): void => {
        const isValid =
            user &&
            user.firstName.length > 0 &&
            user.lastName.length > 0 &&
            user.role.length > 0 &&
            emailIsValid(user.emailAddress);

        setShowError(!isValid);
        if (isValid) {
            setIsLoading(true);
            const request = userId ? UserService.updateUser(user) : UserService.createUser(user);
            request
                .then(() => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: userId ? `User profile updated successfully` : `User registered successfully`,
                            status: 'info',
                            autoClose: true,
                        },
                    });
                    getData();
                    closeUserModal();
                })
                .catch((e) => {
                    setIsLoading(false);
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                });
        }
    };

    return (
        <Modal isOpen={isOpen} close={close} title={userId ? 'Edit User' : 'Add New User'} size="medium">
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        First Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="firstName"
                        value={user.firstName}
                        error={showError && user.firstName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Last Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        name="lastName"
                        value={user.lastName}
                        error={showError && user.lastName.length === 0}
                        errorText="required"
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Email Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        onChange={handleChange}
                        value={user.emailAddress}
                        name="emailAddress"
                        type="email"
                        error={showError && !emailIsValid(user.emailAddress)}
                        errorText="Invalid email address"
                    />
                </div>
            </div>
            <Typography variant="body">Role</Typography>
            <div className="user-role">
                <InputSelect
                    options={availRoles}
                    value={user.role}
                    name="role"
                    placeholder="Role"
                    onChange={handleChange}
                    error={showError && user.role.length === 0}
                    errorText="required"
                />
                {userId && (
                    <Button color="tertiary" onClick={handleRoleChange}>
                        Update Role
                    </Button>
                )}
            </div>
            <ModalActionBar>
                <Button onClick={closeUserModal} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="secondary" disabled={isLoading} loading={isLoading}>
                    {userId ? 'Save' : 'Submit'}
                </Button>
            </ModalActionBar>
        </Modal>
    );
};

export default UserModal;
