import React from 'react';
import Button from '../../../components/Core/Button';
import InputField from '../../../components/Core/InputField';
import Typography from '../../../components/Core/Typography';
import { emailIsValid } from '../../../utils';
import './login.scss';
import { NotificationActionType, NotificationContext } from '../../../components/Core/Notification/state';
import { AuthActionType, AuthContext } from '../../Authentication/state';
import { AuthService } from '../../../api';
import { getRedirectRoute } from '../../../components/AppRouter/PrivateRoute';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';

type login = {
    email: string;
    password: string;
};

type LocationState = {
    from: {
        pathname: string;
    }
};

const Login: React.FC = () => {

    const history = useHistory();
    const location = useLocation<LocationState>();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const authDispatch = React.useContext(AuthContext).dispatch;
    const [isLoading, setIsLoading] = React.useState(false);
    const { from } = location.state ? location.state : {from: undefined};

    const initLogin: login = {
        email: '',
        password: '',
    };

    const [login, setLogin] = React.useState(initLogin);

    const handleLogin = () => {
        setIsLoading(true);
        AuthService.login(login.email, login.password)
            .then((response) => {
                new Promise((resolve) => {
                    localStorage.setItem('snd_login_token', response.access_Token);
                    authDispatch({ type: AuthActionType.LOGIN, payload: response });
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Welcome back ${response.name}!`,
                            status: 'info',
                            autoClose: true,
                        },
                    });
                    setIsLoading(false);
                    resolve('OK');
                }).then(() => {
                    setIsLoading(false);
                    const redirectUrl:string = from ? from.pathname: getRedirectRoute(response.role);
                    history.push(redirectUrl);
                });
            })
            .catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
                setIsLoading(false);
            });
    };
    const handleChange = (name: string, value: string): void => {
        setLogin({ ...login, [name]: value });
    };
    
    return (
        <div className="login-container paper bg-white">
            <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                }}
            >
                <Typography variant="title" weight={500} color="secondary">
                    LOGIN
                </Typography>
                <hr />
                <InputField
                    onChange={handleChange}
                    name="email"
                    type="email"
                    variant="outline"
                    outlineLabel="Email"
                    error={login.email.length > 0 && !emailIsValid(login.email)}
                    errorText="Email is invalid"
                    required={true}
                />
                <InputField
                    onChange={handleChange}
                    name="password"
                    type="password"
                    variant="outline"
                    outlineLabel="Password"
                    required={true}
                />
                <div className="action-container">
                    <Typography variant="caption">
                        <Link to="/forgot-password"><span
                            className="text-action"
                        >
                            Forgot Password?
                        </span></Link>
                    </Typography>
                    <Button
                        color="primary"
                        loading={isLoading}
                        type="submit"
                    >
                        Login
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Login;
