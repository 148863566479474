import React from "react";
import { StationService } from "../../../api";
import { NotificationActionType, NotificationContext } from "../../../components/Core/Notification/state";
import { DataLoadingContext } from "../../../components/DataLoading";
import { StationClusterSearchReq } from "../../../models/Station";
import { downloadFile } from "../../../utils";
import ClusterList from "./ClusterList";
import Pagination from "./Pagination";
import SearchBar from "./SearchBar";
import SearchFilters from "./SearchFilters";
import { initialState, SearchReducer, SearchStationActionType, SearchStationContext } from "./state";
import './stationsearch.scss';

const StationSearch: React.FC = () => {
    const [state, dispatch] = React.useReducer(SearchReducer, initialState);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    React.useEffect(() => {
        if(state.initiateSearch){
            getData();  
        }
    }, [state.initiateSearch]);


    const getData = () => {
        if(state.distance && 
            (state.country && state.country.length > 0)
        ){
            const req: StationClusterSearchReq = {
                country: state.country,
                distance: state.distance as number,
                city: state.city,
                state: state.searchState,
                zip: state.zip,
                stationId: state.selectedStation?.id,
                page: state.page,
                pageSize: state.pageSize,
                formats: state.formats
            }
            toggleLoading(true);
            StationService.searchStationCluster(req)
            .then((response) => {
                dispatch({
                    type: SearchStationActionType.SET_SHOW_CLUSTER,
                    payload: true
                });
                dispatch({
                    type: SearchStationActionType.SET_MAIN_STATIONS,
                    payload: response.items
                });
                dispatch({
                    type: SearchStationActionType.SET_TOTAL,
                    payload: response.totalItems
                })
                dispatch({
                    type: SearchStationActionType.SET_PAGE,
                    payload: { page: response.currentPage, pageSize: state.pageSize }
                })
            }).then(()=>toggleLoading(false)).finally(() => {               
                dispatch({type: SearchStationActionType.TOGGLE_SEARCH})
            })
            
        }
    }

    const handleExport = () => {
        if(state.distance && 
            (state.city && state.city.length > 0) ||
            (state.searchState && state.searchState.length > 0) ||
            (state.selectedStation) || 
            (state.zip)
        ){
            toggleLoading(true);
            const req: StationClusterSearchReq = {
                distance: state.distance as number,               
                country: state.country,
                city: state.city,
                state: state.searchState,
                zip: state.zip,
                stationId: state.selectedStation?.id,
                page: state.page,
                pageSize: state.pageSize,
                formats: state.formats
            }
            StationService.exportSearchStationCluster(req).then((response) => {
                downloadFile(response.content, response.fileName);
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: false,
                    },
                });
            })
            .finally(() => {
                toggleLoading(false);
            })
        }
    }

    return(
        <div className="station-search">
            <SearchStationContext.Provider value={{ state, dispatch }}>
                <SearchFilters downloadFile={handleExport}/>
                <SearchBar />
                <Pagination />
                {state.showData && <ClusterList/>}
                <Pagination />
            </SearchStationContext.Provider>
        </div>
    );
};

export default StationSearch;