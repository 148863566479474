import React from 'react';
import { UserService } from '../../api';
import Typography from '../../components/Core/Typography';
import Callsheet from '../../models/CallSheet';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-cloud.svg';
import IconButton from '../../components/Core/IconButton';
import { convertToCurrentTimeZone, downloadFile, formatPhoneNumber, getTimeIn12HrFormat } from '../../utils';
import { Format } from '../../models/Contract';
import { DashboardContext } from './state';
const weekdays = [
    {
        label: 'Sunday',
        value: 'Sunday',
    },
    {
        label: 'Monday',
        value: 'Monday',
    },
    {
        label: 'Tuesday',
        value: 'Tuesday',
    },
    {
        label: 'Wednesday',
        value: 'Wednesday',
    },
    {
        label: 'Thursday',
        value: 'Thursday',
    },
    {
        label: 'Friday',
        value: 'Friday',
    },
    {
        label: 'Saturday',
        value: 'Saturday',
    },
];

type Props = {
    close: () => void;
    userId?: string;
    userName?: string;
};
const CallSheetPanel: React.FC<Props> = ({close, userId, userName}) => {
    const [data, setData] = React.useState<Callsheet[]>([]);
    const { state } = React.useContext(DashboardContext);

    React.useEffect(() => {
        getData();
    }, []);


    React.useEffect(()=>{
        getData();
    },[state.selectedStation?.id, state.selectedContract?.id])

    const getData = () =>{
        UserService.getCallSheet(userId, state.selectedStation?.id, state.selectedContract?.id ).then((response) => {
            setData(response);
        });
    }

    const handleDownload =()=> {
        UserService.exportCallSheet(userId, state.selectedStation?.id, state.selectedContract?.id ).then((response)=> {
            downloadFile(response.content, response.fileName);
        })
    }

    return (
        <div className="callsheet-panel">
            <Typography variant="heading" color="Primary" weight={600}>
                CALL SHEET
            </Typography>
            <div className='panel-actions'><IconButton Icon={DownloadIcon} onClick={handleDownload} color="info" tooltip='Download as excel'/><IconButton Icon={CloseIcon} onClick={close}/></div>
            <div className="callsheet-days">
                {userName && <Typography variant='subheading' color='Primary' weight={400}>{userName}</Typography>}
                {weekdays.map((day, index) => {
                    return (
                        <div key={index}>
                            <Typography variant="subtitle">{day.label}</Typography>
                            <div className="day-items">
                                {data
                                    .filter((el) => el.dayOfWeek === day.label)
                                    .sort((a, b) => {
                                        const startTimeA = getTimeIn12HrFormat(
                                          convertToCurrentTimeZone(a.startTime, a.timeZone)
                                        );
                                        const startTimeB = getTimeIn12HrFormat(
                                          convertToCurrentTimeZone(b.startTime, b.timeZone)
                                        );
                                        const startTimeComparison = (startTimeA < startTimeB)? -1 : ((startTimeA == startTimeB)? 0 : 1);

                                        if (startTimeComparison == 0) {
                                        const endTimeA = getTimeIn12HrFormat(
                                            convertToCurrentTimeZone(a.endTime, a.timeZone)
                                        );
                                        const endTimeB = getTimeIn12HrFormat(
                                            convertToCurrentTimeZone(b.endTime, b.timeZone)
                                        );
                                        const endTimeComparision = (endTimeA < endTimeB)? -1: 1;
                                        return endTimeComparision;
                                        }

                                        return startTimeComparison;
                                      })
                                    .map((call, index) => {
                                        return (
                                            <div key={`day-${index}`} className="call-item">
                                                <div className="call-item-detail">
                                                    {call.name} <span className='typography--caption'> {Format[call.format]}</span>
                                                    <div className="inline-item">
                                                        <PhoneIcon />
                                                        <span>{call.phoneNumber? formatPhoneNumber(call.phoneNumber): 'unavailable'}</span>
                                                        <RadioIcon />
                                                        <span>{call.stationName}</span>
                                                    </div>
                                                    <Typography variant="caption"><strong>Notes:</strong> <i>{call.notes}</i></Typography>
                                                </div>
                                                <div className="call-item-time">
                                                {getTimeIn12HrFormat(convertToCurrentTimeZone(call.startTime, call.timeZone))} - {getTimeIn12HrFormat(convertToCurrentTimeZone(call.endTime, call.timeZone))}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CallSheetPanel;
