import React from 'react';
//import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import Typography from '../../components/Core/Typography';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import StripContract from '../../models/StripContract';
import { ContractService } from '../../api';
import StripDetailItem from './StripDetailItem';
import { Format } from '../../models/Contract';
import { getListFromEnum } from '../../utils';
import InputSelect from '../../components/Core/InputSelect';
import { DataLoadingContext } from '../../components/DataLoading';

const StripDetail: React.FC = () => {
    const { toggleLoading, dataLoading } = React.useContext(DataLoadingContext);
    const [activeContracts, setActiveContracts] = React.useState<StripContract[]>([]);
    const [selectedFormat, setSelectedFormat] = React.useState<Format>(-1 as number);
    const formats = getListFromEnum(Object.values(Format));
    const contractRef = React.useRef(activeContracts);

    React.useEffect(()=> {
        contractRef.current = activeContracts;
    }, [activeContracts])

    React.useEffect(() => {
        if ((selectedFormat as number) !== -1) {
            toggleLoading(true);
            ContractService.getPriorities(selectedFormat)
                .then((response) => {
                    setActiveContracts(response);
                })
                .catch(() => {
                    setActiveContracts([]);
                })
                .finally(() => {
                    toggleLoading(false);
                });
        }
    }, [selectedFormat]);

    const handleDrop = (source: StripContract, target: StripContract) => {
            const temp: StripContract[] = JSON.parse(JSON.stringify(contractRef.current));
            const targetIndex = temp.findIndex((el) => el.id === target.id);
            ContractService.updatePriority(source.formatPriorityId as string, selectedFormat,  targetIndex).then(() => {            
            ContractService.getPriorities(selectedFormat)
            .then((response) => {
                setActiveContracts(response);
            })
            .catch(() => {
                setActiveContracts([]);
            })});
            
    };

    const handleChange=(sourceIndex: number, targetIndex: number)=> {
        const temp: StripContract[] = JSON.parse(JSON.stringify(contractRef.current));
        if(!(sourceIndex ===0 && targetIndex <=0) && !(sourceIndex === temp.length && targetIndex>=temp.length)){
            ContractService.updatePriority(temp[sourceIndex].formatPriorityId as string, selectedFormat, targetIndex as number).then(() => {            
                ContractService.getPriorities(selectedFormat)
                .then((response) => {
                    setActiveContracts(response);
                })
                .catch(() => {
                    setActiveContracts([]);
                })});
        }
    }

    return (
        <div>
            <div className="strip-format-selector">
                <Typography variant="subtitle">
                    Format <span className="color-error">*</span>
                </Typography>
                <InputSelect
                    onChange={(_name, value) => {
                        setSelectedFormat(value as number);
                    }}
                    value={selectedFormat}
                    name="format"
                    options={formats}
                    placeholder="Please select a format to view contract priorities"
                />
            </div>
            <div className={`strip-detail${dataLoading ? ' loading' : ''}`}>
                <Typography variant="subtitle" weight={600}>
                    STRIP{' '}
                    <span className="typography--caption color-info">
                        <i>*Drag to reorder</i>
                    </span>
                </Typography>
                <DndProvider backend={HTML5Backend}>
                    {activeContracts &&
                        activeContracts.length > 0 &&
                        activeContracts.map((el, index) => (
                            <StripDetailItem key={el.id} index={index} strip={el} onDrop={handleDrop} onChange={handleChange}/>
                        ))}
                </DndProvider>
            </div>
        </div>
    );
};

export default StripDetail;
