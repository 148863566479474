import User from '../models/User';
import instance from './instance';

function loginWithToken():Promise<User> {
    return instance.get('auth/token-login')
            .then((response) => {
                const user:User = response.data; 
                return user;
            });
    }

    function login(emailAddress: string, password: string): Promise<User> {
        return instance
            .post('auth/login', {
                emailAddress: emailAddress,
                password: password,
            })
            .then((response) => {
                return response.data;
            });
    }
    function resetPassword(token: string, email: string, password: string): Promise<string> {
        return instance
            .post(`auth/reset-forgotten-password`, {
                emailAddress: decodeURIComponent(email),
                token: token,
                password: password,
            })
            .then((response) => {
                return response.data;
            });
    }
    
    function resetUserPassword(password: string): Promise<string> {
        return instance
            .post(`auth/reset-password`, {
                password: password,
            })
            .then((response) => {
                return response.data;
            });
    }
    function forgotPassword(email: string): Promise<string> {
        return instance.get(`auth/forgot-password?email=${encodeURIComponent(email)}`).then((response) => {
            return response.data;
        });
    }

export { loginWithToken, login, forgotPassword, resetPassword, resetUserPassword };
