const downloadFile = (data: string, filename:string) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = "data:text/csv;base64," + data;
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link);
};

export const downloadPdf = (pdfData: string, filename: string) => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${pdfData}`;
    link.download = `${filename}`;
    link.click();
};

export default downloadFile;