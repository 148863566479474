import React from "react";
import Button from "../../components/Core/Button";
import Modal, { ModalActionBar } from "../../components/Core/Modal";
import Typography from "../../components/Core/Typography";
import { Format } from "../../models/Contract";
import { getListFromEnum } from "../../utils";
import InputSelect from "../../components/Core/InputSelect";

type Props = {
    isOpen: boolean,
    handleClose: () => void,
    onSubmit: (format?: Format) => void,
    isExporting: boolean
}

const ExportDJModal: React.FC<Props> = ({isOpen, handleClose, onSubmit, isExporting}) => {
    const [selectedFormat, setSelectedFormat] = React.useState<Format>();
    const formats = getListFromEnum(Object.values(Format));

    const handleSubmit = () => {
        onSubmit(selectedFormat);
    }

    return(
        <Modal isOpen={isOpen} close={handleClose} size='medium' title="Export DJs">
            <div className="export-dj-modal">
                <Typography variant="caption" weight={600}>
                    <i>
                        *Submit the request to export all DJs, or select a genre to export specific DJs.
                    </i>
                </Typography>
                <InputSelect
                        options={formats}
                        value={selectedFormat}
                        name="format"
                        placeholder='Select Genre'
                        onChange={(_name, value) => setSelectedFormat(value as number)}
                    />
            </div>
            <ModalActionBar>
                <Button onClick={handleSubmit} loading={isExporting}>
                    Submit
                </Button>
            </ModalActionBar>
        </Modal>
    )
};

export default ExportDJModal;