import React from 'react';
import { useParams } from 'react-router-dom';
import { ContractService, FeedbackService, PerformanceService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
// import LoadingOverlay from '../../components/LoadingOverlay';
import Contract, { ContractStatus, ReleaseType } from '../../models/Contract';
import Performance, { NACCAdds } from '../../models/ContractPerformance';
import Feedback from '../../models/Feedback';
import ContractPerformance from '../ContractPerformance';
import ContractDetail from './ContractDetail';
import ContractFeedbacks from './ContractFeedbacks';
import ContractForm from './ContractForm';
import ContractReport from './ContractReport';
import './contracts.scss';
import ContractAdds from './ContractAdds/ContractAdd';
import ContractMailingList from './ContractMailingList';

const initContract: Contract = {
    clientId: '',
    artistName: '',
    status: ContractStatus.Active,
    releaseType: ReleaseType.Album,
    releaseName: '',
    labelName: '',
    priority: Number.MAX_SAFE_INTEGER,
    formats: [],
    releaseAliases: [],
    artistAliases: [],
    hasMailingList: false
};

const ContractPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [contract, setContract] = React.useState<Contract>({ ...initContract });
    const [feedbacks, setFeedbacks] = React.useState<Feedback[]>([]);
    const [performanceObject, setPerformanceObject] = React.useState<{items:Performance[], total: number}>({items:[] , total : 0});
    const [addsObject, setAddsObject] = React.useState<{items:NACCAdds[], total: number}>({items:[] , total : 0});
    const [isFormView, setIsFormView] = React.useState(false);
    // const [isGeneratingReport, setIsGeneratingReport] = React.useState(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const[performanceFilters, setPerformanceFilters] = React.useState();
    const[addsFilters, setAddsFilters] = React.useState();

    React.useEffect(() => {
        if (id != 'add-new') {
            getData();
        } else {
            setIsFormView(true);
        }
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        getContract();
        getFeedback();
        getPerformances();
        getAdds();
        toggleLoading(false);
    };

    const getContract = () => {
        ContractService.getContract(id)
            .then((response) => setContract(response));
    }

    const getFeedback = () => {
        FeedbackService.getFeedbackForContract(id).then((response) => setFeedbacks(response));
    };

    const getPerformances = () => {
        PerformanceService.getMatchedPerformancesForGrid(id)
            .then((response) => {
                setPerformanceObject(response);
            });
    };

    const getAdds = () => {
        PerformanceService.getMatchedAddsForGrid(id)
        .then((response) => {
            setAddsObject(response);
        })
    }

    const handlePerformancePageChange = (page: number) =>{
        const request = (performanceFilters)? PerformanceService.getMatchedPerformancesForGrid(id, page, performanceFilters) : PerformanceService.getMatchedPerformancesForGrid(id,page);    
        request
        .then((response) => {
            setPerformanceObject(response);          
        });
        }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePerformanceFilterChange = (filters?: any) =>{
        if(filters){
            setPerformanceFilters(filters);
            PerformanceService.getMatchedPerformancesForGrid(id, 1, filters).then((response)=>{
                setPerformanceObject(response);
            })
        }
        else{
            setPerformanceFilters(undefined);
            PerformanceService.getMatchedPerformancesForGrid(id, 1).then((response)=>{
                setPerformanceObject(response);
            })
        }
    }

    const handleAddsPageChange = (page: number) =>{
        const request = (addsFilters)? PerformanceService.getMatchedAddsForGrid(id, page, addsFilters) : PerformanceService.getMatchedAddsForGrid(id, page);    
        request
        .then((response) => {
            setAddsObject(response);          
        });
        }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAddsFilterChange = (filters?: any) =>{
        if(filters){
            setAddsFilters(filters);
            PerformanceService.getMatchedAddsForGrid(id, 1, filters).then((response)=>{
                setAddsObject(response);
            })
        }
        else{
            setAddsFilters(undefined);
            PerformanceService.getMatchedAddsForGrid(id, 1).then((response)=>{
                setAddsObject(response);
            })
        }
    }

    return contract ? (
       
            <div className="contract">
                <br />
                {isFormView ? (
                    <ContractForm contract={{ ...contract }} toggleView={() => setIsFormView(false)} />
                ) : (
                    <ContractDetail contract={contract} toggleView={() => setIsFormView(true)} />
                )}
                {id !== 'add-new' && (
                    <>
                        <ContractMailingList contractId={id} />
                        <ContractAdds adds={addsObject} handlePageChange={handleAddsPageChange} handleFilterChange={handleAddsFilterChange} getData={getAdds}/>
                        <ContractPerformance performances={performanceObject} getData={getPerformances} handlePageChange = {handlePerformancePageChange} handleFilterChange = {handlePerformanceFilterChange}/>
                        <ContractReport contractId={id}/>
                        <ContractFeedbacks feedbacks={feedbacks} getData={getFeedback} />
                    </>
                )}
            </div>
    ) : (
        <></>
    );
};

export default ContractPage;
