import React from 'react';
import { StationService } from '../../api';
import IconButton from '../../components/Core/IconButton';
import { DJ } from '../../models/Station';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-circle.svg';
import Tooltip from '../../components/Core/Tooltip';
import Typography from '../../components/Core/Typography';
import { Format } from '../../models/Contract';
import { convertToCurrentTimeZone, formatPhoneNumber, getTimeIn12HrFormat } from '../../utils';
import UserAssignmentModal from './UserAssignmentModal';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';

type Props = {
    setSelectedDj: (dj: DJ) => void;
    djs: DJ[];
    getData:()=>void;
};
const Djs: React.FC<Props> = ({ setSelectedDj, djs, getData }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [modalDJ, setmodalDJ] = React.useState<undefined|DJ>(undefined);
    
    const handleDelete = (id: string) => {
        StationService.deleteStationDJ(id).then(() => {
            getData();
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'DJ was successfully deleted.',
                    status: 'success',
                    autoClose: false,
                },
            });
        });
    };

    const handleOpenDeleteClientDialog = (dj:DJ) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to delete ${dj.firstName +  " " + dj.lastName}?`,
                handleConfirm: () => handleDelete(dj.id as string),
            },
        });
    };


    return (
        <>
          {
            modalDJ?.id && 
                <UserAssignmentModal
                    djId = {modalDJ.id}
                    open = {modalDJ!=undefined}
                    close = {()=> setmodalDJ(undefined)}
                    />            
          }  
                    
        <br/>
        <Typography variant='subtitle' weight={800}>STATION DJ&lsquo;S</Typography>
        <div className="contact-card-grid">
            {djs.map((dj) => {
                return (
                    <div
                        key={dj.id}
                        className="contact-card"
                        tabIndex={0}
                        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && setSelectedDj(dj)}
                    >
                        <div onClick={() => setSelectedDj(dj)}>
                            {dj.isPriority && (
                                <Tooltip text="Priority">
                                    <AlertIcon className="priority-icon color-error" />
                                </Tooltip>
                            )}
                            <div>
                                <Typography variant="subtitle">
                                    {`${dj.firstName} ${dj.lastName}`.slice(0, 30)}
                                    {`${dj.firstName} ${dj.lastName}`.length > 30 ? '...' : ''}
                                </Typography>
                                <Typography variant="body">
                                    <strong>Format: </strong>
                                    {dj.formats && <Typography variant="body">
                                        {
                                            String(dj.formats.map((el:number)=>{return ` ${Format[el]}`}))
                                        }
                                    </Typography>}
                                </Typography>
                                {dj.phoneNumber && (
                                    <Typography variant="body">
                                        <strong>Phone Number: </strong>
                                        {formatPhoneNumber(dj.phoneNumber)}
                                    </Typography>
                                )}
                                <div className="dj-availables">
                                    {dj.djAvailabilities &&
                                        dj.djAvailabilities.map((availables, index) => {
                                            return (
                                                <Typography variant="caption" key={index}>
                                                    <ClockIcon/>
                                                    <strong>
                                                        {availables.dayOfWeek}&nbsp;&nbsp;
                                                        <span className="color-info">|</span>&nbsp;&nbsp;
                                                    </strong>
                                                    {getTimeIn12HrFormat(convertToCurrentTimeZone(availables.startTime, availables.timeZone))} - {getTimeIn12HrFormat(convertToCurrentTimeZone(availables.endTime, availables.timeZone))}
                                                </Typography>
                                            );
                                        })}
                                </div>
                                <Typography variant="caption">
                                    <strong>Notes: </strong>
                                    <i>{dj.notes}</i>
                                </Typography>
                            </div>
                        </div>
                        <div className="card-actions">
                            <IconButton Icon={PlusIcon} tooltip="Assign DJ to User" onClick={()=>setmodalDJ(dj)} color="info"/>
                            <IconButton
                                Icon={EditIcon}
                                tooltip="Edit DJ"
                                onClick={() => setSelectedDj(dj)}
                                color="info"
                            />
                            <IconButton
                                Icon={DeleteIcon}
                                tooltip="Delete DJ"
                                onClick={() => handleOpenDeleteClientDialog(dj as DJ)}
                                color="error"
                            />
                        </div>
                    </div>
                );
            })}
        </div>
        </>
    );
};

export default Djs;
