import Client, { ClientSearchResponse } from '../models/Client';
import instance from './instance';

function getClients(): Promise<Client[]> {
    return instance.get(`clients`).then((response) => {
        return response.data;
    });
}

function createClient(Client: Client): Promise<Client> {
    return instance.post(`client`, Client).then((response) => {
        return response.data;
    });
}

function getClient(id: string): Promise<Client> {
    return instance.get(`client/${id}`).then((response) => {
        return response.data;
    });
}

function getSimilarClients(value?: string): Promise<ClientSearchResponse[]> {
    let queryString = '';
    if(value){
        queryString = `?value=${value}`;
    }

    return instance.get(`clients/search${queryString}`).then((response) => {
        return response.data as Array<ClientSearchResponse>;
    })
}

function deleteClient(id: string): Promise<boolean> {
    return instance.delete(`client/${id}`).then((response) => {
        return response.data;
    });
}

function updateClient(Client: Client): Promise<Client> {
    return instance.put(`client`, Client).then((response) => {
        return response.data;
    });
}

export { getClients, createClient, updateClient, getClient, getSimilarClients, deleteClient };
