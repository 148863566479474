import React from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import Client from '../../models/Client';
import ClientContract from './ClientContract';
import ClientDetail from './ClientDetail';
import ClientForm from './ClientForm';
import './clients.scss';

const initClient: Client = {
    name: '',
    contactName: ''
};

const ClientPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [client, setClient] = React.useState<Client | null>(null);
    const [isFormView, setIsFormView] = React.useState(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);

    React.useEffect(() => {
        if (id !== 'add-new') {
            getData();
        } else {
            setIsFormView(true);
            setClient(initClient);
        }
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        ClientService.getClient(id)
            .then((response) => {
                setClient(response);
            })
            .finally(() => toggleLoading(false));
    };

    return client ? (
        <div className="client">
            {isFormView ? (
                <ClientForm client={client} toggleView={() => setIsFormView(false)} />
            ) : (
                <ClientDetail client={client} toggleView={() => setIsFormView(true)} />
            )}
            {id !== "add-new" && <ClientContract clientId={id}/>}
        </div>
    ) : (
        <></>
    );
};

export default ClientPage;
