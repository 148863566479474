import { NACCUploadType } from '../models/Contract';
import Performance, { SpinAddReq, UploadPerformance, NACCAdds, DataForReport} from '../models/ContractPerformance';
import { PerformanceType } from '../models/ContractPerformance';
import FileExportResponse from '../models/FileExportResponse';
import { SpinUploadsRes } from '../models/StripContract';
import { checkAdds } from '../utils';
import instance from './instance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function uploadPerformance(data: UploadPerformance): Promise<any> {    
    const formData = new FormData();
    formData.append('contractId', data.contractId ? data.contractId: '');
    formData.append('week', data.week);
    formData.append('file', data.file);
    const request = (data.genre == undefined)? `spin?type=${data.type}` : (!checkAdds(data.genre))? `spin?type=${data.type}&genre=${data.genre}` : `adds?genre=${data.genre}`;
    return instance.post(request, formData).then((response) => {
        return response;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMatchedPerformancesForGrid(contractId: string, page?:number, filters?: any): Promise<{items:Performance[], total: number}> {
    const body = {currentPage: page, itemsInPage: 20, filters: filters}
    return instance.put(`spin/${contractId}/matched-for-grid`, body).then((response) => {
        return response.data;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getMatchedAddsForGrid(contractId: string, page?:number, filters?: any): Promise<{items:NACCAdds[], total: number}> {
    const body = {currentPage: page, itemsInPage: 20, filters: filters}
    return instance.post(`${contractId}/adds/matched-for-grid`, body).then((response) => {
        return response.data;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getUnMatchedForGrid(page?: number, filters?: any): Promise<{items: Performance[], total: number}>{
    const body = {currentPage: page, itemsInPage: 20, filters: filters}
    return instance.put(`spin/unmatched-for-grid`, body).then((response)=>{        
        return response.data;
    })
}

function getDataForReport(contractId: string, templateId: string,  startDate?: Date, endDate?: Date): Promise<DataForReport> {
    const reqBody = (startDate)? { startDate: startDate, endDate: endDate} : {startDate: null, endDate: null}
    const request= (templateId.length>0)? `spin/${contractId}/report?templateId=${templateId}` : `spin/${contractId}/report`;
    return instance.put(request, reqBody).then((response) => {
        return response.data;
       
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getUnMatchedAddsForGrid(page?: number, filters?: any): Promise<{items: NACCAdds[], total: number}>{
    const body = {currentPage: page, itemsInPage: 20, filters: filters}
    return instance.put('/adds/unmatched-for-grid', body).then((response)=>{        
        return response.data;
    })
}


function downloadTemplate(type?: PerformanceType) : Promise<FileExportResponse> {
    return instance.get(`spin/template?type=${type}`).then((response) => {
        return response.data as FileExportResponse;
    });
}

function getPriorityUploads(): Promise<Array<SpinUploadsRes>>{
    return instance.get(`spin/all-uploads`).then((response) => {
        return response.data as SpinUploadsRes[];
    })
}

function removeAssociatedData(requestId: string, uploadType: NACCUploadType): Promise<boolean>{
    return instance.get(`spin/remove/${requestId}?type=${uploadType}`)
    .then((response) => {
        return response.data;
    })
}

function associateSpin(req: {spinId: string, contractId: string}): Promise<boolean>{
    return instance.put(`spin/associate`, req)
    .then((response) => {
        return response.data;
    })
}

function unassociateSpin(id: string): Promise<boolean>{
    return instance.get(`spin/unassociate/${id}`)
    .then((response) => {
        return response.data;
    })
}

function associateSpinList(req: {Ids: string[], contractId: string}): Promise<boolean>{
    return instance.put(`spin/associate-list`, req)
    .then((response) => {
        return response.data;
    })
}

function associateAddsList(req: {Ids: string[], contractId: string}): Promise<boolean>{
    return instance.put(`adds/associate-list`, req)
    .then((response) => {
        return response.data;
    })
}

function associateAdds(req: {addsId: string, contractId: string}): Promise<boolean>{
    return instance.put(`adds/associate`, req)
    .then((response) => {
        return response.data;
    })
}

function unassociateAdds(id: string): Promise<boolean>{
    return instance.get(`adds/unassociate/${id}`)
    .then((response) => {
        return response.data;
    })
}

function deletePerformanceData(id: string): Promise<boolean> {
    return instance.delete(`spin/${id}`).then((res) => {
        return res.data;
    })
}

function deleteAddsData(id: string): Promise<boolean> {
    return instance.delete(`adds/${id}`).then((res) => {
        return res.data;
    })
}

function addSpinData(req: SpinAddReq): Promise<boolean> {
    return instance.post('spin/add', req).then((response) => {
        return response.data;
    })
}

function deleteListSpins(spinsList: string[]): Promise<boolean> {
    return instance.put('spin/delete-list', spinsList).then((response) => {
        return response.data;
    })
}
function deleteListAdds(addsList: string[]): Promise<boolean> {
    return instance.put('adds/delete-list', addsList).then((response) => {
        return response.data;
    })
}


export {deleteListSpins,  
    associateAddsList, 
    deleteListAdds, 
    associateSpinList, 
    getUnMatchedAddsForGrid,
    getMatchedAddsForGrid,
    getUnMatchedForGrid,
    getMatchedPerformancesForGrid, 
    associateAdds,
    unassociateAdds,
    uploadPerformance,
    deleteAddsData, 
    getDataForReport, 
    downloadTemplate, 
    getPriorityUploads, 
    removeAssociatedData, 
    associateSpin, 
    unassociateSpin,
    deletePerformanceData, 
    addSpinData
};
