import React from 'react';
import { StationService, UserService } from '../../api';
import Button from '../../components/Core/Button';
import Checkbox from '../../components/Core/Checkbox';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Typography from '../../components/Core/Typography';
import { SystemUser, UserRole } from '../../models/User';

type Props ={
    djId: string;
    open: boolean;
    close:()=>void;
}

const UserAssignmentModal:React.FC<Props>=({open, close, djId})=> {
    const [users, setUsers]= React.useState<SystemUser[]>([]);
    const [assignedUsers, setAssignedUsers]=React.useState<string[]>([]);

    React.useEffect(()=> {
        if(djId) {            
            UserService.getUsers().then((response)=>setUsers(response));
            StationService.getDJCallers(djId).then((response)=>{
                const temp:string[] = [];
                response.forEach((el)=> {
                    temp.push(el.userId)
                });
                setAssignedUsers(temp);
            })
        }
    }, [djId])

    const handleChange=(id:string)=> {
        const existingIndex = assignedUsers.findIndex((el)=>el===id);
        const temp = [...assignedUsers];
        if(existingIndex!=-1) {
            temp.splice(existingIndex, 1);
        } else {
            temp.push(id);
        }
        setAssignedUsers(temp);
    }

    const handleSave=()=> {
        StationService.updateDJCallers(djId, assignedUsers).then(()=> {
            close();
        })
    }

    return <Modal isOpen={open} close={close} title="Manage Callers" size='medium'>
            <div className='user-assignment'>
                {
                    users.map((user)=> {
                        return <div key={user.id} className="user-assignment-item">
                            <Checkbox onChange={()=>handleChange(user.id as string)} checked={assignedUsers.includes(user.id as string)}/>
                            <div>
                                <Typography variant='subtitle' weight={500}>{user.firstName} {user.lastName} {user.role === UserRole.Admin && <span className='typography--caption color-info'>ADMIN</span>}</Typography>
                                <Typography variant='body'>{user.emailAddress}</Typography>
                            </div>
                        </div>
                    })
                }
            </div>
            <ModalActionBar><Button onClick={close} color="secondary">Close</Button><Button onClick={handleSave} color="secondary">Save</Button></ModalActionBar>
    </Modal>
}

export default UserAssignmentModal;