import { Format } from "../../../../models/Contract";
import { StationDashboardViewModel, StationGridViewModel } from "../../../../models/Station";

export enum SearchStationActionType {
    SET_COUNTRY,
    SET_DISTANCE,
    SET_CITY,
    SET_STATE,
    SET_SELECTED_STATION,
    SET_ZIP_CODE,
    SET_PAGE,
    SET_TOTAL,
    SET_MAIN_STATIONS,
    SET_DROP_DOWN_STATIONS,
    CLEAR_FILTER,
    TOGGLE_SEARCH,
    SET_FORMAT,
    SET_SHOW_CLUSTER
}

export type SearchStationAction = 
{type: SearchStationActionType.SET_COUNTRY, payload?: string} |
{type: SearchStationActionType.SET_DISTANCE, payload?: number} |
{type: SearchStationActionType.SET_CITY, payload?: string} | 
{type: SearchStationActionType.SET_STATE, payload?: string} | 
{type: SearchStationActionType.SET_SELECTED_STATION, payload?: StationDashboardViewModel} | 
{type: SearchStationActionType.SET_ZIP_CODE, payload?: string} | 
{type: SearchStationActionType.SET_PAGE, payload: {page: number, pageSize: number}} | 
{type: SearchStationActionType.SET_TOTAL, payload: number} | 
{type: SearchStationActionType.SET_MAIN_STATIONS, payload: Array<StationGridViewModel>} |
{type: SearchStationActionType.SET_DROP_DOWN_STATIONS, payload: Array<StationDashboardViewModel>} |
{type: SearchStationActionType.CLEAR_FILTER} | 
{type: SearchStationActionType.TOGGLE_SEARCH} | 
{type: SearchStationActionType.SET_SHOW_CLUSTER, payload: boolean} | 
{type: SearchStationActionType.SET_FORMAT, payload?: Format[]}