import React from 'react';
import { useParams } from 'react-router-dom';
import IconButton from '../../components/Core/IconButton';
import Typography from '../../components/Core/Typography';
import Performance, { SpinAddReq, UploadPerformance } from '../../models/ContractPerformance';
import './contractperformance.scss';
import PerformanceTable from './PerformanceTable';
import { ReactComponent as CreateIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload-cloud.svg';
import Modal from '../../components/Core/Modal';
import PerformanceUpload from './PerformanceUpload';
import { PerformanceService } from '../../api';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icons/chevron-up.svg';
import PerformanceAdd from './PerformanceAdd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContractPerformance: React.FC<({ performances: {items: Performance[], total: number}; getData: () => void; handlePageChange: (page:number)=>void; handleFilterChange: (filters:any) =>void })> = ({performances, getData, handlePageChange, handleFilterChange}) => {
    const { id } = useParams<{ id: string }>();
    const [openUploadModal, setUploadModal] = React.useState(false);
    const [openSpinAddModal, setSpinAddModal] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);

    const handleUpload = (data: UploadPerformance): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            PerformanceService.uploadPerformance(data)
                .then(() => {
                    getData();
                    setUploadModal(false);
                    resolve(true);
                })
                .catch((e) => {
                    reject(e.message);
                });
        });
    };


    const handleAddSpinData = (data: SpinAddReq): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            PerformanceService.addSpinData(data)
                .then(() => {
                    getData();
                    setSpinAddModal(false);
                    resolve(true);
                })
                .catch((e) => {
                    reject(e.message);
                });
        });
    };

    return (
        <div className="contract-performance paper">
            <div className="performance-title">
                <div>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        SPIN DATA
                    </Typography>
                    {performances.items.length > 0 && (
                        <Typography variant="caption" color="info">
                            {performances.total} spin{performances.total > 1 && 's'} recorded.
                        </Typography>
                    )}
                </div>
                <div className='icons-container'>
                    <IconButton
                        Icon={CreateIcon}
                        color="success"
                        tooltip="Add spin data"
                        onClick={() => setSpinAddModal(true)}
                    />
                    <IconButton
                        Icon={UploadIcon}
                        color="tertiary"
                        tooltip="Upload spin data"
                        onClick={() => setUploadModal(true)}
                    />
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        Icon={isExpanded ? UpArrowIcon : DownArrowIcon}
                    />
                </div>
            </div>
            {isExpanded && 
                <PerformanceTable data={performances.items} total = {performances.total} isInGlobal={false} handlePageChange = {handlePageChange} handleFilterChange = {handleFilterChange} refreshData={getData}/>
            }
            
            <Modal isOpen={openUploadModal} close={() => setUploadModal(false)} size='large' title="Upload performance data">
                <PerformanceUpload handleUpload={handleUpload} contractId={id} />
            </Modal>

            <PerformanceAdd isOpen={openSpinAddModal} close={() => setSpinAddModal(false)} handleSubmit={handleAddSpinData} contractId={id} />
        </div>
    );
};

export default ContractPerformance;
