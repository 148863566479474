import { getSelectedState, Grid, GridCellProps, GridColumn, GridFilterCellProps, GridFilterChangeEvent, GridHeaderSelectionChangeEvent, GridPageChangeEvent, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import React from 'react';
import Performance, { PerformanceType, SpinStatus } from '../../models/ContractPerformance';
import Checkbox from '../../components/Core/Checkbox';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as UnAssociationIcon } from '../../assets/icons/user-minus.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as ContractIcon } from '../../assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import Contract, { Format } from '../../models/Contract';
import { ContractService, PerformanceService } from '../../api';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Typography from '../../components/Core/Typography';
import ContractSelector from '../Contracts/ContractSelector';
import Button from '../../components/Core/Button';
import StripContract from '../../models/StripContract';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { DataLoadingContext } from '../../components/DataLoading';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { getFormatName, getListFromEnum, getSelectedGridData, useDebounce } from '../../utils';
import GridDropDownFilter from '../../components/GridDropDownFilter';

type Props = {
    data: Performance[];
    isInGlobal: boolean;
    toggleCheck?: () => void;
    toggleAdds?: () => void;
    toggleUpload?: () => void;
    refreshData?: () => void;
    total?: number;
    handlePageChange?: (page: number) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFilterChange?: (filters?: any) => void;
};

export interface PageState {
    skip: number;
    take: number;
}
const DATA_ITEM_KEY = 'id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

export const initialDataState: PageState = { skip: 0, take: 20 };

const PerformanceTable: React.FC<Props> = ({ data, isInGlobal, toggleCheck, toggleUpload, toggleAdds, refreshData, total, handlePageChange, handleFilterChange }) => {
    const [contract, setContract] = React.useState<Contract>();
    const [selectedContract, setSelectedContract] = React.useState<StripContract>();
    const [selectedPerformance, setSelectedPerformance] = React.useState<Performance>();
    const [isAssociationModalOpen, setIsAssociationModalOpen] = React.useState(false);
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const [listAssociation, setListAssociation] = React.useState(false);
    const dataState = data.map((dataItem: Performance) => Object.assign({ selected: false }, dataItem));
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const formats = getListFromEnum(Object.values(Format));

    const debounced = useDebounce(filter?.filters, 500);

    React.useEffect(()=>{
        handleFilter();
    }, [debounced])


    const handleOpenDeletePerformanceDialog = (item: Performance) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Performance Data',
                description: `Are you sure you want to delete selected perfromance data? This process is irreversible.`,
                handleConfirm: () => handleDelete(item.id as string),
            },
        });
    };
    const pageChange = (event: GridPageChangeEvent) => {
        toggleLoading(true);
        const newPage = event.page;
        if (handlePageChange) {
            handlePageChange((newPage.skip / newPage.take) + 1);
        }
        setPage(newPage);
        toggleLoading(false);

    };

    const handleDelete = (id: string) => {
        toggleLoading(true);
        PerformanceService.deletePerformanceData(id)
            .then((res) => {
                if (res) {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Successfully deleted performance data`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                    if (refreshData) refreshData();
                }
                else {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: "Something went wrong. Please try again later.",
                            status: 'error',
                            autoClose: true,
                        },
                    });
                }
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
            .finally(() => {
                toggleLoading(false);
            })
    }

    const handleUnassociation = (id: string) => {
        toggleLoading(true);
        PerformanceService.unassociateSpin(id)
            .then((res) => {
                if (res) {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Successfully unassociated performance data`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                    if (refreshData) refreshData();
                }
                else {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: "Something went wrong. Please try again later.",
                            status: 'error',
                            autoClose: true,
                        },
                    });
                }
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
            .finally(() => {
                toggleLoading(false);
            })
    }

    const handleAssociationClick = (item: Performance) => {
        setSelectedPerformance(item);
        if (item.contractId) {
            ContractService.getContract(item.contractId)
                .then((res) => {
                    setContract(res);
                    setIsAssociationModalOpen(true);
                })
        }
        else {
            setContract(undefined);
            setIsAssociationModalOpen(true);
        }
    }

    const handleUnAssociationClick = (item: Performance) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Un Associate Performance Data',
                description: `Are you sure you want to unassociate selected perfromance data? This process is irreversible.`,
                handleConfirm: () => handleUnassociation(item.id as string),
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CellValue = (props: any) => {
        const dataItem = props.dataItem;
        const fieldName = props.field;
        const fieldValue = dataItem[fieldName];
        return (
            <td className='grid-word-break'>
                <p>{(fieldValue.length == 0) ? "-" : fieldValue}</p>
            </td>
        )
    }

    const FormatFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter
            {...props}
            data={formats}
            defaultValue={{ label: 'select format', value: '' }}
            filterOperator="contains"
        />
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const GenreValue = (props: any) => {
        const { dataItem } = props;

        const content = getFormatName(dataItem.genre)

        return (
            <td>
                <p>{content}</p>
            </td>
        );
    }

    const GridActions = (props: GridCellProps) => {
        const spin = props.dataItem as Performance;
        return (
            <td className="grid-actions">
                {isInGlobal && <IconButton Icon={spin.status == SpinStatus.NoMatch ? UserIcon : ContractIcon} color={'primary'} onClick={() => handleAssociationClick(props.dataItem as Performance)} tooltip='Associate spin to a contract'/>}
                {!isInGlobal && props.dataItem.type === PerformanceType.NACC && <IconButton Icon={UnAssociationIcon} color={'primary'} onClick={() => handleUnAssociationClick(props.dataItem as Performance)} tooltip='Unassociate'/>}
                <IconButton Icon={DeleteIcon} color={'error'} onClick={() => handleOpenDeletePerformanceDialog(props.dataItem as Performance)} tooltip='Remove'/>
            </td>
        );
    };

    const handleMultiAssign = () => {
        const payload: { Ids: string[], contractId: string } = { Ids: getSelectedGridData(selectedState), contractId: (selectedContract) ? selectedContract.id as string : '' };
        PerformanceService.associateSpinList(payload)
            .then(() => {
                setListAssociation(false);
                setIsAssociationModalOpen(false);
                setFilter(undefined);
                if (refreshData) refreshData();
            })
    }

    const handleAssociation = () => {
        let payload: { spinId: string, contractId: string } = { spinId: '', contractId: '' };

        if (selectedPerformance?.contractId) {
            payload = { spinId: selectedPerformance.id as string, contractId: selectedPerformance.contractId as string }
        }
        else {
            if (selectedContract) {
                payload = { spinId: selectedPerformance?.id as string, contractId: selectedContract.id as string }
            }
        }

        if (payload.spinId.length > 0 && payload.contractId.length > 0) {
            PerformanceService.associateSpin(payload)
                .then(() => {
                    setIsAssociationModalOpen(false);
                    setFilter(undefined);
                    if (refreshData) refreshData();
                })
        }
    }

    const handleFilter = () => {
        setSelectedState({});
        if(filter){
            if (handleFilterChange) handleFilterChange(debounced);
        }
        else {
            if (handleFilterChange) handleFilterChange();
            setFilter(undefined);
        }
    };

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            const newSelectedState: { [key: string]: boolean } = {};

            event.dataItems.forEach(item => {
                const value = idGetter(item);
                newSelectedState[value] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const handleListDelete = () => {
        PerformanceService.deleteListSpins(getSelectedGridData(selectedState)).then((res) => {
            if (res) {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: `Successfully deleted performance data`,
                        status: 'success',
                        autoClose: true,
                    },
                });
                if (refreshData) refreshData();
            }
            else {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: "Something went wrong. Please try again later.",
                        status: 'error',
                        autoClose: true,
                    },
                });
            }
        }).catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(() => setSelectedState({}))
    }

    return (
        <>
            <Grid
                style={{
                    height: '100%',
                }}
                sortable={true}
                filterable={true}
                filter={filter}
                data={dataState.map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
                total={total}
                take={page.take}
                skip={page.skip}
                pageable={true}
                onPageChange={pageChange}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{ enabled: isInGlobal, drag: false, cell: false, mode: 'multiple' }}
            >
                {isInGlobal && (
                    <GridToolbar>
                        <Checkbox checked={false} onChange={() => (toggleAdds ? toggleAdds() : '')} label="Show Adds" />
                        <Checkbox
                            checked={false}
                            onChange={() => (toggleCheck ? toggleCheck() : '')}
                            label="Show Uploads"
                        />

                        <Button color="primary" onClick={() => (toggleUpload ? toggleUpload() : '')}>
                            Upload NACC
                        </Button>

                        {(() => {
                            const trueValues = Object.entries(selectedState).filter(([_key, value]) => value === true);
                            const countTrueValues = trueValues.length;
                            if (countTrueValues > 1) {
                                return (
                                    <>
                                        <IconButton
                                            Icon={DeleteIcon}
                                            color={'error'}
                                            onClick={handleListDelete}
                                            tooltip="Delete"
                                        />
                                        <IconButton
                                            Icon={UsersIcon}
                                            color={'error'}
                                            onClick={() => {
                                                setListAssociation(true);
                                                setIsAssociationModalOpen(true);
                                            }}
                                            tooltip="Assign"
                                        />
                                    </>
                                );
                            }
                            return null;
                        })()}
                    </GridToolbar>
                )}
                {isInGlobal && (
                    <GridColumn
                        filterable={false}
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1}
                    />
                )}
                <GridColumn field="week" title="Date" width={120} />
                <GridColumn field="station" title="Station" width={120} />
                <GridColumn field="market" title="Market" cell={CellValue} width={120} />
                <GridColumn field="genre" title="Genre" cell={GenreValue} filterCell={FormatFilterCell} width={120} />
                {!isInGlobal && (
                    <GridColumn title="Media Base" resizable={true}>
                        <GridColumn field="mediaBaseData" title="Spin" filterable={false} cell={CellValue} />
                        <GridColumn field="mediaBaseRank" title="Rank" filterable={false} cell={CellValue} />
                    </GridColumn>
                )}
                {!isInGlobal && (
                    <GridColumn
                        field="subModernData"
                        title="Sub Modern Spin"
                        filterable={false}
                        cell={CellValue}
                        resizable={true}
                    />
                )}
                {!isInGlobal && (
                    <GridColumn
                        field="jackBartonData"
                        title="Jack Barton Spin"
                        filterable={false}
                        cell={CellValue}
                        resizable={true}
                    />
                )}
                {!isInGlobal && (
                    <GridColumn
                        field="naccRank"
                        title="NACC(rank)"
                        filterable={false}
                        cell={CellValue}
                        resizable={true}
                    />
                )}
                {!isInGlobal && (
                    <GridColumn
                        field="cdxData"
                        title="CDX Spin"
                        filterable={false}
                        cell={CellValue}
                        resizable={true}
                    />
                )}
                {!isInGlobal && (
                    <GridColumn title="Others" resizable={true}>
                        <GridColumn field="otherSource" title="Source" filterable={false}  cell={CellValue} />
                        <GridColumn field="otherSourceSpins" title="Spin" filterable={false} cell={CellValue} />
                        <GridColumn field="otherSourceRank" title="Rank" filterable={false} cell={CellValue} />
                    </GridColumn>
                )}
                {isInGlobal && (
                    <GridColumn title="NACC Artist Name" field="naccArtistName" cell={CellValue} resizable={true} />
                )}
                {isInGlobal && <GridColumn title="NACC Record" field="naccRecord" cell={CellValue} resizable={true} />}
                {isInGlobal && (
                    <GridColumn title="NACC Rank" field="naccRank" filterable={false} cell={CellValue} width={120} />
                )}

                <GridColumn field="fileName" title="Uploaded File Name" cell={CellValue} resizable={true} width={120} />
                <GridColumn cell={GridActions} filterable={false} width={100} sortable={false} />
            </Grid>

            <Modal
                isOpen={isAssociationModalOpen}
                close={() => {
                    setListAssociation(false);
                    setIsAssociationModalOpen(false);
                    setSelectedContract(undefined);
                    setContract(undefined);
                    setSelectedPerformance(undefined);
                }}
                size="large"
                title={
                    contract
                        ? `Approve this performance record for contract - ${contract.releaseName}`
                        : listAssociation
                        ? 'Associate selected performance records to a contract'
                        : 'Associate this performance record to a contract'
                }
            >
                <div className="contract-association-modal">
                    {contract && (
                        <>
                            <Typography variant="body" weight={600}>
                                {contract.releaseName.toUpperCase()}
                            </Typography>
                            <div className="form-grid-third">
                                <Typography variant="caption">
                                    <strong>Client:</strong> {contract.clientName}
                                </Typography>
                                <Typography variant="caption">
                                    <strong>Artist:</strong> {contract.artistName}
                                </Typography>
                                <Typography variant="caption">
                                    <strong>Label:</strong> {contract.labelName}
                                </Typography>
                            </div>
                        </>
                    )}
                    {!contract && listAssociation && (
                        <ContractSelector
                            spinIdProp={getSelectedGridData(selectedState)[0]}
                            onSelect={(selectedContract: StripContract | undefined) => setSelectedContract(selectedContract)}
                        />
                    )}
                    {!contract && !listAssociation && (
                        <ContractSelector
                            spin={selectedPerformance as Performance}
                            onSelect={(selectedContract: StripContract | undefined) => setSelectedContract(selectedContract)}
                        />
                    )}
                </div>
                <ModalActionBar>
                    <Button
                        disabled={
                            selectedPerformance?.contractId !== null && selectedPerformance?.contractId !== undefined
                                ? false
                                : selectedContract !== undefined && selectedContract !== null
                                ? false
                                : true
                        }
                        onClick={listAssociation ? handleMultiAssign : handleAssociation}
                    >
                        Associate
                    </Button>
                </ModalActionBar>
            </Modal>
        </>
    );
};
export default PerformanceTable;
