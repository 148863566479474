import React from "react";
import Modal, { ModalActionBar } from "../../../components/Core/Modal";
import StationSelector from "../../../components/StationsSelector";
import Button from "../../../components/Core/Button";
import { StationDashboardViewModel } from "../../../models/Station";
import IconButton from "../../../components/Core/IconButton";
import { ReactComponent as RemoveIcon } from '../../../assets/icons/minus-circle.svg';
import './mailinglist.scss';
import { DataLoadingContext } from "../../../components/DataLoading";
import { ContractService } from '../../../api';
import Typography from "../../../components/Core/Typography";
import Toggle from "../../../components/Core/Toggle/inedx";

type Props = {
    contractId: string;
    isOpen: boolean;
    close: () => void;
    onSubmit: (req: StationDashboardViewModel[]) => void;
}

const AddMailingListModal: React.FC<Props> = ({ contractId, isOpen, close, onSubmit }) => {
    const [allAvailableStations, setAllAvailableStations] = React.useState<StationDashboardViewModel[]>([]);
    const [selectedStations, setSelectedStations] = React.useState<StationDashboardViewModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [onlyShowNeedsMailing, setOnlyShowNeedsMailing] = React.useState(false);
    const [stationQueryParam, setStationQueryParam] = React.useState<string | undefined>();
    const { toggleLoading } = React.useContext(DataLoadingContext);

    React.useEffect(() => {
        searchRelatedStations();
    }, [stationQueryParam, onlyShowNeedsMailing]);

    const handleSubmit = () => {
        onSubmit(selectedStations);
    }

    const handleRadioStationSelected = (item: StationDashboardViewModel) => {
        if (selectedStations.findIndex(a => a.id === item.id) === -1) {
            const newList = [...selectedStations, item];
            setSelectedStations(newList);
        }
    };

    const handleRemoveClick = (item: StationDashboardViewModel) => {
        const updatedList = selectedStations.filter((station) => station.id !== item.id);
        setSelectedStations(updatedList);
    };

    const handleClose = () => {
        setSelectedStations([]);
        setOnlyShowNeedsMailing(false);
        close();
    }

    const handleOnQueryParamChange = (value: string | undefined) => {
        setStationQueryParam(value);
    };

    const searchRelatedStations = () => {
        toggleLoading(true);
        setIsLoading(true);
        ContractService.getStationsWithSameFormatAndValue(contractId, onlyShowNeedsMailing, stationQueryParam)
            .then((res) => {
                setAllAvailableStations(res);
            }).finally(() => {
                toggleLoading(false);
                setIsLoading(false);
            })
    }

    return (
        <Modal isOpen={isOpen} close={handleClose} size='large' title="Add stations to mailing list">
            <div className="mailing-modal">
                <div className="mailing-toggler">
                    <Typography variant="caption" weight={600}>
                        <i>
                            *Filter by CD mailing requirement
                        </i>
                    </Typography>
                    <Toggle onChange={() => setOnlyShowNeedsMailing(!onlyShowNeedsMailing)} value={onlyShowNeedsMailing} name="needsMailing" />
                </div>
                <StationSelector onSelection={handleRadioStationSelected} onQueryParamChange={handleOnQueryParamChange} isLoading={isLoading} stations={allAvailableStations} />
                {selectedStations && selectedStations.length > 0 &&
                    <div className="selected-stations">
                        {selectedStations.map((station, idx) => {
                            return (
                                <StationList key={idx} handleRemove={handleRemoveClick} value={station} index={idx} />
                            )
                        })}
                    </div>
                }
            </div>
            <ModalActionBar>
                <Button onClick={handleSubmit} disabled={selectedStations.length == 0}>
                    Submit
                </Button>
            </ModalActionBar>
        </Modal>
    )
};

type ItemProps = {
    handleRemove: (value: StationDashboardViewModel) => void;
    value: StationDashboardViewModel;
    index: number;
}

const StationList: React.FC<ItemProps> = ({ handleRemove, value, index }) => {
    return (
        <div className={`station-item`} tabIndex={0}>
            <div className="detail-index-data">
                <div className="detail-index">{index + 1}</div>
                <div>
                    <Typography variant="body">{value.name}</Typography>
                </div>
            </div>
            <div className="action">
                <span
                    className="station-list-remove-icon"
                    data-value={value}
                    role="button"
                    onClick={() => handleRemove(value)}
                >
                    <IconButton Icon={RemoveIcon} tooltip='Remove station' />
                </span>
            </div>
        </div>
    )
};

export default AddMailingListModal;