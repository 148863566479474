/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

interface DropdownFilterCellProps extends GridFilterCellProps {
  data: any,
  defaultValue: any,
  filterOperator?: string
}

const GridDropDownFilter:React.FC<DropdownFilterCellProps> = (props: DropdownFilterCellProps) => {

    const onChange = (event: DropDownListChangeEvent) => {
        props.onChange({
            value: event.target.value.value,
            operator: props.filterOperator ? props.filterOperator : 'eq',
            syntheticEvent: event.syntheticEvent
        });
    }

    const onClearButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {  
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    }
    return (
      <div className="k-filtercell">
        <DropDownList
          data={props.data}
          onChange={onChange}
          value={props.value ? props.data.find((el:any)=>el.value === props.value): props.defaultValue}
          dataItemKey="value"
          textField="label"   
          defaultValue={props.defaultValue}      
          
        />
        <Button
          title="Clear"
          disabled={!props.value}
          onClick={onClearButtonClick}
          icon="filter-clear"
        />
      </div>
    );
}

export default GridDropDownFilter;