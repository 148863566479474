import React from 'react';
import { StationService } from '../../api';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import InputMultiSelect from '../../components/Core/InputMultiSelect';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import TextArea from '../../components/Core/TextArea';
import Toggle from '../../components/Core/Toggle/inedx';
import Typography from '../../components/Core/Typography';
import { Format } from '../../models/Contract';
import { DJ, DJTime } from '../../models/Station';
import { getListFromEnum, phoneNumberIsValid } from '../../utils';
import DJTimeSlots from './DJTimeSlots';

type Props = {
    open: boolean;
    close: () => void;
    dj: DJ;
    getData: () => void;
};

const StationDjModal: React.FC<Props> = ({ open, close, dj, getData }) => {
    const [_dj, setDj] = React.useState(dj);
    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const formats = getListFromEnum(Object.values(Format));

    React.useEffect(() => {
        setDj(dj);
    }, [dj]);

    const handleChange = (name: string, value: string | boolean | number | DJTime[] | Array<string | number>) => {
        setDj({ ..._dj, [name]: value });
    };

    const onSubmit = () => {
        const isValid =
            _dj.firstName.length > 0 &&
            _dj.lastName.length > 0 &&
            phoneNumberIsValid(_dj.phoneNumber) &&
            _dj.djAvailabilities &&
            _dj.djAvailabilities.length > 0 &&
            _dj.formats.length > 0;
        if (isValid) {
            setLoading(true);
            const request = dj.id ? StationService.updateStationDJ(_dj) : StationService.createStationDJ(_dj);
            request
                .then(() => {
                    getData();
                    close();
                })
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    return (
        <Modal isOpen={open} close={close} title="Station DJ" size="large">
            <div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">
                            First Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={_dj.firstName}
                            name="firstName"
                            error={showError && _dj.firstName.length === 0}
                            errorText="required"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Last Name <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={_dj.lastName}
                            name="lastName"
                            error={showError && _dj.lastName.length === 0}
                            errorText="required"
                        />
                    </div>
                </div>
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">
                            Phone Number <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            value={_dj.phoneNumber}
                            name="phoneNumber"
                            type="number"
                            error={showError && !phoneNumberIsValid(_dj.phoneNumber)}
                            errorText="Invalid phone number"
                        />
                    </div>
                    <div>
                        <Typography variant="body">
                            Format(s) <span className="color-error">*</span>
                        </Typography>
                        <InputMultiSelect 
                            options={formats}
                            value={_dj.formats}
                            name="formats"
                            placeholder='Select DJ format(s)'
                            onChange={handleChange}
                            error={showError && _dj.formats.length === 0}
                            errorText='required'
                        />
                    </div>
                </div>
                <DJTimeSlots djId={_dj.id} availability={_dj.djAvailabilities} handleChange={handleChange} />
                {showError && !(_dj.djAvailabilities && _dj.djAvailabilities.length > 0) && (
                    <Typography variant="caption" color="error">
                        Please include at least one availabile time.
                    </Typography>
                )}
                <div className="form-grid-half">
                    <div>
                        <Typography variant="body">Is Priority?</Typography>
                        <Toggle onChange={handleChange} name="isPriority" value={_dj.isPriority} />
                    </div>

                    <div>
                        <Typography variant="body">Is Mailing List?</Typography>
                        <Toggle onChange={handleChange} name="isInMailingList" value={_dj.isInMailingList} />
                    </div>
                </div>
                <div>
                    <Typography variant="body">Notes</Typography>
                    <TextArea onChange={handleChange} value={_dj.notes} name="notes" />
                </div>
            </div>
            <ModalActionBar>
                <Button onClick={close} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="secondary" loading={loading}>
                    {dj.id ? 'Save' : 'Submit'}
                </Button>
            </ModalActionBar>
        </Modal>
    );
};

export default StationDjModal;
