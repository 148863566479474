import { Format } from "./Contract";

export default interface LoginUser {
    id: string;
    firstName: string;
    lastName: string;
    name?: string;    
    role: UserRole;
    status: number;
    access_Token: string;
    emailAddress: string;
    format: Format
}

export enum UserRole {
    Admin = "Admin",
    Visitor = "Visitor",
    Employee = "Employee"
}

export interface SystemUser {
    id?: string;
    firstName: string;
    lastName: string;
    name?: string;
    emailAddress: string;
    role: UserRole;
    status?: UserStatus;
    createdAt?: Date;
    isDisabled?:boolean;
    LastLogon?: string;
    UpdatedAt?: string;
}

export enum UserStatus
{
    Draft,
    Approved,
    Registered,
    Denied
}

export type CallerDJ = {
    djId: string;
    djName: string;
    formats: Format[];
}