import React from 'react';
import IconButton from '../../components/Core/IconButton';
import { Contact } from '../../models/Station';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as PrimaryIcon } from '../../assets/icons/check-circle.svg';
import { StationService } from '../../api';
import Typography from '../../components/Core/Typography';
import { formatPhoneNumber } from '../../utils';
import Tooltip from '../../components/Core/Tooltip';

type Props = {
    contacts: Contact[]
    setSelectedContact: (contact: Contact) => void;
    getData:()=>void;
};
const Contacts: React.FC<Props> = ({ setSelectedContact, contacts, getData }) => {    

    const handleDelete = (id: string) => {
        StationService.deleteStationContact(id).then(() => {
            getData();
        });
    };

    return (
        <>
        <br/>
        <Typography variant='subtitle' weight={800}>STATION CONTACTS</Typography>
        <div className="contact-card-grid">
            {contacts.map((contact) => {
                return <div
                    key={contact.id}
                    className="contact-card"
                    tabIndex={0}
                    onClick={() => setSelectedContact(contact)}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                        e.key === 'Enter' && setSelectedContact(contact)
                    }
                >
                    {contact.isPrimary && <Tooltip text='primary contact'><PrimaryIcon className='primary-check color-success'/></Tooltip>}
                    <Typography variant='subtitle'>{contact.firstName} {contact.lastName}</Typography>
                    <Typography variant="body"><strong>Email: </strong>{contact.emailAddress}</Typography>
                    {contact.phoneNumber && <Typography variant="body"><strong>Phone Number: </strong>{formatPhoneNumber(contact.phoneNumber)}</Typography>}
                <div className='card-actions'>
                    <IconButton Icon={EditIcon} tooltip="Edit Contact" onClick={()=>setSelectedContact(contact)} color="info"/>
                    <IconButton Icon={DeleteIcon} tooltip="Delete Contact" onClick={()=>handleDelete(contact.id as string)} color="error"/>
                </div>
                </div>;
            })}
        </div>
        </>
    );
};

export default Contacts;
