import React from 'react';
import Typography from '../../components/Core/Typography';
import StripContract from '../../models/StripContract';
import { useDrag, useDrop } from 'react-dnd';
import { getDiffernceInDays } from '../../utils';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as UpArrow } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/arrow-down.svg';

type Props = {
    strip: StripContract;
    index: number;
    onDrop: (source: StripContract, target: StripContract) => void;
    onChange: (from: number, to: number) => void;
};

export const getColorCode = (startDate: string, endDate: string) => {
    /* eslint-disable-next-line react/prop-types */
    // const startDate = strip.startDate;

    // /* eslint-disable-next-line react/prop-types */
    // const endDate = strip.endDate;

    const totalDifference = getDiffernceInDays(startDate as string, endDate as string);
    const currentDifference = getDiffernceInDays(new Date().toString(), endDate as string);

    const percentage = ((totalDifference - currentDifference) / totalDifference) * 100;

    if (percentage >= 80) {
        return 'success';
    } else if (percentage >= 60) {
        return 'info';
    } else if (percentage >= 40) {
        return 'warning';
    } else if (percentage >= 20) {
        return 'primary';
    } else {
        return 'error';
    }
};
const StripDetailItem: React.FC<Props> = ({ strip, index, onDrop, onChange }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'StripContract',
        drop: (item: StripContract) => handleDrop(item),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'StripContract',
            item: strip,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            collect: (monitor: any) => ({
                isDragging: !!monitor.isDragging(),
                item: strip,
            }),
        }),
        [],
    );

    const handleDrop = (source: StripContract) => {
        onDrop(source, strip);
    };

    return (
        <div
            className={`detail-item-container${isOver ? ' hovering' : ''}${(index + 1) % 5 == 0 ? ' fifth' : ''}`}
            title="drag to reorder"
            ref={drop}
        >
            <div className={`detail-item${isDragging ? ' active' : ''}`} ref={drag}>
                <div className="detail-index">{index + 1}</div>
                <div
                    className={`detail-description detail-description--${getColorCode(
                        strip.startDate as string,
                        strip.endDate as string,
                    )}`}
                >
                    <div className="strip-detail-description-metadata">
                        <Typography variant="body" weight={600}>
                            {strip.artistName.toUpperCase()}
                        </Typography>
                    </div>
                    <div className="strip-detail-action">
                        <IconButton Icon={UpArrow} onClick={() => onChange(index, index - 1)} />
                        <IconButton Icon={DownArrow} onClick={() => onChange(index, index + 1)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StripDetailItem;
