import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';

import { ClientService } from '../../api';
import Client from '../../models/Client';
type Props = {
    client: Client;
    fetchData: () => void;
};
const ClientActions: React.FC<Props> = ({ client, fetchData }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleOpenDeleteClientDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to delete ${client.name}?`,
                handleConfirm: () => handleDelete(client.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        ClientService.deleteClient(id).then(() => {
            fetchData();
        });
    };

    return (
        <>
            <Link to={`/clients/${client.id}`}>
                <IconButton Icon={ExternalLinkIcon} color="tertiary" tooltip='Client details'/>
            </Link>
            <IconButton Icon={DeleteIcon} onClick={handleOpenDeleteClientDialog} color="error" tooltip='Delete client'/>
        </>
    );
};

export default ClientActions;
