import React from 'react';
import Select, { MultiValue } from 'react-select';
import './multiselect.scss';
type Option = {
    label: string;
    value: string | number;
};

type Props = {
    onChange: (name: string, value: Array<string | number> ) => void;
    name: string;
    value?: Array<string | number>;
    options: Array<Option>;
    placeholder: string;
    error?: boolean;
    errorText?:string;
};

const InputMultiSelect: React.FC<Props> = ({ name, value=[], options, placeholder, onChange, error, errorText }) => {
    const [selected, setSelected] = React.useState<Array<Option> | undefined>();

    React.useEffect(() => {
        const newValue:Array<Option>=[];
        options.forEach((element: Option)=> {
            if(value?.includes(element.value)) {
                newValue.push(element);
            }
        })
        setSelected(newValue);
    }, [value]);

    const handleChange = (options: MultiValue<Option>): void => {
        if (options) {
            const newValue: Array<string | number> = [];
            options.forEach((element: Option)=> {
                newValue.push(element.value);
            })
            onChange(name, newValue);
        }
    };
    return (
        <div className="multi-select-container">
            <Select
                onChange={handleChange}
                name={name}
                options={options}
                value={selected}
                placeholder={placeholder}
                classNamePrefix={error?  'error-select' : 'input-select'}
                isMulti={true}
            />
            {error && <p className="error-text">{errorText}</p>}
        </div>
    );
};

export default InputMultiSelect;
