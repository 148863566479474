import React from 'react';
import Typography from '../../components/Core/Typography';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg';
import LoaderGif from '../../assets/images/loader.gif';
import { useDebounce, getListFromEnum } from '../../utils';
import { FeedbackService } from '../../api';
import Feedback, { FeedbackCategory } from '../../models/Feedback';
import InputSelect from '../../components/Core/InputSelect';
import Button from '../../components/Core/Button';
import TextArea from '../../components/Core/TextArea';
import './feedbackeditor.scss';
import { DashboardContext } from './state';
import DateInput from '../../components/DateInput';
import { Format } from '../../models/Contract';

type Props = {
    feedbackId?: string;
    feedback?: string;
    feedbackSentiment?: FeedbackCategory;
    contractId?: string;
    stationId?: string;
    feedBackDate?: Date;
    onFeedbackChange?: ()=>void;
    feedbackGenre?: Format;
};

const NoteEditor: React.FC<Props> = ({
    feedbackId,
    feedback = '',
    feedbackSentiment,
    contractId,
    stationId,
    feedBackDate,
    onFeedbackChange,
    feedbackGenre,
}) => {
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [canAutoSave, setCanAutoSave] = React.useState(false);
    const [_feedbackId, setFeedbackId] = React.useState(feedbackId);
    const [_feedback, setFeedback] = React.useState(feedback);
    const [_feedbackSentiment, setFeedbackSentiment] = React.useState(feedbackSentiment);
    const [_feedbackDate, setFeedbackDate] = React.useState(feedBackDate?? new Date());
    const [isUpdating, setIsUpdating] = React.useState(false);
    const debounce = useDebounce(_feedback, 1000) as string;
    const sentiments = getListFromEnum(Object.values(FeedbackCategory));
    const { selectedFormat } = React.useContext(DashboardContext).state;

    React.useEffect(()=> {
        setFeedbackId(feedbackId);
        setFeedback(feedback);
        setFeedbackSentiment(feedbackSentiment);
        setFeedbackDate(feedBackDate?? new Date());
        setCanAutoSave(false);
        handleUpdate();
    }, [contractId, stationId, feedback, feedbackSentiment, feedBackDate])

    React.useEffect(() => {
        if (contractId && _feedbackSentiment && canAutoSave && _feedbackDate) {
            saveData();
        }
    }, [debounce]);

    const handleDateChange = (_name: string, value: string) => {
       
        const date = new Date(value + " 00:00:00"); // Set the time to 00:00:00
        const options = { timeZone: "America/New_York" };
        const dateString = date.toLocaleString("en-US", options);
        const dateInTimeZone = new Date(dateString);
        setFeedbackDate(dateInTimeZone);

    }

    const handleUpdate = () =>{
        setIsUpdating(true);
        setTimeout(()=> setIsUpdating(false),500);
    }

    const handleEditorChange = (_name: string, value: string) => {
        setCanAutoSave(true);
        setFeedback(value);
        setSuccess(false);
    };

    const saveData = () => {
        setLoading(true);
        const payload: Feedback = {
            id: _feedbackId,
            stationId: stationId as string,
            contractId: contractId as string,
            category: _feedbackSentiment as FeedbackCategory,
            comment: debounce,
            genre:  feedbackGenre? feedbackGenre : selectedFormat,
            feedbackDate: _feedbackDate
        };
        const request = !_feedbackId
            ? FeedbackService.createFeedback(payload)
            : FeedbackService.updateFeedback(payload);
        request
            .then((response) => {
                if (_feedbackId !== response.id) {
                    setFeedbackId(response.id);
                }
                setSuccess(true);
            })
            .finally(() => {
                setLoading(false);
                onFeedbackChange && onFeedbackChange();
            });
    };
if(isUpdating) return null;
    return (
        <div
            className="feedback-editor"
            style={{ opacity: contractId ? 1 : 0.6, pointerEvents: contractId ? 'all' : 'none' }}
        >
            <Typography variant="subtitle" weight={600}>
                FEEDBACK
            </Typography>
            {!contractId && (
                <Typography variant="body" color="error">
                    Please make the selections on the left to start writing feedback.
                </Typography>
            )}
            <br />
            <Typography variant="body">Please provide feedback sentiment</Typography>
            <InputSelect
                options={sentiments}
                value={_feedbackSentiment}
                onChange={(_name, value) => {
                    setFeedbackSentiment(value as number);
                }}
                name="feedbackSentiment"
                placeholder="Select"
            />
            <br />
            <Typography variant = "body">Date</Typography>
            <DateInput
                        onChange={handleDateChange}
                        name = "endDate"
                        value={_feedbackDate.toString()}
                        />
            <div className="input-area">
                <TextArea
                    onChange={handleEditorChange}
                    disabled={!contractId && _feedbackSentiment === undefined}
                    name="feedback"
                    value={_feedback}
                    placeholder="write feedback here.."
                />
                {canAutoSave && <div className="process-indicator">
                    {loading && <img src={LoaderGif} alt="loader" className="loader" />}
                    {success && <CheckIcon className="color-success" />}
                </div>}
            </div>

            <div className="action-container">
                <Button onClick={saveData} color="success" disabled={!contractId && _feedbackSentiment === undefined}>
                    {_feedbackId ? 'Update' : 'Save'}
                </Button>
            </div>
        </div>
    );
};

export default NoteEditor;
