import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/table.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from '../../assets/icons/unarchive.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { StationService } from '../../api';
import { StationGridViewModel } from '../../models/Station';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';

type Props = {
    station: StationGridViewModel;
    fetchData: () => void;
};

const StationActions: React.FC<Props> = ({ station, fetchData }) => {
    const history = useHistory();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    const handleOpenDeleteStationDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Station',
                description: `Are you sure you want to delete ${station.name}?`,
                handleConfirm: () => handleDelete(station.id as string),
            },
        });
    };

    const handleOpenArchiveContractDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Disable Station',
                description: `Are you sure you want to disable ${station.name}?`,
                handleConfirm: () => handleArchive(station.id),
            },
        });
    };

    const handleOpenUnArchiveContractDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Enable Station',
                description: `Are you sure you want to enable ${station.name}?`,
                handleConfirm: () => handleUnArchive(station.id),
            },
        });
    };

    const handleDelete = (id: string) => {
        StationService.deleteStation(id).then(() => {
            fetchData();
        });
    };

    const handleArchive = (id: string) => {
        StationService.archiveStation(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'Station was successfully disabled.',
                    status: 'success',
                    autoClose: false,
                },
            });
            fetchData();
        });
    };

    const handleUnArchive = (id: string) => {
        StationService.unarchiveStation(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'Station was successfully enabled.',
                    status: 'success',
                    autoClose: false,
                },
            });
            fetchData();
        });
    }

    return (
        <>
            <IconButton
                Icon={ExternalLinkIcon}
                onClick={() => history.push(`/stations/${station.id}`)}
                color="tertiary"
                tooltip="Station details"
            />
            <Link to={`/work-station/${station.id}`}>
                <IconButton Icon={DashboardIcon} color="tertiary" tooltip="Add feedback" />
            </Link>
            {station.isLive && <IconButton onClick={handleOpenArchiveContractDialog} Icon={ArchiveIcon} color="tertiary" tooltip="Disable"/>}
            {!station.isLive && <IconButton onClick={handleOpenUnArchiveContractDialog} Icon={UnArchiveIcon} color="tertiary" tooltip="Enable" />}
            <IconButton
                Icon={DeleteIcon}
                onClick={handleOpenDeleteStationDialog}
                color="error"
                tooltip="Delete station"
            />
        </>
    );
};

export default StationActions;
