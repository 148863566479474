import React from 'react';
import { useParams } from 'react-router-dom';
import Station, { Contact, DJ } from '../../models/Station';
import { DataLoadingContext } from '../../components/DataLoading';
import { StationService } from '../../api';
import StationForm from './StationForm';
import StationDetail from './StationDetail';
import './station.scss';
import Contacts from './contacts';
import Djs from './Djs';
import Button from '../../components/Core/Button';
import StationContactModal from './StationContactModal';
import StationDjModal from './StationDjModal';

const initStation: Station = {
    name: '',
    isLive: false,
    needsMailing: false,
    formats: [],
    aliases: []
};
const initContact: Contact = {
    stationId: '',
    firstName: '',
    lastName: '',
    isPrimary: false,
};

const initDJ: DJ = {
    firstName: '',
    djAvailabilities: [],
    lastName: '',
    formats: [],
    phoneNumber: '',
    isInMailingList: false,
    isPriority: false,
};

enum StationModals {
    CLOSED,
    DJ,
    CONTACT,
}
const StationPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [station, setStation] = React.useState<Station | null>(null);
    const [isFormView, setIsFormView] = React.useState(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [contacts, setContacts] = React.useState<Contact[]>([]);
    const [contactSelection, setContactSelection] = React.useState<Contact>(initContact);
    const [djs, setDjs] = React.useState<DJ[]>([]);
    const [djSelection, setDjSelection] = React.useState<DJ>(initDJ);
    const [openModal, setOpenModal] = React.useState(StationModals.CLOSED);

    React.useEffect(() => {
        if (id !== 'add-new') {
            getData();
        } else {
            setIsFormView(true);
            setStation(initStation);
        }
    }, [id]);

    const getData = () => {
        toggleLoading(true);
        StationService.getStation(id)
            .then((response) => {
                setStation(response);
            })
            .finally(() => {
                toggleLoading(false);
            });
        getStationContacts();
        getStationDjs();
    };

    const getStationContacts = () => {
        StationService.getAllStationContact(id).then((response) => {
            setContacts(response);
        });
    };

    const getStationDjs = () => {
        StationService.getAllStationDJ(id).then((response) => setDjs(response));
    };

    return station ? (
        <div className="station">
            {isFormView ? (
                <StationForm station={station} toggleView={() => setIsFormView(false)} />
            ) : (
                <StationDetail station={station} toggleView={() => setIsFormView(true)} />
            )}
            {station.id && (
                <div className="action-buttons">
                    <Button onClick={() => setOpenModal(StationModals.CONTACT)}>Add Contact</Button>
                    <Button onClick={() => setOpenModal(StationModals.DJ)}>Add DJ</Button>
                </div>
            )}
            {station.id && (
                <Contacts
                    setSelectedContact={(val) => {
                        setContactSelection(val);
                        setOpenModal(StationModals.CONTACT);
                    }}
                    getData={getStationContacts}
                    contacts={contacts}
                />
            )}
            {station.id && (
                <Djs
                    setSelectedDj={(val) => {
                        setDjSelection(val);
                        setOpenModal(StationModals.DJ);
                    }}
                    getData={getStationDjs}
                    djs={djs}
                />
            )}
            <StationContactModal
                contact={{...contactSelection, stationId: id}}
                open={openModal === StationModals.CONTACT}
                close={() => {
                    setOpenModal(StationModals.CLOSED);
                    setContactSelection(initContact);
                }}
                getData={getStationContacts}
            />
            <StationDjModal
                dj={{...djSelection, stationId: id}}
                open={openModal === StationModals.DJ}
                close={() => {
                    setOpenModal(StationModals.CLOSED);
                    setDjSelection(initDJ);
                }}
                getData={getStationDjs}
            />
        </div>
    ) : (
        <></>
    );
};
export default StationPage;
