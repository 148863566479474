export default interface Contract {
    id?: string;
    clientId: string;
    clientName?: string;
    campaignGoals?: string;
    status: ContractStatus;
    startDate?: string | Date;
    endDate?: string | Date;
    releaseType: ReleaseType;
    releaseName: string;
    releaseAliases: Array<string>;
    artistName: string;
    artistAliases: Array<string>;
    labelName: string;
    formats: Format[];
    priority: number;
    notes?: string;
    dateCreated?: string;
    dateUpdated?: string;
    hasMailingList: boolean;
}

export interface AddContractMailingListReq {
    stationId: string;
    contractId: string;
}

export interface ContractMailingListResp {
    id: string;
    stationId: string;
    name: string;
    country?: string;
    addressLine1?:string;
    addressLine2?:string;
    city?: string;
    state?: string;
    zip?: string;
    priority?: number;
    needsMailing: boolean;
}

export interface CalendarEvent {
    id: string;
    title: string;
    start: string;
    end: string;
    formats: Format[];
    backgroundColor: string;
    borderColor: string;
    allDay: boolean;
}

export enum ContractStatus {
    Active = 0,
    Archived = 1,
}

export enum ReleaseType {
    Single = 0,
    Album = 1,
}

//keep in sorted order for creating list;
export enum Format {
    AAA = 1,
    Alternative = 2,
    Americana = 3,    
    College = 4,
    Electronic = 5,
    'Hip-Hop' = 6,
    Metal = 7,
    Rock = 8,
    Specialty = 9,
    Other = 10
}

export enum NACCDataType{ 
    Heavy = 1,
    Electronic = 2,
    'Hip-Hop' = 3,
    'Top 30 Charts' = 4,
    'Hip-Hop Adds' = 5,
    'Electronic Adds' = 6,
    'Heavy Adds' = 7,   
    'Top Adds' = 8,
    
}

export enum NACCUploadType{
    Spin = 0,
    Adds =1
}
