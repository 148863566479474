import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClientService } from '../../api';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import Button from '../../components/Core/Button';
import Card, { CardActionBar } from '../../components/Core/Card';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Typography from '../../components/Core/Typography';
import Client from '../../models/Client';

type Props = {
    client: Client;
    toggleView: () => void;
};
const ClientDetail: React.FC<Props> = ({ client, toggleView }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const history = useHistory();

    const handleOpenDeleteClientDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Client',
                description: `Are you sure you want to delete ${client.name}?`,
                handleConfirm: () => handleDelete(client.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        ClientService.deleteClient(id).then(() => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: 'client was successfully deleted.',
                    status: 'success',
                    autoClose: false,
                },
            });
            history.push('/clients');
        });
    };
    return (
        <Card>
            <div className="client-detail">
                <Typography variant="subheading" weight={500} color="secondary">
                    {client.name}
                </Typography>
                <br />
                <div>
                    <Typography variant="subtitle" weight={500} color="tertiary">
                        PRIMARY CONTACT
                    </Typography>
                    <Typography variant="body">
                        <strong>Name: </strong>
                        {client.contactName}
                        <br />
                        {client.contactEmail && client.contactEmail.length>0 && <><strong>Email Address: </strong>
                        {client.contactEmail}</>}
                    </Typography>
                </div>
                <CardActionBar>
                    <Button onClick={toggleView}>Edit</Button>
                    <Button onClick={handleOpenDeleteClientDialog} color="error">
                        Delete
                    </Button>
                </CardActionBar>
            </div>
        </Card>
    );
};

export default ClientDetail;
