
import Feedback from '../models/Feedback';
import FileExportResponse from '../models/FileExportResponse';
import instance from './instance';


function createFeedback(feedback: Feedback): Promise<Feedback> {
    return instance.post(`feedback`, feedback).then((response)=> {
        return response.data;
    })
}

function updateFeedback(feedback: Feedback): Promise<Feedback> {
    return instance.put(`feedback`, feedback).then((response)=> {
        return response.data;
    })
}

function deleteFeedback(id:string): Promise<boolean> {
    return instance.delete(`feedback/${id}`).then((response)=> {
        return response.data
    })
}

function getFeedbackById(id:string): Promise<Feedback> {
    return instance.get(`feedback/${id}`).then((response)=>{
        return response.data;
    })
}

function getFeedbackForContract(contractId: string, station?: string):Promise<Feedback[]> {
    const req = (station) ? `contract/${contractId}/feedbacks?stationId=${station}` : `contract/${contractId}/feedbacks`;
    return instance.get(req).then((response)=> {
        return response.data;
    })
}

function exportFeedbackForContract(contractId: string): Promise<FileExportResponse>{
    return instance.get(`contract/${contractId}/feedbacks/export`).then((response) => {
        return response.data;
    })
}

export {createFeedback, updateFeedback, deleteFeedback, getFeedbackForContract, exportFeedbackForContract, getFeedbackById };
