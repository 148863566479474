import React from 'react';
import Button from '../../components/Core/Button';
import InputSelect from '../../components/Core/InputSelect';
import Typography from '../../components/Core/Typography';
import Upload from '../../components/Upload';
import { PerformanceType, UploadPerformance } from '../../models/ContractPerformance';
import { downloadFile, formatDate, getListFromEnum } from '../../utils';
import { PerformanceService } from '../../api';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import DateInput from '../../components/DateInput';

type Props = {
    handleUpload: (data: UploadPerformance) => Promise<boolean>;
    contractId: string;
};

const PerformanceUpload: React.FC<Props> = ({ handleUpload, contractId }) => {
    const [showTemplateDownload, setShowTemplateDownload] = React.useState<boolean>(false);
    const [type, setType] = React.useState<PerformanceType>();
    const [week, setWeek] = React.useState<string>();
    const [date, setDate] = React.useState(new Date());
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [filteredPerformanceTypes, setFilteredPerformanceTypes] = React.useState<any>();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const[dateError, setDateError] = React.useState(false)

    React.useEffect(() => {
        const performanceTypes = getListFromEnum(Object.values(PerformanceType));
        if(performanceTypes && performanceTypes.length > 0){
            const newPRTypes = performanceTypes.filter(a => a.label !== 'NACC' && a.label !== 'OTHER');
            setFilteredPerformanceTypes(newPRTypes);
        }
    }, [])

    React.useEffect(() => {
        setDateError(false);      
        const newDate = new Date(date.getTime());
      
        if (type === PerformanceType.SUBMODERN) {
          if (newDate.getDay() !== 4) {
            newDate.setDate(newDate.getDate() - ((newDate.getDay() - 4 + 7) % 7));
          }
      
          const first = formatDate(new Date(newDate.getTime()));
          newDate.setDate(newDate.getDate() + 6);
          const last = formatDate(new Date(newDate.getTime()));
      
          if (first === "Invalid Date" || last === "Invalid Date" || first.length === 0 || last.length === 0) {
            setWeek(_prevWeek => {
              setDateError(true);
              return "Invalid Date";
            });
          } else {
            const weekString = `${first}-${last}`;
            setWeek(weekString);
          }
        } else {
          if (newDate.getDay() !== 3) {
            newDate.setDate(newDate.getDate() - ((newDate.getDay() - 3 + 7) % 7));
          }
      
          const first = formatDate(new Date(newDate.getTime()));
          newDate.setDate(newDate.getDate() + 6);
          const last = formatDate(new Date(newDate.getTime()));
      
          if (first === "Invalid Date" || last === "Invalid Date" || first.length === 0 || last.length === 0) {
            setWeek(_prevWeek => {
              setDateError(true);
              return "Invalid Date";
            });
          } else {
            const weekString = `${first}-${last}`;
            setWeek(weekString);
          }
        }
      }, [date, type]);
    
    const onUpload = (file: unknown) => {
        const isValid = week && (type !== PerformanceType.NACC ? contractId : true);
        if (isValid) {
            setShowError(false);
            setLoading(true);
            handleUpload({
                contractId: contractId,
                type: type as PerformanceType,
                week: week as string,
                file: file as File,
            })
                .then(() => setError(''))
                .catch((e) => setError(e))
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    const handleDateChange = (_name: string, value: string) => {
        const date = new Date(value + " 00:00:00"); // Set the time to 00:00:00
        const options = { timeZone: "America/New_York" };
        const dateString = date.toLocaleString("en-US", options);
        const dateInTimeZone = new Date(dateString);
        setDate(new Date(dateInTimeZone));
    };

    const handleTypeChange = (value: PerformanceType) => {
        setType(value);
        
        if(value === PerformanceType.SUBMODERN || value === PerformanceType.JACKBARTON){
            setShowTemplateDownload(true);
        }
        else{
            setShowTemplateDownload(false);
        }
    }

    const downloadTemplate = () => {
        setLoading(true);

        PerformanceService.downloadTemplate(type)
        .then((response) => {
            downloadFile(response.content, response.fileName);
        })
        .catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: false,
                },
            });
        })

        setLoading(false);
    }

    return (
        <>
            {error.length>0 && <Typography variant='body' color="error" textAlign='center'>{error}</Typography>}
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Type of data <span className="color-error">*</span>
                    </Typography>
                    {
                        filteredPerformanceTypes && 
                            <InputSelect
                                onChange={(_name, value) => handleTypeChange(value as PerformanceType)}
                                name="type"
                                value={type}
                                options={filteredPerformanceTypes}
                                placeholder="Select an option"
                                error={showError && typeof type === 'undefined'}
                            />
                    }
                </div>
                <div>
                    <Typography variant="body">
                        Date <span className="color-error">*</span>
                    </Typography>
                    <DateInput
                        onChange={handleDateChange}
                        name = "date"
                        value={date.toLocaleDateString('en-US')}
                        error = {dateError}
                        />
                    <div className="week-seletion">
                        <Typography variant="caption" color={dateError? "error": "info"} textAlign="right">
                            {week}
                        </Typography>
                    </div>
                </div>
              </div>
                {showTemplateDownload && 
                    <div>
                        <hr style={{border: '1px solid black'}}/>
                        <Typography textAlign='left' variant='body' weight={600} color='secondary'>
                            If you do not have a template. Please click on the button below to download the template file.
                        </Typography>
                        <Button onClick={downloadTemplate}>Download Template</Button>
                        <hr style={{border: '1px solid black'}}/>
                    </div>
                }
            <br />
            <Upload maxSize={5000} handleUpload={onUpload} fileTypes={['csv']} loading={loading} disabled ={dateError || typeof type === 'undefined'} />
        </>
    );
};

export default PerformanceUpload;
