import React from 'react';
import { StationDashboardViewModel, StationGridViewModel } from '../../../../models/Station';
import { SearchStationAction, SearchStationActionType } from './actions';
import { Format } from '../../../../models/Contract';

type SearchReducerState = {
    country?: string;
    distance?: number;
    searchState?: string;
    city?: string;
    zip?: string;
    selectedStation?: StationDashboardViewModel;
    total: number;
    page: number;
    pageSize: number;
    mainSearchStations: StationGridViewModel[];
    dropDownSearchStations: StationDashboardViewModel[];
    initiateSearch: boolean;
    formats?: Format[];
    showData:boolean;
}

export const initialState: SearchReducerState = {
    total: -1,
    page: 1,
    pageSize: 10,
    mainSearchStations: [],
    dropDownSearchStations: [],
    initiateSearch: false,
    showData:true,
}

export function SearchReducer(state: SearchReducerState, action: SearchStationAction): SearchReducerState {
    switch(action.type){
        case SearchStationActionType.SET_COUNTRY:
            return {...state, country: action.payload, searchState: action.payload=='US'? 'AL' : 'AB', page:1};
        case SearchStationActionType.SET_DISTANCE:
            return {...state, distance: action.payload};
            case SearchStationActionType.SET_CITY:
                return {...state, city: action.payload, page:1};
            case SearchStationActionType.SET_STATE:
                return {...state, searchState: action.payload, page:1};
            case SearchStationActionType.SET_SELECTED_STATION:
                return {...state, selectedStation: action.payload};
            case SearchStationActionType.SET_ZIP_CODE:
                return {...state, zip: action.payload, page:1};
            case SearchStationActionType.SET_PAGE:
                return {...state, page: action.payload.page, pageSize: action.payload.pageSize };
            case SearchStationActionType.SET_TOTAL:
                return {...state, total: action.payload};
            case SearchStationActionType.SET_MAIN_STATIONS:
                return {...state, mainSearchStations: action.payload};
            case SearchStationActionType.SET_DROP_DOWN_STATIONS:
                return {...state, dropDownSearchStations: action.payload};
            case SearchStationActionType.TOGGLE_SEARCH:
                return {...state, initiateSearch: !state.initiateSearch };
                case SearchStationActionType.SET_FORMAT:
                    return {...state, formats: action.payload, page:1};
            case SearchStationActionType.SET_SHOW_CLUSTER:
                return {...state, showData: action.payload}
            case SearchStationActionType.CLEAR_FILTER:
                return {...state, country: undefined,distance: undefined, searchState: undefined, city: undefined, zip: undefined, selectedStation: undefined, formats: undefined, page: 1, total:-1 };
        default:
            return state;
    }
}

export const SearchStationContext = React.createContext<{
    state: SearchReducerState,
    dispatch: React.Dispatch<SearchStationAction>;
}>({
    state: initialState,
    dispatch: () => undefined
});