import React from 'react';
import './button.scss';

type Props = {
    /** color to use for the background */
    color?: string;
    textColor?: string;
    /** standard children prop: accepts any valid React Node */
    children: React.ReactNode;
    /** callback function passed to the onClick handler*/
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
};
const LoadingIcon: React.FC = () => {
    return (
        <svg
            version="1.1"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height="16px"
            viewBox="0 0 52 16"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
        >
            <circle fill="#fff" stroke="none" cx="6" cy="8" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="8" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="8" r="6">
                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
            </circle>
        </svg>
    );
};

const Button: React.FC<Props> = ({ children, color = 'primary', onClick, disabled, className, loading = false, type="button", textColor='white'}) => {

    return (
        <button
            className={`button bg-${color} color-${textColor}${className ? ` ${className}` : ''}`}
            onClick={onClick}
            tabIndex={0}
            disabled={disabled || loading}
            type={type}
            onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) => e.key==="Enter" && onClick && onClick()}
        >
            {loading ? <LoadingIcon /> : children}
        </button>
    );
};

export default Button;
