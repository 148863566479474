import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from "@fullcalendar/list";
import './calendar.scss';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as EventIcon } from '../../assets/icons/list.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { useHistory } from 'react-router-dom';
import { DataLoadingContext } from '../../components/DataLoading';
import { CalendarEvent, Format } from '../../models/Contract';
import { ContractService } from '../../api';
import { getFormatList, getFormatName } from '../../utils';

const Calendar: React.FC<{ isEmbed?: boolean }> = ({ isEmbed = false }) => {
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [isListView, setIsListView] = React.useState(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calanderRef = React.useRef<any>(null);
    const [events, setEvents] = React.useState<CalendarEvent[]>([]);
    const history = useHistory();
    const formats = getFormatList();

    React.useEffect(() => {
        toggleLoading(true);
        ContractService.getContractsForCalendar().then((response) => {
            handleSetEvents(response);
        }).finally(() => toggleLoading(false));
    }, []);

    const handleSetEvents = (events: CalendarEvent[]) => {
        const eventsWithBackground = events.map(event => ({
            ...event,
            end: event.start,
            backgroundColor: getEventBackgroundColor(event.formats),
            borderColor: getEventBackgroundColor(event.formats)
        }));

        setEvents(eventsWithBackground);
    }

    const handleCalanderView = () => {
        setIsListView(false);
        if (calanderRef.current) {
            calanderRef.current.getApi().changeView('dayGridMonth');
        }
    }

    const handleListView = () => {
        setIsListView(true);
        if (calanderRef.current) {
            calanderRef.current.getApi().changeView('listYear');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (info: any) => {
        history.push(`/projects/${info.event.id}`);
    }

    const getEventBackgroundColor = (formats: Format | Format[]): string => {
        if (Array.isArray(formats) && formats.length > 1) {
            return "#ffd700"
        }
        else {
            const format = Array.isArray(formats) ? formats[0] : formats;

            switch (format) {
                case Format.AAA:
                    return '#1f77b4'; // muted blue
                case Format.Alternative:
                    return '#ff7f0e'; // safety orange
                case Format.Americana:
                    return '#2ca02c'; // forest green
                case Format.College:
                    return '#d62728'; // brick red
                case Format.Electronic:
                    return '#9467bd'; // muted purple
                case Format['Hip-Hop']:
                    return '#8c564b'; // chestnut brown
                case Format.Metal:
                    return '#e377c2'; // raspberry pink
                case Format.Rock:
                    return '#7f7f7f'; // middle gray
                case Format.Specialty:
                    return '#bcbd22'; // olive
                case Format.Other:
                    return '#17becf'; // medium turquoise
                default:
                    return '#ff0000'; // red - for unexpected formats
            }
        }
    }


    return (
        <div className="calendar-container">
            <div className='legend-container'>
                {formats.map((format, idx) => {
                    return (
                        <div className='legend-item-container' key={idx}>
                            <div className='legend-item' style={{ backgroundColor: getEventBackgroundColor(format) }}></div>
                            <div>{getFormatName(format)}</div>
                        </div>
                    )
                })
                }
                <div className='legend-item-container'>
                    <div className='legend-item' style={{backgroundColor: '#ffd700'}}></div>
                    <div>Multi-Formats</div>
                </div>
            </div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                dayMaxEvents={3}
                initialView="listYear"
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth',
                }}
                eventClick={handleClick}
                events={events}
                ref={calanderRef}
            />
            {!isEmbed &&
                <div className='toggle-icons'>
                    <IconButton onClick={handleCalanderView} Icon={CalendarIcon} color={isListView ? 'secondary' : 'info'} tooltip="Calendar view" />
                    <IconButton onClick={handleListView} Icon={EventIcon} color={!isListView ? 'secondary' : 'info'} tooltip="List View" />
                </div>
            }
        </div>
    );
};

export default Calendar;
