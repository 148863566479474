import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridToolbar,
    GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { ClientService} from '../../api';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Core/Button';
import ClientActions from './ClientActions';
import Client from '../../models/Client';
import './clients.scss';
import pagerSettings from '../../utils/pagerSettings';


const Clients: React.FC = () => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [clients, setClients] = React.useState<Client[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        toggleLoading(true);
        ClientService.getClients()
            .then((response) => {
                setClients(response);
            })
            .finally(() => toggleLoading(false));
    };    

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <ClientActions client={props.dataItem as Client} fetchData={getData} />
            </td>
        );
    };

    return (
        <div className="clients">
            <div className="client-list">
                <Grid
                    style={{
                        height: '100%',
                    }}
                    sortable={true}
                    pageable={pagerSettings}
                    filterable={true}
                    data={process(clients, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                    }}
                    // eslint-disable-next-line react/prop-types
                    onRowClick={(props) => history.push(`/clients/${props.dataItem.id}`)}
                >
                    <GridToolbar>
                        <Button onClick={() => history.push(`/clients/add-new`)}>Create</Button>
                    </GridToolbar>
                    <GridColumn field="name" title="Name" />
                    <GridColumn field="contactName" title="Primary Contact Name" />
                    <GridColumn field="contactEmail" title="Primary Contact Email" />
                    <GridColumn cell={GridActions} filterable={false} />
                </Grid>
            </div>
        </div>
    );
};

export default Clients;
