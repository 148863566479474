import { TemplateConfig } from "./models";
import routes from "./routeConfig";
import themecolors from "./themeColors";

const config: TemplateConfig = {
    //yet to implement colors
    colors: themecolors,
    routes: routes
}

export default config;