import React from 'react';
import { DataLoadingContext } from '../../components/DataLoading';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { PerformanceService } from '../../api';
import pagerSettings from '../../utils/pagerSettings';
import { SpinUploadsRes } from '../../models/StripContract';
import IconButton from '../../components/Core/IconButton';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import Performance, { NACCAdds, PerformanceType, UploadPerformance } from '../../models/ContractPerformance';
import Modal from '../../components/Core/Modal';
import Button from '../../components/Core/Button';
import Typography from '../../components/Core/Typography';
import Upload from '../../components/Upload';
import { formatDate, getListFromEnum } from '../../utils';
import PerformanceTable from '../ContractPerformance/PerformanceTable';
import Checkbox from '../../components/Core/Checkbox';
import './spinuploads.scss';
import { NACCDataType, NACCUploadType } from '../../models/Contract';
import InputSelect from '../../components/Core/InputSelect';
import AddsTable from './AddsTable';
import DateInput from '../../components/DateInput';

const SpinUploads: React.FC = () => {
    const [dataState, setDataState] = React.useState<State>({ skip: 0, take: 20 });
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [spinUploads, setSpinUploads] = React.useState<SpinUploadsRes[]>([]);
    const [performanceObject, setPerformanceObject] = React.useState<{ items: Performance[], total: number }>({ items: [], total: 0 });
    const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
    const [week, setWeek] = React.useState<string>();
    const [date, setDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const [showUploads, setShowUploads] = React.useState<boolean | undefined>();
    const [showAdds, setShowAdds] = React.useState(false);
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [format, setFormat] = React.useState<NACCDataType | undefined>(undefined);
    const formats = getListFromEnum(Object.values(NACCDataType));
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();
    const [addsDataObject, setAddsDataObject] = React.useState<{ items: NACCAdds[], total: number }>({ items: [], total: 0 });
    const [filters, setFilters] = React.useState();
    const [dateError, setDateError] = React.useState(false)

    React.useEffect(() => {
        if (showUploads) {
            getUploadData();
            setFilters(undefined);

        }       
    }, [showUploads]);



    React.useEffect(() => {
        setDateError(false);
        if (date.getDay() !== 3) { // Wednesday is represented by 3
            date.setDate(date.getDate() - ((date.getDay() - 3 + 7) % 7));
        }

        // set the first day of the week (Wednesday) as the start of the range
        const first = formatDate(new Date(date.getTime()));

        // set the date to the next Tuesday
        date.setDate(date.getDate() + 6);

        // set the last day of the week (Tuesday) as the end of the range
        const last = formatDate(new Date(date.getTime()));

        if (first == "Invalid Date" || last == "Invalid Date" || first.length == 0 || last.length == 0) {
            setDateError(true);
            setWeek("Invalid Date");

        }
        else {
            const weekString = `${first}-${last}`;
            setWeek(weekString)
        }

    }, [date]);

    const handleDateChange = (_name: string, value: string) => {
        const date = new Date(value + " 00:00:00"); // Set the time to 00:00:00
        const options = { timeZone: "America/New_York" };
        const dateString = date.toLocaleString("en-US", options);
        const dateInTimeZone = new Date(dateString);
        setDate(new Date(dateInTimeZone));
    };

    const getUploadData = () => {
        toggleLoading(true);
        PerformanceService.getPriorityUploads()
            .then((response) => {
                setSpinUploads(response);
            }).finally(() => toggleLoading(false));
    };

    const getAddsData = () => {
        toggleLoading(true);
        PerformanceService.getUnMatchedAddsForGrid()
            .then((response) => {
                setAddsDataObject(response);
            }).finally(() => toggleLoading(false));
    };

    const getUnMatchedData = () => {
        toggleLoading(true);
        PerformanceService.getUnMatchedForGrid()
            .then((response) => {
                setPerformanceObject(response);

            }).finally(() => toggleLoading(false));
    }

    const handlePageChange = (page: number) => {
        const request = (filters) ? PerformanceService.getUnMatchedForGrid(page, filters) : PerformanceService.getUnMatchedForGrid(page);
        const addsRequest = (filters) ? PerformanceService.getUnMatchedAddsForGrid(page, filters) : PerformanceService.getUnMatchedAddsForGrid(page);
        if (showAdds) {
            addsRequest.then((response) => {
                setAddsDataObject(response);
            });
        }
        else {
            request.then((response) => {
                setPerformanceObject(response);
            });
        }

    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFilterChange = (filters?: any) => {
        if (filters) {
            setFilters(filters);
            if (showAdds) {
                PerformanceService.getUnMatchedAddsForGrid(1, filters).then((response) => {
                    setAddsDataObject(response);
                })
            }
            else {
                PerformanceService.getUnMatchedForGrid(1, filters).then((response) => {
                    setPerformanceObject(response);
                })
            }

        }
        else {
            setFilters(undefined);
            if (showAdds) {
                PerformanceService.getUnMatchedAddsForGrid(1).then((response) => {
                    setAddsDataObject(response);
                })
            }
            else {
                PerformanceService.getUnMatchedForGrid(1).then((response) => {
                    setPerformanceObject(response);
                })
            }
        }

    }

    const handleOpenDeleteUploadDialog = (data: SpinUploadsRes) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Spin Upload',
                description: `Are you sure you want to remove data associated with this upload?`,
                handleConfirm: () => handleUnAssociation(data.requestId, data.dataType),
            },
        });
    };

    const handleChange = (_name: string, value: number) => {
        setFormat(value);
    }

    const GridActions = (props: GridCellProps) => {
        return (
            <td className="grid-actions">
                <IconButton Icon={DeleteIcon} onClick={() => handleOpenDeleteUploadDialog(props.dataItem as SpinUploadsRes)} color="error" tooltip='Remove Associated Information' />
            </td>
        );
    };


    const handleUnAssociation = (requestId: string, uploadType: NACCUploadType) => {
        toggleLoading(true);
        PerformanceService.removeAssociatedData(requestId, uploadType)
            .then(() => {
                getUploadData();
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
    };

    const onUpload = (file: unknown) => {

        setShowError(false);
        if (format == undefined) {
            setShowError(true);
        }
        else {
            setLoading(true);
            handleUpload({
                type: PerformanceType.NACC,
                week: week as string,
                file: file as File,
                genre: format
            })
                .finally(() => { setFormat(undefined); setLoading(false) });
        }
    };

    const handleUpload = (data: UploadPerformance): Promise<boolean> => {
        setError(undefined);
        return new Promise((resolve, reject) => {
            PerformanceService.uploadPerformance(data)
                .then((response) => {
                    if (response.data == false) {
                        setError(response.message);
                        resolve(true);
                    }
                    else {
                        getUploadData();
                        setIsUploadModalOpen(false);
                        getUnMatchedData();
                        if (showAdds) getAddsData();
                        resolve(true);
                    }
                })
                .catch((e) => {
                    reject(e.message);
                });
        });
    };

    const dateCell = (props: GridCellProps) => {
        /* eslint-disable-next-line react/prop-types */
        const temp = props.dataItem as SpinUploadsRes;
        return <td className='clickable-grid-custom-item'>{formatDate(temp.dateCreated as string)}</td>;
    };

    return (
        <div className="spin-uploads">
            {
                (showUploads && !showAdds) &&
                <div className="spin-uploads-list">
                    <Grid
                        style={{
                            height: '100%',
                        }}
                        sortable={true}
                        pageable={pagerSettings}
                        filterable={true}
                        data={process(spinUploads, dataState)}
                        {...dataState}
                        onDataStateChange={(e: GridDataStateChangeEvent) => {
                            setDataState(e.dataState);
                        }}
                    >
                        <GridToolbar>
                            <Checkbox
                                checked={showAdds}
                                onChange={() => { setShowUploads(false); setShowAdds(!showAdds); }}
                                label="Show Adds"
                            />
                            <Checkbox
                                checked={showUploads}
                                onChange={() => { setShowUploads(!showUploads); setShowAdds(false); }}
                                label="Show Uploads"
                            />

                            <Button color="primary" onClick={() => setIsUploadModalOpen(true)}>
                                Upload NACC
                            </Button>
                        </GridToolbar>
                        <GridColumn field="fileName" title="Uploaded File Name" />
                        <GridColumn field="typeOfUpload" title="Uploaded For" />
                        <GridColumn field="dateCreated" title="Date Created" cell={dateCell} />
                        <GridColumn cell={GridActions} filterable={false} width="180px" sortable={false} />
                    </Grid>
                </div>
            }
            {(showAdds && !showUploads) &&
                <AddsTable
                    data={addsDataObject.items}
                    isInGlobal={true}
                    total={addsDataObject.total}
                    toggleCheck={() => { setShowUploads(!showUploads); setShowAdds(false); }}
                    toggleAdds={() => { setShowAdds(!showAdds); setShowUploads(false) }}
                    refreshData={() => getAddsData()}
                    toggleUpload={() => setIsUploadModalOpen(true)}
                    handlePageChange={handlePageChange}
                    handleFilterChange={handleFilterChange}
                />

            }
            {
                (!showUploads && !showAdds) &&
                <PerformanceTable
                    data={performanceObject.items}
                    total={performanceObject.total}
                    isInGlobal={true}
                    toggleCheck={() => { setShowUploads(!showUploads); setShowAdds(false); }}
                    toggleAdds={() => { setShowAdds(!showAdds); setShowUploads(false) }}
                    refreshData={() => getUnMatchedData()}
                    toggleUpload={() => setIsUploadModalOpen(true)}
                    handlePageChange={handlePageChange}
                    handleFilterChange={handleFilterChange}
                />
            }
            <Modal isOpen={isUploadModalOpen} close={() => { setError(undefined); setFormat(undefined); setIsUploadModalOpen(false) }} size='large' title="Upload spin data for NACC">
                <div>
                    <div>
                        {error && <Typography variant='body' color='error' textAlign='center'>{error}</Typography>}
                        <Typography variant="body">
                            Genre <span className="color-error">*</span>
                        </Typography>
                        <InputSelect
                            options={formats}
                            value={format}
                            name="format"
                            placeholder='Select Genre'
                            onChange={(name, value) => handleChange(name, value as number)}
                            error={showError && (format === undefined)}
                            errorText='required'
                        />
                    </div>
                    <Typography variant="body">
                        Date <span className="color-error">*</span>
                    </Typography>
                    <DateInput
                        onChange={handleDateChange}
                        name="date"
                        value={date.toLocaleDateString('en-US')}
                        error={dateError}
                    />
                    <div className="week-seletion">
                        <Typography variant="caption" color={dateError ? "error" : "info"} textAlign="right">
                            {week}
                        </Typography>
                    </div>
                </div>
                <br />
                <Upload maxSize={5000} handleUpload={onUpload} fileTypes={['csv']} loading={loading} disabled={dateError || format == undefined} />

            </Modal>
        </div>
    );
};

export default SpinUploads;
