import React from 'react';
import { useDebounce } from '../../utils';
import { getSimilarClients } from '../../api/clientService';
import { DataLoadingContext } from '../../components/DataLoading';
import { ClientSearchResponse } from '../../models/Client';
import Typography from '../../components/Core/Typography';
import { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

type Props = {
    selectedClient?: ClientSearchResponse;
    handleChange: (item: ClientSearchResponse) => void;
    error: boolean;
    errorText: string;
}

const ClientSelector: React.FC<Props> = ({ selectedClient, handleChange, error, errorText }) => {
    const[inputValue, setInputValue] = React.useState<string>();
    const[similarClients, setSimilarClients] = React.useState<ClientSearchResponse[]>([]);
    const[isFetching, setIsFetching] = React.useState<boolean>(false);
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const debounced = useDebounce(inputValue, 500);

    React.useEffect(() => {
        _getSimilarClients();
    }, [debounced]);

    const _getSimilarClients = () => {
        toggleLoading(true);
        setIsFetching(true);
        getSimilarClients(debounced as string)
        .then((res) => {
            setSimilarClients(res);
        })
        .finally(() => {
            toggleLoading(false);
            setIsFetching(false);
        })
    };

    const _handleChange = (item: ClientSearchResponse) => {
        _handleChange(item);
    }
    
    return(
        <>
            <div className='selector'>
                <Select
                    className={error ? 'error' : ''}
                    options={similarClients}
                    components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                    onChange={(el) => handleChange(el as ClientSearchResponse)}
                    value={selectedClient}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    isLoading={isFetching}
                    getOptionValue={(el) => el.id as string}
                    getOptionLabel={(el) => el.name as string}
                    placeholder="search"
                    isSearchable={true}
                    noOptionsMessage={()=>{return <>Search by entering client&aspos;s name or contact&aspos;s name and email address</>}}
                />
            </div>
            {error && <p className="error-text">{errorText}</p>}
        </>
    )
};

const Option: React.FC<OptionProps<ClientSearchResponse>> = (props: OptionProps<ClientSearchResponse>) => {

    return (
        <div className="option">
            <components.Option {...props} className="tag-option-item">
                <div className="details">
                    <div>
                        <Typography variant="body" weight={600}>
                            {props.data.name}
                        </Typography>
                    </div>
                </div>
            </components.Option>
            <Link to={`/clients/${props.data.id}`}>
                <ExternalLinkIcon />
            </Link>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<ClientSearchResponse>> = (props: DropdownIndicatorProps<ClientSearchResponse>) => {
    return(
        <div className='dropdown-indicator'>
            <components.DropdownIndicator {...props}>
                <SearchIcon className='color-grey' />
            </components.DropdownIndicator>
        </div>
    )
}

export default ClientSelector;