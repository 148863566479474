import React from 'react';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';
import Select from 'react-select';
import { StationService, UserService } from '../../api';
import Button from '../../components/Core/Button';
import Modal, { ModalActionBar } from '../../components/Core/Modal';
import Typography from '../../components/Core/Typography';
import { useDebounce } from '../../utils';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { CallerDJ} from '../../models/User';
import { Format } from '../../models/Contract';
import { DJDashboardViewModel } from '../../models/Station';
import IconButton from '../../components/Core/IconButton';

type Props = {
    selectedId: string | null;
    close: () => void;
    open: boolean;
};

const UserDJsModal: React.FC<Props> = ({ selectedId, open, close }) => {
    const [djs, setDjs] = React.useState<DJDashboardViewModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [assignedDjs, setAssignedDjs] = React.useState<CallerDJ[]>([]);
    const [inputValue, setInputValue] = React.useState<string>();
    const [selected, setSelected] = React.useState<DJDashboardViewModel>();
    const [search, setSearch] = React.useState(true);

    const debounced = useDebounce(inputValue, 500);
    React.useEffect(() => {
        if (selectedId) {
            UserService.getUserDjs(selectedId).then((response) => {
                setAssignedDjs(response);
            });
        }
    }, [selectedId]);

    React.useEffect(() => {
        if(search){
            getDJs();
        }
        
    }, [debounced]);


    const getDJs = () => {
        setIsLoading(true);
        
        StationService.getDJsForDashboard(debounced as string)
            .then((res) => {
                setDjs(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const addSelection = () => {
        const temp = [...assignedDjs];
        const existingIndex = temp.findIndex((el) => el.djId === selected?.id);
        if (existingIndex === -1 && selected) {
            temp.push({ djId: selected.id, djName: selected.name, formats: selected.formats });
            setAssignedDjs(temp);
            setSelected(undefined);
            setInputValue('');
        }
    };

    const removeSelection = (val: CallerDJ) => {
        const temp = [...assignedDjs];
        const existingIndex = temp.findIndex((el) => el.djId === val.djId);
        if (existingIndex !== -1) {
            temp.splice(existingIndex, 1);
            setAssignedDjs(temp);
        }
    };

    const handleSave = () => {
        const temp: string[] = [];
        assignedDjs.forEach((el) => {
            temp.push(el.djId);
        });
        UserService.updateUserDjs(selectedId as string, temp).then(() => close());
    };

    return (
        <Modal isOpen={open} close={close} size="large" title="User DJS">
            <div className="caller-stations">
                <div className="station-list">
                    {assignedDjs.map((dj) => {
                        return (
                            <div key={dj.djId} className="station-item">
                                <Typography variant="body">{dj.djName}</Typography>
                                <Typography variant="caption">
                                    {String(
                                        dj.formats.map((el: number) => {
                                            return ` ${Format[el]}`;
                                        }),
                                    )}
                                </Typography>
                                <IconButton Icon={DeleteIcon} onClick={() => removeSelection(dj)} color="error"/>
                            </div>
                        );
                    })}
                </div>                
                <div className="selection">
                    <Select
                        options={djs}
                        components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                        onChange={(el) => { setSearch(false); setSelected(el as DJDashboardViewModel)}}
                        value={selected ? selected : null}
                        onInputChange={(newValue) => {setSearch(true);setInputValue(newValue)}}
                        isLoading={isLoading}
                        placeholder="search djs by station name..."
                        isSearchable={true}
                        getOptionLabel={(el) => el.stationName}
                        getOptionValue={(el) => el.id}
                        noOptionsMessage={() => {
                            return <>Enter station name</>;
                        }}
                        menuIsOpen={true}
                    />
                    <Button onClick={addSelection} color="secondary" disabled={!selected}>
                        Add
                    </Button>
                </div>
            </div>
            <ModalActionBar>
                <Button onClick={close} color="secondary">
                    Close
                </Button>
                <Button onClick={handleSave} color="secondary">
                    Save
                </Button>
            </ModalActionBar>
        </Modal>
    );
};

const Option: React.FC<OptionProps<DJDashboardViewModel>> = (props: OptionProps<DJDashboardViewModel>) => {
    return (
        <div className="option">
            
            <components.Option {...props} className="tag-option-item">
                <div className="details">
                    <div>
                        <Typography variant="body" weight={600}>
                            {props.data.name}
                        </Typography>
                        {props.data.formats && (
                            <Typography variant="caption">
                                <strong>Formats: </strong>
                                {String(
                                    props.data.formats.map((el: number) => {
                                        return ` ${Format[el]}`;
                                    }),
                                )}
                            </Typography>
                        )}
                    </div>
                </div>
            </components.Option>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<DJDashboardViewModel>> = (
    props: DropdownIndicatorProps<DJDashboardViewModel>,
) => {
    return (
        <div className="dropdown-indicator">
            <components.DropdownIndicator {...props}>
                <SearchIcon className="color-grey" />
            </components.DropdownIndicator>
        </div>
    );
};

export default UserDJsModal;
