import React from 'react';
import IconButton from '../../components/Core/IconButton';
import { ReactComponent as DisableIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as EnableIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CallSheetIcon } from '../../assets/icons/file-text.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../components/Core/AlertDialog/state';
import { UserService } from '../../api';
import { SystemUser } from '../../models/User';
type Props = {
    user: SystemUser;
    fetchData: () => void;
    openAssociationModal: () =>void;
    handleEdit: () => void;
    handleCallSheetClick: () => void;
};
const UserActions: React.FC<Props> = ({ user, fetchData, openAssociationModal, handleEdit, handleCallSheetClick }) => {
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const enableUserDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Enable User',
                description: `Are you sure you want to enable user ${user.name}?`,
                handleConfirm: () => handleEnable(user.id as string),
            },
        });
    };

    const openDeleteUserDialog = (isDisabling: boolean) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: isDisabling ? 'Disable User' : 'Delete User',
                description: `Are you sure you want to ${isDisabling ? 'disable user' : 'delete user'} ${user.name}?`,
                handleConfirm: () => (isDisabling ? handleDisable(user.id as string) : handleDelete(user.id as string)),
            },
        });
    };

    const handleDelete = (id: string) => {
        UserService.deleteUser(id).then(() => {
            fetchData();
        });
    };

    const handleEnable = (id: string) => {
        UserService.enableUser(id).then(() => {
            fetchData();
        });
    };

    const handleDisable = (id: string) => {
        UserService.disableUser(id).then(() => {
            fetchData();
        });
    };
    return (
        <>
            <IconButton onClick={handleEdit} Icon={EditIcon} color='info' tooltip="Edit" />
            {user.isDisabled && <IconButton Icon={DisableIcon} onClick={enableUserDialog} color="info" tooltip='Enable user'/>}
            {!user.isDisabled && (
                <IconButton Icon={EnableIcon} onClick={() => openDeleteUserDialog(true)} color="info"  tooltip='Disable user'/>
            )}
            <IconButton Icon={CallSheetIcon} onClick={handleCallSheetClick} color="info" tooltip='Open Callsheet'/>
            <IconButton Icon={RadioIcon} onClick={openAssociationModal} color="info" tooltip='Assign djs'/>
            <IconButton Icon={DeleteIcon} onClick={() => openDeleteUserDialog(false)} color="error" tooltip='Delete user'/>
        </>
    );
};

export default UserActions;
