import React from 'react';
import Typography from '../../components/Core/Typography';
import Contracts from '../Contracts';

type Props = {
    clientId: string
}

const ClientContract: React.FC<Props> = ({ clientId }) => {
    return (
        <div className="paper client-contracts">
            <Typography variant="title" weight={600} color="tertiary">
                PROJECTS
            </Typography>
            <Contracts clientId={clientId}/>
        </div>
    );
};

export default ClientContract;
