import React from "react";
import { getSelectedState, Grid, GridCellProps, GridColumn, GridFilterCellProps, GridFilterChangeEvent, GridHeaderSelectionChangeEvent, GridPageChangeEvent, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { CompositeFilterDescriptor, getter } from '@progress/kendo-data-query';
import Checkbox from "../../components/Core/Checkbox";
import Button from "../../components/Core/Button";
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as UnassociationIcon } from '../../assets/icons/user-minus.svg';
import { ReactComponent as ContractIcon } from '../../assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import IconButton from "../../components/Core/IconButton";
import { NACCAdds, SpinStatus } from "../../models/ContractPerformance";
import Modal, { ModalActionBar } from "../../components/Core/Modal";
import StripContract from "../../models/StripContract";
import { PerformanceService } from "../../api";
import { AlertDialogActionType, AlertDialogContext } from "../../components/Core/AlertDialog/state";
import { DataLoadingContext } from "../../components/DataLoading";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";
import ContractSelector from "../Contracts/ContractSelector";
import { initialDataState, PageState } from "../ContractPerformance/PerformanceTable";
import { getFormatName, getListFromEnum, getSelectedGridData, useDebounce } from "../../utils";
import { Format } from "../../models/Contract";
import GridDropDownFilter from "../../components/GridDropDownFilter";
type Props = {
    data: NACCAdds[];
    toggleCheck?: () => void;
    toggleAdds?: () => void;
    toggleUpload?: () => void;
    refreshData?: () => void;
    total?: number;
    isInGlobal: boolean;
    handlePageChange?: (page: number) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFilterChange?: (filters?: any) => void;
};
const DATA_ITEM_KEY = 'id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const AddsTable: React.FC<Props> = ({ data, total, isInGlobal, toggleCheck, toggleUpload, toggleAdds, refreshData, handleFilterChange, handlePageChange }) => {
    const [selectedAdds, setSelectedAdds] = React.useState<NACCAdds>();
    const [isAssociationModalOpen, setIsAssociationModalOpen] = React.useState(false);
    const [selectedContract, setSelectedContract] = React.useState<StripContract>();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const dataState = data.map((dataItem: NACCAdds) => Object.assign({ selected: false }, dataItem));
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const [listAssociation, setListAssociation] = React.useState(false);
    const formats = getListFromEnum(Object.values(Format));
    
    const debounced = useDebounce(filter?.filters, 500);

    React.useEffect(()=>{
        handleFilter();
    }, [debounced])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CellValue = (props: any) => {
        const dataItem = props.dataItem;
        const fieldName = props.field;
        const fieldValue = dataItem[fieldName];
        return (
            <td className="grid-word-break">
                <p>{(fieldValue.length == 0) ? "-" : fieldValue}</p>
            </td>
        )
    };

    const FormatFilterCell = (props: GridFilterCellProps) => (
        <GridDropDownFilter
            {...props}
            data={formats}
            defaultValue={{ label: 'select format', value: '' }}
            filterOperator="contains"
        />
    );
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const GenreValue = (props: any) => {
        const dataItem = props.dataItem;
        return (
            <td>
                <p>{getFormatName(dataItem.genre)}</p>
            </td>
        )
    }

    const handleMultiAssign = () => {
        const payload: { Ids: string[], contractId: string } = { Ids: getSelectedGridData(selectedState), contractId: (selectedContract) ? selectedContract.id as string : '' };
        PerformanceService.associateAddsList(payload)
            .then(() => {
                setListAssociation(false);
                setIsAssociationModalOpen(false);
                setFilter(undefined);
                if (refreshData) refreshData();
            })
    }


    const GridActions = (props: GridCellProps) => {
        const adds = props.dataItem as NACCAdds;
        return (
            <td className="grid-actions">
                {isInGlobal && <IconButton Icon={adds.status == SpinStatus.NoMatch ? UserIcon : ContractIcon} color={'primary'} onClick={() => handleAssociationClick(adds)} tooltip="Associate adds to a contract" />}
                {!isInGlobal && <IconButton Icon={UnassociationIcon} color={'primary'} onClick={() => handleUnAssociationClick(adds)} tooltip="Unassociate"/>}
                <IconButton Icon={DeleteIcon} color={'error'} onClick={() => handleOpenDeletePerformanceDialog(adds)} tooltip="Remove" />
            </td>
        );
    };

    const handleOpenDeletePerformanceDialog = (item: NACCAdds) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Delete Performance Data',
                description: `Are you sure you want to delete selected perfromance data? This process is irreversible.`,
                handleConfirm: () => handleDelete(item.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        toggleLoading(true);
        PerformanceService.deleteAddsData(id)
            .then((res) => {
                if (res) {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Successfully deleted adds data`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                    if (refreshData) refreshData();
                }
                else {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: "Something went wrong. Please try again later.",
                            status: 'error',
                            autoClose: true,
                        },
                    });
                }
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            }).finally(() => {setSelectedState({}); toggleLoading(false);})
    }

    const handleUnassociation = (id: string) => {
        toggleLoading(true);
        PerformanceService.unassociateAdds(id)
            .then((res) => {
                if (res) {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: `Successfully unassociated adds data`,
                            status: 'success',
                            autoClose: true,
                        },
                    });
                    if (refreshData) refreshData();
                }
                else {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: "Something went wrong. Please try again later.",
                            status: 'error',
                            autoClose: true,
                        },
                    });
                }
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            })
            .finally(() => {
                toggleLoading(false);
            })
    }

    const handleListDelete = () => {
        PerformanceService.deleteListAdds(getSelectedGridData(selectedState)).then((res) => {
            if (res) {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: `Successfully deleted adds data`,
                        status: 'success',
                        autoClose: true,
                    },
                });
                if (refreshData) refreshData();
            }
            else {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: "Something went wrong. Please try again later.",
                        status: 'error',
                        autoClose: true,
                    },
                });
            }
        }).catch((e) => {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(() => setSelectedState({}))
    }
    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const checkboxElement: any = event.syntheticEvent.target
            const checked = checkboxElement.checked;
            const newSelectedState: { [key: string]: boolean } = {};

            event.dataItems.forEach(item => {
                const value = idGetter(item);
                newSelectedState[value] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );
    const handleAssociationClick = (dataItem: NACCAdds) => {
        setSelectedAdds(dataItem);
        setIsAssociationModalOpen(true);
    }

    const handleUnAssociationClick = (item: NACCAdds) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Un Associate Adds Data',
                description: `Are you sure you want to unassociate selected adds data? This process is irreversible.`,
                handleConfirm: () => handleUnassociation(item.id as string),
            },
        });
    }

    const handleAssociation = () => {
        let payload: { addsId: string, contractId: string } = { addsId: '', contractId: '' };

        if (selectedContract) {
            payload = { addsId: selectedAdds?.id as string, contractId: selectedContract.id as string }
        }

        if (payload.addsId.length > 0 && payload.contractId.length > 0) {
            PerformanceService.associateAdds(payload)
                .then(() => {
                    setIsAssociationModalOpen(false);
                    setFilter(undefined);
                    if (refreshData) refreshData();
                })
        }
    }

    const pageChange = (event: GridPageChangeEvent) => {
        toggleLoading(true);
        const newPage = event.page;
        if (handlePageChange) {
            handlePageChange((newPage.skip / newPage.take) + 1);
        }
        setPage(newPage);
        toggleLoading(false);

    };

    const handleFilter = () => {
        setSelectedState({});
        if(filter){
            if (handleFilterChange) handleFilterChange(debounced);
        }
        else {
            if (handleFilterChange) handleFilterChange();
            setFilter(undefined);
        }

    };

    return <>
        <Grid
            style={{
                height: '100%',
            }}
            sortable={true}
            filterable={true}
            filter={filter}
            data={dataState.map(item => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
            total={total}
            take={page.take}
            skip={page.skip}
            pageable={true}
            onPageChange={pageChange}
            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{ enabled: isInGlobal, drag: false, cell: false, mode: 'multiple' }}
        >
            {isInGlobal &&
                <GridToolbar>

                    <Checkbox
                        checked={true}
                        onChange={() => toggleAdds ? toggleAdds() : ''}
                        label="Show Adds"
                    />
                    <Checkbox
                        checked={false}
                        onChange={() => toggleCheck ? toggleCheck() : ''}
                        label="Show Uploads"
                    />

                    <Button color="primary" onClick={() => toggleUpload ? toggleUpload() : ''}>
                        Upload NACC
                    </Button>
                    {(Object.keys(selectedState).length > 0 && Object.values(selectedState).includes(true)) && <IconButton Icon={DeleteIcon} color={'error'} onClick={handleListDelete} />}
                    {(Object.keys(selectedState).length > 0 && Object.values(selectedState).includes(true)) && <IconButton Icon={UsersIcon} color={'error'} onClick={() => { setListAssociation(true); setIsAssociationModalOpen(true); }} tooltip="Assign" />}
                </GridToolbar>
            }
            {isInGlobal &&
                <GridColumn
                    filterable={false}
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                        dataState.findIndex(item => !selectedState[idGetter(item)]) === -1
                    }
                />
            }
            <GridColumn field="week" title="Date" />
            <GridColumn field="station" title="Station" />
            {!isInGlobal && <GridColumn title='Market' field='market' cell={CellValue} />}
            {isInGlobal && <GridColumn title='Artist' field='artist' cell={CellValue} />}
            <GridColumn title='Record' field='record' cell={CellValue} />
            <GridColumn field="genre" title="Genre" cell={GenreValue} filterCell={FormatFilterCell}/>
            <GridColumn field='fileName' title='Uploaded File Name' cell={CellValue} />
            <GridColumn cell={GridActions} filterable={false} width={100} sortable={false} />
        </Grid>

        <Modal isOpen={isAssociationModalOpen} close={() => { setListAssociation(false); setIsAssociationModalOpen(false); setSelectedContract(undefined); setSelectedAdds(undefined); }} size='large' title={'Associate this adds record to a contract'}>
            <div className='contract-association-modal'>
                {listAssociation ?
                    <ContractSelector addsIdProp={getSelectedGridData(selectedState)[0]} onSelect={(selectedContract: StripContract | undefined) => setSelectedContract(selectedContract)} />
                    :
                    <ContractSelector adds={selectedAdds as NACCAdds} onSelect={(selectedContract: StripContract |undefined) => setSelectedContract(selectedContract)} />
                }
            </div>
            <ModalActionBar>
                <Button disabled={(selectedAdds?.contractId !== null && selectedAdds?.contractId !== undefined) ? false : (selectedContract !== undefined && selectedContract !== null) ? false : true} onClick={listAssociation ? handleMultiAssign : handleAssociation}>
                    Associate
                </Button>
            </ModalActionBar>
        </Modal>
    </>
}

export default AddsTable;