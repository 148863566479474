import { UserRole } from "../models/User"

export type TemplateConfig = {
    colors: ThemeColor[],
    routes: Route[]
}

export type ThemeColor = {
    variable: string,
    value: string
}

export type Route = {
    label: string;
    route: string;
    roles: Array<UserRole>;
    Component: React.FC;
    Icon?: React.FC;
    exact: boolean;
    navPosition?: NavPosition;
    isExternal?:boolean;
    showFooter?: boolean;
    showHorizontalNav?: boolean;
    showVerticalNav?:boolean;
    class?:string;
}

export enum NavPosition {
    TOP="Top",
    FOOTER="Footer",
    VERTICAL="Vertical"
}

export type NavItem = {
    label:string;
    route: string;
    Icon?: React.FC;
}