import { TimeZone } from "../models/Station";

const getCurrentTimeZone = (): TimeZone => {
    const currentOffset = (new Date().getTimezoneOffset()) / -60;

    switch(currentOffset)
    {
        case -9:
            return TimeZone["Alaska Standard Time (UTC-9)"];
        case -6:
            return TimeZone["Central Standard Time (UTC-6)"];
        case -5:
            return TimeZone["Eastern Standard Time (UTC-5)"];
        case -10:
            return TimeZone["Hawaii-Aleutian Standard Time (UTC-10)"];
        case -7:
            return TimeZone["Mountain Standard Time (UTC-7)"];
        case -8:
            return TimeZone["Pacific Standard Time (UTC-8)"];
        default:
            return TimeZone["Eastern Standard Time (UTC-5)"];
    }
};

const getTimeIn12HrFormat = (timeIn24HrFormat: string): string => {
    let res = '';
    const h1 = Number(parseFloat(timeIn24HrFormat[0]) - 0); //getting first part of the hr part from time
    const h2 = Number(parseFloat(timeIn24HrFormat[1]) - 0); //getting second part of the hr part from time
    
    let hh = h1 * 10 + h2;

    let Meridien = ''; //variable to store AM/PM
     
    if (hh < 12) {
        Meridien = "AM";
    }
    else
        Meridien = "PM";
    
    hh %= 12;
    
    // Handling 00 and 12 case separately
    if (hh == 0) {
        res += "12";
    
        // Printing minutes and seconds
        for (let i = 2; i < 5; ++i) {
            res += timeIn24HrFormat[i];
        }
    }
    else {
        res += hh;
        // Printing minutes and seconds
        for (let i = 2; i < 5; ++i) {
            res += timeIn24HrFormat[i];
        }
    };

    return `${res} ${Meridien}`;
};

const getTBZNames = (timeZone: TimeZone): string => {

    switch(timeZone)
    {
        case TimeZone["Alaska Standard Time (UTC-9)"]:
            return 'America/Anchorage';
        case TimeZone["Central Standard Time (UTC-6)"]:
            return 'America/Chicago';
        case TimeZone["Eastern Standard Time (UTC-5)"]:
            return 'America/Detroit';
        case TimeZone["Hawaii-Aleutian Standard Time (UTC-10)"]:
            return 'Pacific/Honolulu';
        case TimeZone["Mountain Standard Time (UTC-7)"]:
            return 'America/Denver';
        case TimeZone["Pacific Standard Time (UTC-8)"]:
            return 'America/Los_Angeles';
        default:
            return 'America/Detroit';
    }
};

const getTimeZoneOffset = (timeZone: TimeZone): string => {
    switch(timeZone)
    {
        case TimeZone["Alaska Standard Time (UTC-9)"]:
            return '-09:00';
        case TimeZone["Central Standard Time (UTC-6)"]:
            return '-06:00';
        case TimeZone["Eastern Standard Time (UTC-5)"]:
            return '-05:00';
        case TimeZone["Hawaii-Aleutian Standard Time (UTC-10)"]:
            return '-10:00';
        case TimeZone["Mountain Standard Time (UTC-7)"]:
            return '-07:00';
        case TimeZone["Pacific Standard Time (UTC-8)"]:
            return '-08:00';
        default:
            return '-05:00';
    }
}

const convertToCurrentTimeZone = (time: string, currentTimeZone: TimeZone): string => {
    let res = '';
    const splits = time.split(':');

    if(splits.length === 2){
        const hr = parseFloat(splits[0]);
        const mm = parseFloat(splits[1]);

        const tempDate = new Date (new Date(`2000/1/1 ${hr}:${mm} ${getTimeZoneOffset(currentTimeZone)}`)
                        .toLocaleString("en-US", {timeZone: getTBZNames(getCurrentTimeZone())}));
        
        const newHr = tempDate.getHours();
        const newMm = tempDate.getMinutes();

        res += `${newHr <= 9 ? '0'+newHr : newHr}:${newMm <= 9 ? '0'+newMm : newMm}`;                
    }

    return res;
};

const getDiffernceInDays = (startDate: string, endDate: string): number => {
    const differenceIntime = new Date(endDate).getTime() - new Date(startDate).getTime();

    return differenceIntime / (1000 * 3600 * 24);
}

export {
    getCurrentTimeZone,
    getTimeIn12HrFormat,
    convertToCurrentTimeZone,
    getDiffernceInDays
};