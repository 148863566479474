import React from 'react';
import IconButton from '../../../components/Core/IconButton';
import Typography from '../../../components/Core/Typography';
import { NACCAdds } from '../../../models/ContractPerformance';
import './contractadd.scss';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as UpArrowIcon } from '../../../assets/icons/chevron-up.svg';
import AddsTable from '../../SpinUploads/AddsTable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContractAdds: React.FC<({ adds: { items: NACCAdds[], total: number }; getData: () => void; handlePageChange: (page: number) => void; handleFilterChange: (filters: any) => void })> = ({ adds, getData, handlePageChange, handleFilterChange }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <div className="contract-adds paper">
            <div className="adds-title">
                <div>
                    <Typography variant="subtitle" weight={600} color="secondary">
                        ADDS DATA
                    </Typography>
                    {adds.items.length > 0 && (
                        <Typography variant="caption" color="info">
                            {adds.total} adds recorded.
                        </Typography>
                    )}
                </div>
                <div className='icons-container'>
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        Icon={isExpanded ? UpArrowIcon : DownArrowIcon}
                    />
                </div>
            </div>
            {isExpanded &&
                <AddsTable
                    data={adds.items}
                    total={adds.total}
                    isInGlobal={false}
                    refreshData={getData}
                    handlePageChange={handlePageChange}
                    handleFilterChange={handleFilterChange}
                />
            }
        </div>
    );
};

export default ContractAdds;
