import React from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '../../../components/Core/IconButton';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/external-link.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { AlertDialogActionType, AlertDialogContext } from '../../../components/Core/AlertDialog/state';
import { ContractService } from '../../../api';
import { ContractMailingListResp } from '../../../models/Contract';

type Props = {
    mailingList: ContractMailingListResp;
    fetchData: () => void;
};

const ContractMailingListActions: React.FC<Props> = ({ mailingList, fetchData }) => {
    const history = useHistory();
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    const handleOpenDeleteStationDialog = () => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Remove Station',
                description: `Are you sure you want to remove ${mailingList.name} from the mailing list?`,
                handleConfirm: () => handleDelete(mailingList.id as string),
            },
        });
    };

    const handleDelete = (id: string) => {
        ContractService.deleteStationFromContractMailingList(id).then(() => {
            fetchData();
        });
    };

    return (
        <>
            <IconButton
                Icon={ExternalLinkIcon}
                onClick={() => history.push(`/stations/${mailingList.stationId}`)}
                color="tertiary"
                tooltip="View station details"
            />
            <IconButton
                Icon={DeleteIcon}
                onClick={handleOpenDeleteStationDialog}
                color="error"
                tooltip="Remove station from mailing list"
            />
        </>
    );
};

export default ContractMailingListActions;
