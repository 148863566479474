import React from 'react';
import { ContractService, FeedbackService, StationService } from '../../api';
import Feedback, { FeedbackCategory } from '../../models/Feedback';
import { StationDashboardViewModel } from '../../models/Station';
import StripContract from '../../models/StripContract';
import ContractFeedbacks from '../Contracts/ContractFeedbacks';
import ContractSelector from './ContractSelector';
import './dashboard.scss';
import NoteEditor from './NoteEditor';
import { ReactComponent as CallSheetIcon } from '../../assets/icons/file-text.svg';
import { DashBoardActionType, DashboardContext, dashboardReducer, initialState } from './state';
import StationSelector from './StationSelector';
import CallSheetPanel from './CallSheetPanel';


const Dashboard: React.FC = () => {
    const [state, dispatch] = React.useReducer(dashboardReducer, initialState);
    const [feedbacks, setFeedbacks] = React.useState<Feedback[]>([]);
    const [activeFeedback, setActiveFeedback] = React.useState<Feedback>();
    const stationId = state.selectedStation?.id;
    const feedbackId = state.feedbackId;
    const [openCallsheet, setCallSheetOpen] = React.useState(false);
    const [activeFeedbackSentiment, setActiveFeedbackSentiment] = React.useState<FeedbackCategory|undefined>();

    React.useEffect(() => {
        if (feedbackId) {
            hanldeInitialization();
        }
        else{
            setActiveFeedback(undefined);
        }
    }, [feedbackId]);

    React.useEffect(()=>{
        if(activeFeedback){
            setActiveFeedbackSentiment(activeFeedback.category);
        }
        else{
            setActiveFeedbackSentiment(undefined)
        }
    },[activeFeedback]);

    React.useEffect(() => {
        if (stationId) {
            StationService.getStation(stationId).then((response) =>
                dispatch({
                    type: DashBoardActionType.SELECT_STATION,
                    payload: response as StationDashboardViewModel,
                }),
            );
        }
    }, [stationId]);

    const hanldeInitialization = () => {
        if(feedbackId!=undefined){
        FeedbackService.getFeedbackById(feedbackId).then((response) => {
            setActiveFeedback(response);
            if (response.contractId !== state.selectedContract?.id) {
                ContractService.getContract(response.contractId).then((response) =>
                    dispatch({
                        type: DashBoardActionType.SELECT_CONTRACT,
                        payload: response as unknown as StripContract,
                    }),
                );
            }
        });
        }
    };

    React.useEffect(() => {
        if (state.selectedContract) {
            getFeedback();
        }
    }, [state.selectedContract]);

    const getFeedback = () => {
        if(state.selectedStation?.name == undefined){
            FeedbackService.getFeedbackForContract(state.selectedContract?.id as string).then((response) =>
            setFeedbacks(response),
        );}
            else{
                FeedbackService.getFeedbackForContract(state.selectedContract?.id as string, state.selectedStation?.id).then((response) =>
                setFeedbacks(response),
            );
        }
    };

    return (
        <DashboardContext.Provider value={{ state, dispatch }}>            
            <div id="scroll-anchor"></div>
            <div className="dashboard">
                <div className="selectors">
                    <StationSelector />
                    {state.selectedStation && <ContractSelector />}
                </div>
                <div className="dashboard-content">
                    <div className="paper">
                        <div onClick={()=>setCallSheetOpen(true)} className="callsheet-icon" tabIndex={0} onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && setCallSheetOpen(true)}><CallSheetIcon/> Call sheet</div>
                        <NoteEditor
                            feedback={activeFeedback ? activeFeedback.comment : undefined}
                            feedbackId={activeFeedback ? activeFeedback.id : undefined}
                            feedbackSentiment={activeFeedbackSentiment? activeFeedbackSentiment: undefined}
                            contractId={activeFeedback?activeFeedback.contractId : state.selectedContract?.id ?? undefined}
                            stationId={activeFeedback?activeFeedback.stationId : state.selectedStation?.id ?? undefined}
                            feedBackDate = {activeFeedback? activeFeedback.feedbackDate: new Date()}
                            feedbackGenre = {activeFeedback? activeFeedback.genre : undefined}
                            onFeedbackChange = {getFeedback}
                        />
                    </div>
                    {state.selectedContract && feedbacks.length > 0 && (
                        <ContractFeedbacks
                            feedbacks={feedbacks}
                            getData={getFeedback}
                            activeFeedbackId={activeFeedback ? activeFeedback.id : undefined}
                        />
                    )}
                </div>
                {openCallsheet && <CallSheetPanel close={()=>setCallSheetOpen(false)}/>}
            </div>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
