import React from 'react';
import Typography from '../../../components/Core/Typography';
import { Format } from '../../../models/Contract';
import { formatLocation, formatPhoneNumber } from '../../../utils';
import { SearchStationContext } from './state';

const ClusterList: React.FC = () => {
    const { state } = React.useContext(SearchStationContext);

    return (
        <div className="cluster-list">
            {state.mainSearchStations.map((el, index) => (
                <div className={`cluster-item`} key={index}>
                    <Typography variant="body">Name:
                        <Typography variant="caption">
                            {el.name}
                        </Typography>
                    </Typography>
                    <Typography variant="body">Formats:
                        <Typography variant="caption">
                            {String(el.formats.map((el: number) => { return ` ${Format[el]}` }))}
                        </Typography>
                    </Typography>
                    <Typography variant="body">Address:
                        <Typography variant="caption">
                            {formatLocation(
                                el.addressLine1,
                                el.addressLine2,
                                el.city,
                                el.state,
                                el.country,
                                el.zip
                            )}
                        </Typography>
                    </Typography>
                    <Typography variant="body">Needs Mailing:
                        <Typography variant="caption">
                            {el.needsMailing ? 'Yes' : 'No'}
                        </Typography>
                    </Typography>
                    {el.primaryContactName && el.primaryContactName.trim().length > 0 &&
                        <Typography variant="body">Primary Contact:
                            <Typography variant='caption'><strong>{el.primaryContactName}</strong>
                                {el.primaryContactEmailAddress &&
                                    <>
                                        {` | ${el.primaryContactEmailAddress}`}
                                    </>
                                }
                            </Typography>
                            {el.primaryContactPhoneNumber &&
                                <Typography variant='caption'>{formatPhoneNumber(el.primaryContactPhoneNumber)}</Typography>
                            }
                        </Typography>
                    }
                </div>
            ))}
        </div>
    );
};
export default ClusterList;
