import React from 'react';
import { DashBoardActionType, DashboardContext } from './state';
import { Format } from '../../models/Contract';
import { ContractService } from '../../api';
import { DataLoadingContext } from '../../components/DataLoading';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/icons/clipboard.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { Link } from 'react-router-dom';
import IconButton from '../../components/Core/IconButton';
import Typography from '../../components/Core/Typography';
import StripContract from '../../models/StripContract';
import { useDebounce } from '../../utils';

const ContractSelector: React.FC = () => {
    const { contracts, selectedContract, selectedStation } = React.useContext(DashboardContext).state;
    const [selectedFormat, setSelectedFormat] = React.useState<Format>();
    const dispatch = React.useContext(DashboardContext).dispatch;
    const { toggleLoading } = React.useContext(DataLoadingContext);
    const [filteredData, setFilteredData] = React.useState<StripContract[]>([]);
    const [searchText, setSearchText] = React.useState('');
    const debounce = useDebounce(searchText, 1000) as string;
    

    React.useEffect(() => {
        if (selectedStation) {
            setSelectedFormat(selectedStation.formats[0]);
            dispatch({
                type: DashBoardActionType.SELECT_FORMAT, // eslint-disable-next-line react/prop-types
                payload: selectedStation.formats[0],
            });
        }
    }, [selectedStation]);


    React.useEffect(() => {
        if (selectedFormat) {
            toggleLoading(true);
            ContractService.getPriorities(selectedFormat)
                .then((response) => {
                    dispatch({ type: DashBoardActionType.SET_CONTRACTS, payload: response });
                })
                .finally(() => toggleLoading(false));
        }
    }, [selectedFormat]);

    React.useEffect(() => {
        setSearchText('');
        setFilteredData(contracts);
    }, [contracts]);

    React.useEffect(() => {
        const temp = contracts.filter(
            (el) =>
                el.artistName.toLowerCase().includes(searchText.toLowerCase()) ||
                el.releaseName.toLowerCase().includes(searchText.toLowerCase()) ||
                el.labelName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(temp);
    }, [debounce]);

    const handleRowClick = (item: StripContract) => {
        dispatch({
            type: DashBoardActionType.SELECT_CONTRACT, // eslint-disable-next-line react/prop-types
            payload: item,
        });
    };

    const handleFormatChange = (genre: Format) =>{
        dispatch({
            type: DashBoardActionType.SELECT_FORMAT, // eslint-disable-next-line react/prop-types
            payload: genre,
        });
        setSelectedFormat(genre);
    };

    return (
        <div className="selector contract-selector">
            <div className="selector-title">
                <ClipboardIcon className="color-tertiary" />
                <Typography variant="subtitle" color="tertiary">
                    Priorities
                </Typography>
            </div>            
            <div className="filter-search">
                <input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="search by release, artist, label"
                />
                <SearchIcon />
            </div>            
            <div className="contract-list">
            {selectedStation && selectedStation.formats.length > 0 && (
                <div className='format-container'>
                    <div className="format-selector">
                        {selectedStation.formats.map((el, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleFormatChange(el)}
                                    tabIndex={0}
                                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                        e.key === 'Enter' && handleFormatChange(el)
                                    }
                                    className={`format${selectedFormat === el ? ' selected' : ''}`}
                                >
                                    {Format[el]}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className='contract-dropdown'>
                {filteredData.map((el,index) => {
                    return (
                        <div
                            className={`contract-item${selectedContract?.id === el.id ? ' selected' : ''}${(index+1)%5 ==0 ? " last" : ''}`}
                            key={el.id}
                            onClick={() => handleRowClick(el)}
                            tabIndex={0}
                            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                e.key === 'Enter' && handleRowClick(el)
                            }
                        >
                           <div className = "detail-index-data">
                            <div className="detail-index">{index + 1}</div>
                                <div>                                                             
                                    <Typography variant="body">{el.artistName}</Typography>
                                    {/* <Typography variant="caption">
                                        <strong>Artist: </strong>
                                        {el.artistName}
                                    </Typography>
                                    <Typography variant="caption">
                                        <strong>Label: </strong>
                                        {el.labelName}
                                    </Typography> */}
                                </div>
                            </div>
                            <div
                                className="action"
                                onClick={(e) => e.preventDefault()}
                                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && e.preventDefault()
                                }
                            >
                                <IconButton Icon={InfoIcon} color="tertiary" tooltip={`Release:${el.releaseName} Label: ${el.labelName}`}/>
                                <Link to={`/projects/${el.id}`}>
                                    <IconButton Icon={ExternalLinkIcon} color="tertiary" tooltip="Project details" />
                                </Link>
                            </div>                       
                        </div>
                    );
                })}
            </div>
            </div>
        </div>
    );
};

export default ContractSelector;
