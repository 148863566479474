import { Format } from "./Contract";

export default interface Feedback {
    id?:string;
    stationId:string;
    contractId: string;
    contractName?: string;
    stationName?: string;
    category: FeedbackCategory
    comment: string;
    feedbackDate?: Date;
    createdAt?:string;
    updatedAt?:string;
    genre?: Format;
}

export enum FeedbackCategory {
    'Will Listen' = 0,
    'Best Bet' = 1,
    'On No Spin' = 2,
    'Neutral' = 3,
    'Add' = 4,
    'Good News' = 5,
    'On' = 6,
    'Negative' = 7
}