import Contract, { AddContractMailingListReq, CalendarEvent, ContractMailingListResp, Format } from '../models/Contract';
import FileExportResponse from '../models/FileExportResponse';
import StripContract from '../models/StripContract';
import instance from './instance';
import Client from '../models/Client';
import { StationDashboardViewModel } from '../models/Station';

function getContracts(payload?: { clientId?: string; format?: number; showArchived?: boolean }): Promise<Contract[]> {
    let reqUrl = `contracts`;
    let hasStartedQuery = false;
    if (payload) {
        if (payload.clientId) {
            reqUrl = reqUrl + `?clientId=${payload.clientId}`;
            hasStartedQuery = true;
        }
        if (payload.showArchived) {
            reqUrl =
                reqUrl +
                (!hasStartedQuery ? `?showArchived=${payload.showArchived}` : `&showArchived=${payload.showArchived}`);
            hasStartedQuery = true;
        }
    }
    return instance.get(reqUrl).then((response) => {
        return response.data;
    });
}

function getContractsForDashboard(formats: Format[]): Promise<StripContract[]> {
    return instance.post(`contracts/dashboard`, formats).then((response) => {
        return response.data as StripContract[];
    });
}

function getContractsForCalendar(): Promise<CalendarEvent[]> {
    return instance.get(`contracts/calendar`).then((response) => {
        return response.data;
    });
}

function createContract(Contract: Contract): Promise<Contract> {
    return instance.post(`contract`, Contract).then((response) => {
        return response.data;
    });
}

function getContract(id: string): Promise<Contract> {
    return instance.get(`contract/${id}`).then((response) => {
        return response.data;
    });
}

function deleteContract(id: string): Promise<boolean> {
    return instance.delete(`contract/${id}`).then((response) => {
        return response.data;
    });
}

function archiveContract(id: string): Promise<boolean> {
    return instance.put(`contract/archive/${id}`).then((response) => {
        return response.data;
    });
}

function unarchiveContract(id: string): Promise<boolean> {
    return instance.put(`contract/unarchive/${id}`).then((response) => {
        return response.data;
    });
}

function updateContract(Contract: Contract): Promise<Contract> {
    return instance.put(`contract`, Contract).then((response) => {
        return response.data;
    });
}

function createContractWithClient(Contract: Contract, Client: Client): Promise<Contract> {
    return instance.post(`contract-with-client`, {client:Client, contract:Contract}).then((response) => {
        return response.data;
    });
}

function getPriorities(format: Format): Promise<StripContract[]> {
    return instance.get(`priorities/${format}`).then((response) => {
        return response.data;
    });
}

function updatePriority(id: string, format:Format, priority:number): Promise<boolean> {
    return instance.put(`priority/${id}?priority=${priority}&format=${format}`).then((response) => {
        return response.data;
    });
}

function exportContract(id: string): Promise<FileExportResponse> {
    return instance.get(`contract/${id}/export`).then((response) => {
        return response.data as FileExportResponse;
    });
}

function exportMailingList(id: string): Promise<FileExportResponse> {
    return instance.get(`contract/${id}/export/mailing-list`).then((response) => {
        return response.data as FileExportResponse;
    });
}

function searchSimiarContractForAStrip(spinId: string, type: string): Promise<StripContract[]>{
    return instance.get(`contract/search/${spinId}?type=${type}`)
    .then((response) => {
        return response.data;
    })
}


function getContractInformation(value: string): Promise<StripContract[]>{
    return instance.get(`contract/contract-information?value=${value}`)
    .then((response) => {
        return response.data;
    })
}

function getContractMailingList(contractId: string): Promise<ContractMailingListResp[]> {
    return instance.get(`contract/${contractId}/mailing-list`)
    .then((response) => {
        return response.data;
    })
}

function insertStationsToContractMailingList(req: AddContractMailingListReq[]) : Promise<boolean> {
    return instance.post('contract/mailing-list', req)
    .then((response) => {
        return response.data;
    })
}

function deleteStationFromContractMailingList(id: string): Promise<boolean> {
    return instance.delete(`contract/mailing-list/${id}`)
    .then((response) => {
        return response.data;
    })
}

function getStationsWithSameFormatAndValue(id: string, onlyShowNeedsMailing: boolean, name?: string): Promise<StationDashboardViewModel[]> {
    let apiRoute = `contract/${id}/search/radiostation`;

    if(name) apiRoute += `?name=${name}`;
    apiRoute += `${name ? '&' : '?'}onlyShowNeedsMailing=${onlyShowNeedsMailing}`; 
    return instance.get(apiRoute).then((res) => {
        return res.data;
    })
}

export {
    getContracts,
    getContractsForDashboard,
    createContract,
    updateContract,
    createContractWithClient,
    getContract,
    deleteContract,
    getPriorities,
    updatePriority,
    getContractsForCalendar,
    exportContract,
    exportMailingList,
    archiveContract,
    unarchiveContract,
    searchSimiarContractForAStrip,
    getContractInformation,
    insertStationsToContractMailingList,
    getContractMailingList,
    deleteStationFromContractMailingList,
    getStationsWithSameFormatAndValue
};
