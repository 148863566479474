export default interface Report{
    id? :string;
    contractId?: string;
    content: string;
    dateCreated?: string;
    dateUpdated?: string;
    week?: string;
    reportType: ReportType

}

export enum ReportType{
    Weekly,
    Cumulative
}
