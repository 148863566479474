import React from 'react';
import './resetpassword.scss';
import Logo from '../../assets/images/logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import { useHistory, useParams, useLocation, matchPath } from 'react-router-dom';
import { passwordAreEqual, passwordIsValid } from '../../utils';
import { AuthContext } from '../Authentication/state';
import { AuthService } from '../../api';

type resetPassword = {
    password: string,
    confirmPassword: string
}
const ResetPassword: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [resetPassword, setResetPassword] = React.useState<resetPassword>({password: '', confirmPassword: ''});
    const [isSuccess, setIsSuccess] = React.useState(false);    
    const[isLoading, setIsLoading]= React.useState(false);
    const [isCreatePasswordPage, setIsCreatePassword] = React.useState(false);
    const [error, setError] = React.useState('');
    const { token, email } = useParams<{ token: string, email: string }>();
    const authState = React.useContext(AuthContext).state;

    React.useEffect(()=> {
        const temp = matchPath(location.pathname, {
            path: '/reset-password/:token/:email',
        });
        setIsCreatePassword(!temp);
    }, [location])
    
    const handleChange=(name:string, value:string)=> {
        setResetPassword({...resetPassword, [name]: value});        
    } 
    const navigateToLogin = () => {
        history.push('/');
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        if(authState.user?.id) {
            AuthService.resetUserPassword(resetPassword.password).then(()=> {
                setIsSuccess(true);
                setError('');
                setIsLoading(false);
            }).catch((e)=>{
                setError(e.message);
                setIsLoading(false);
            })
        } else {
            AuthService.resetPassword(token, email, resetPassword.password).then(()=> {
                setIsSuccess(true);
                setError('');
                setIsLoading(false);
            }).catch((e)=>{
                setError(e.message);
                setIsLoading(false);
            })
        }
        
    };

    return (
        <div className="page reset-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                {!isSuccess ? (
                    <>
                        <Typography variant="title" weight={500} color="secondary">
                            CREATE A NEW PASSWORD
                        </Typography>
                        {!isCreatePasswordPage && <Typography variant="body">
                            Your new password must be different from your previous password.
                        </Typography>}
                        {error.length > 0 && (
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        )}
                        <InputField
                            onChange={handleChange}
                            name="password"
                            type="password"
                            variant="outline"
                            outlineLabel="Password"
                            required={true}
                            value={resetPassword.password}
                            error={resetPassword.password.length> 0 && !passwordIsValid(resetPassword.password)}
                            errorText="password must be 8 characters in length and must contain one lowercase, one uppercase letter, one number and one special character (@, $, !, %, *, ?, &)."
                        />
                        <InputField
                            onChange={handleChange}
                            name="confirmPassword"
                            type="password"
                            variant="outline"
                            outlineLabel="Confirm Password"
                            required={true}
                            value={resetPassword.confirmPassword}
                            error={resetPassword.confirmPassword.length> 0 && !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)}
                            errorText="passwords donot match"
                        />
                        <div className="action-container">
                            <Button color="primary" className='mx-auto d-block' onClick={handleResetPassword} disabled={!passwordIsValid(resetPassword.password) || !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)} loading={isLoading}>
                                {isCreatePasswordPage ? 'Create Password':'Reset Password'}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography variant="title" color="secondary" textAlign="center">
                        {isCreatePasswordPage ? "You successfully created a new password!": "Your password was successfully reset!"}
                        </Typography>                        
                        <Button color="primary" onClick={navigateToLogin} className="mx-auto d-block">
                            Back to login
                        </Button>
                    </>
                )}
            </section>
        </div>
    );
};

export default ResetPassword;
