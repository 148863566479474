import axios, { InternalAxiosRequestConfig } from 'axios';
import { ApiResponse } from '../models/ApiResponse';

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
});

instance.interceptors.request.use(
    async function (config: InternalAxiosRequestConfig) {
        const token = localStorage.getItem('snd_login_token');
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
instance.interceptors.response.use(
    (resp) => {
        const response: ApiResponse<unknown> = resp?.data;
        if (response?.isSuccess) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return Promise.resolve(response) as any;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return Promise.reject(response) as any;
        }
    },
    () => {
        const networkIssue: ApiResponse<boolean> = {
            isSuccess: false,
            message: 'Something went wrong. Please try again later.',
            statusCode: 500,
            data: false,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Promise.reject(networkIssue) as any;
    },
);

export default instance;
