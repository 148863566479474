import Auth from '../../../models/Auth';
import React from 'react';

import { AuthAction, AuthActionType } from './actions';

export const initialState: Auth = {
    isLoggedIn: false,
    isEditorView: false
};

//reducers
export function authReducer(state: Auth, action: AuthAction): Auth {
    switch (action.type) {
        case AuthActionType.LOGIN:
            return {
                ...initialState,
                isLoggedIn: true,
                sessionId: '1',
                user: action.payload,
            };
        case AuthActionType.LOGOUT:
            localStorage.removeItem("snd_login_token");
            return initialState;
        default:
            return state;
    }
}

export const AuthContext = React.createContext<{
    state: Auth;
    dispatch: React.Dispatch<AuthAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
