import React from 'react';
import './stationselector.scss';
import Typography from '../../components/Core/Typography';
import { OptionProps, components, DropdownIndicatorProps } from 'react-select';
import Select from 'react-select';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { Link } from 'react-router-dom';
import { StationDashboardViewModel } from '../../models/Station';
import { Format } from '../../models/Contract';
import useDebounce from '../../utils/useDebounce';

type Props = {
    onQueryParamChange: (value: string | undefined) => void;
    onSelection: (item: StationDashboardViewModel) => void;
    stations: StationDashboardViewModel[];
    isLoading: boolean;
}

const StationSelector: React.FC<Props> = ({ onSelection, onQueryParamChange, stations, isLoading}) => {
    const [inputValue, setInputValue] = React.useState<string>();
    const debounced = useDebounce(inputValue, 500);

    React.useEffect(() => {
        onQueryParamChange(debounced as string);
    }, [debounced]);
    
    return (
        <div className="searchbar">
            <div className="search-input-wrapper">
                <Select
                    options={stations}
                    components={{ Option: Option, DropdownIndicator: DropDownIndicator }}
                    onChange={(el) => onSelection(el as StationDashboardViewModel)}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    isLoading={isLoading}
                    getOptionValue={(el) => el.id as string}
                    getOptionLabel={(el) => el.name as string}
                    placeholder="search stations..."
                    isSearchable={true}
                    noOptionsMessage={()=>{return <>Enter station name</>}}
                />
            </div>
        </div>
    );
};

const Option: React.FC<OptionProps<StationDashboardViewModel>> = (props: OptionProps<StationDashboardViewModel>) => {

    return (
        <div className="option">
            <components.Option {...props} className="tag-option-item">
                <div className="details">
                    <div>
                        <Typography variant="body" weight={600}>
                            {props.data.name}
                        </Typography>
                        {props.data.formats&&<Typography variant="caption">
                            <strong>Formats: </strong>
                            {
                               String(props.data.formats.map((el:number)=>{return ` ${Format[el]}`}))
                            }
                        </Typography>}
                        {(props.data.city || props.data.state) && 
                            <Typography variant='caption'>
                                <strong>Market: </strong>
                                {props.data.city && <>{props.data.city}</>}
                                {props.data.state && <>{props.data.city && ', '}{props.data.state}</>}
                                {props.data.country && <>{props.data.state && ', '}{props.data.country}</>}
                            </Typography>
                        }
                    </div>
                </div>
            </components.Option>
            <Link to={`stations/${props.data.id}`}>
                <ExternalLinkIcon />
            </Link>
        </div>
    );
};

const DropDownIndicator: React.FC<DropdownIndicatorProps<StationDashboardViewModel>> = (props: DropdownIndicatorProps<StationDashboardViewModel>) => {
    return(
        <div className='dropdown-indicator'>
            <components.DropdownIndicator {...props}>
                <SearchIcon className='color-grey' />
            </components.DropdownIndicator>
        </div>
    )
}


export default StationSelector;
