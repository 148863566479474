import {  Format, NACCDataType } from "./Contract";

export default interface Performance {
    id?:string;
    contractId?: string;
    station: string;
    market: string;
    week: string;
    naccArtistName?: string;
    naccRecord?: string;
    naccRank: string;
    subModernData: string;
    jackBartonData: string;
    mediaBaseData: string;
    mediaBaseRank: string;
    cdxData: string;
    otherSource: string;
    otherSourceSpins: string;
    otherSourceRank: string;
    fileName: string;
    requestId: string;
    dateCreated: string;
    status: SpinStatus;
    type: PerformanceType;
}

export  interface NACCAdds{
    id?: string;
    week: string;
    contractId?: string;
    station: string;
    artist: string;
    record: string;
    label: string;
    fileName: string;
    createdAt: string;
    status: SpinStatus;
    genre: NACCDataType;
    stationId?: string;
    market?: string;
}

export interface SpinReport {
    stationName: string;
    stationLocation: string;
    spinCount: string;
    genre: Format;
}

export interface AddsReport{
    stationName: string;
    stationLocation: string;
}

export interface NACCReport{
    stationName: string;
    naccRank: string;
    genre: Format;
    stationLocation: string;
}

export interface DataForReport{
    spinReports: SpinReport[];
    naccReports: NACCReport[];
    addsReports: AddsReport[];
    template?: string;
    feedbackLink: string;
}


export interface GroupedSpinReport {
    stationLocation: string;
    spinCount: number;
    state: string;
}

export type SpinAddReq = {
    contractId: string;
    week: string;
    station: string;
    market: string;
    spins: string;
    rank: string;
    otherSource: string;
    type: PerformanceType;
    genre: Format;
}

export type UploadPerformance = {
    contractId?: string;
    type: PerformanceType;
    week: string;
    file: File
    genre?: NACCDataType;
}

export enum PerformanceType {
    NACC,
    SUBMODERN,
    JACKBARTON,
    MEDIABASE,
    OTHER,
    CDX
}

export enum SpinStatus
{
    FullMatch,
    SoftMatch,
    NoMatch
}